import {
  PlanBasicData,
  Profile,
  UserProfile,
} from "./../../serviceClient/api.dtos";

import { Loading } from "../common/commonTypes";
import { RootState } from "../../app/store";

export const getPassThrough = (state: RootState): string =>
  state.payment.passThrough;

export const getAvailablePlans = (state: RootState): PlanBasicData[] =>
  state.payment.plans;

export const getAvailablePlansLoading = (state: RootState) =>
  state.payment.plansLoading;

export const getCollapsePlans = (state: RootState): boolean =>
  state.payment.collapsePlans;

export const getUser = (state: RootState): UserProfile => state.payment.user;

export const getProfileData = (state: RootState): Profile =>
  state.payment.user.profileData;

export const getProfileLoading = (state: RootState): Loading =>
  state.payment.userLoading;

export const getAccount = (state: RootState) => state.payment.account;

export const getAccountLoading = (state: RootState): Loading =>
  state.payment.accountLoading;

export const getReceipts = (state: RootState) => state.payment.receipts;

export const getReceiptsLoading = (state: RootState): Loading =>
  state.payment.receiptsLoading;

export const getSubscriptionsIds = (state: RootState): Array<number> =>
  state.payment.subscriptionsIds;

export const getPauseableSubscriptions = (state: RootState) =>
  state.payment.pauseableSubscriptions;

export const getPausedSubscriptions = (state: RootState) =>
  state.payment.pausedSubscriptions;

export const getNoActiveSubscription = (state: RootState): boolean =>
  state.payment.noActiveSubscription;

export const getAccountSuspended = (state: RootState): boolean =>
  state.payment.accountSuspended;

export const getDownForMaintenance = (state: RootState): boolean =>
  state.payment.downForMaintenance;

export const getAnyPausedSubscriptions = (state: RootState): boolean =>
  state.payment.anyPausedSubscriptions;

export const getAnyActiveSubscriptions = (state: RootState): boolean =>
  state.payment.anyActiveSubscriptions;

export const getSelectedProductId = (state: RootState) =>
  state.payment.selectedProductId;

export const getSelectedProduct = (state: RootState) =>
  state.payment.selectedProduct;

export const getPaddleProgress = (state: RootState): string =>
  state.payment.paddleProgress;

export const getAlterSubscriptionPlanLoading = (state: RootState) =>
  state.payment.alterSubscriptionPlanLoading;

export const getRestartPlanLoading = (state: RootState) =>
  state.payment.restartPlanLoading;

export const getOverrideCheckout = (state: RootState) =>
  state.payment.overrideCheckout;

export const getOverrideCheckoutLoading = (state: RootState) =>
  state.payment.overrideCheckoutLoading;

export const getProductAction = (state: RootState) =>
  state.payment.productAction;

export const getCancelSubscriptionLoading = (state: RootState) =>
  state.payment.cancelSubscriptionLoading;

export const getPurchaseDayPlanLoading = (state: RootState) =>
  state.payment.purchaseDayPlanLoading;

export const getTrial = (state: RootState) => state.payment.isTrial;

export const getCurrency = (state: RootState) => state.payment.currency;

export const getUserIsAwaitingPaymentLessTrial = (state: RootState) =>
  state.payment.userIsAwaitingPaymentLessTrial;

export const getProductsLoader = (state: RootState) =>
  state.payment.productsLoader;

export const getCheckoutSuccessLoader = (state: RootState) =>
  state.payment.checkoutSuccessLoader;

export const getSuccessType = (state: RootState) => state.payment.successType;

export const getDiscount = (state: RootState) => state.payment.discount;

export const getDiscountLoading = (state: RootState) =>
  state.payment.discountLoading;

export const getAccountIsLockedMessage = (state: RootState) =>
  state.payment.accountIsLockedMessage;
