import {
  NestResultSummary,
  NestingSetting,
} from "../../serviceClient/api.dtos";
import {
  NestablePart,
  NestableSheet,
  NestingProgressState,
} from "./nestingSlice";

import { RootState } from "./../../app/store";

export const getNestingState = (state: RootState) => state.nesting;

export const getPartsToNest = (state: RootState): NestablePart[] =>
  state.nesting.partsToNest;

export const getSheets = (state: RootState): NestableSheet[] =>
  state.nesting.sheets;

export const getNestingSettings = (state: RootState): NestingSetting[] =>
  state.nesting.userNestingSessionSettings;

export const getNestingResult = (state: RootState): NestResultSummary | null =>
  state.nesting.nestingResult;

export const getNestingSessionId = (state: RootState): string =>
  state.nesting.sessionId;

export const getWebSocketsNestingError = (state: RootState): string | null =>
  state.nesting.webSocketsError;

export const getNestingSessionNameLoading = (state: RootState) =>
  state.nesting.nestingSessionProjectLoading;

export const getUserNestingSessionSettingsLoading = (state: RootState) =>
  state.nesting.userNestingSessionSettingsLoading;

export const getNestName = (state: RootState): string => state.nesting.nestName;

export const getNestingProgressState = (
  state: RootState
): NestingProgressState => state.nesting.nestingProgress;

export const getNestingSessionDirty = (state: RootState): boolean =>
  state.nesting.sessionDirty;

export const getExportNestingSessionLoading = (state: RootState) =>
  state.nesting.exportNestingSessionLoading;

export const getNestingSessionRequestLoading = (state: RootState) =>
  state.nesting.nestingSessionRequestLoading;

export const getNestingSessionRequestErrorMessage = (state: RootState): any =>
  state.nesting.nestingSessionRequestErrorMessage;

export const getCurrentSettingsHasBeenNested = (state: RootState): boolean =>
  state.nesting.currentSettingsHasBeenNested;

export const getRestoreProjectLoading = (state: RootState) =>
  state.nesting.restoreProjectLoading;

export const getPreviewSvgLoading = (state: RootState) =>
  state.nesting.previewLoading;

export const getCancelNestingSessionLoading = (state: RootState) =>
  state.nesting.cancelNestingSessionLoading;

export const getCurrentNestSaved = (state: RootState) =>
  state.nesting.currentNestSaved;

export const getInvalidSheetIds = (state: RootState) =>
  state.nesting.invalidSheetIds;

export const getSheetInputFocus = (state: RootState) =>
  state.nesting.sheetInputFocus;

export const getSelectedPartIds = (state: RootState) =>
  state.nesting.selectedPartIds;

export const getSelectedSheetIds = (state: RootState) =>
  state.nesting.selectedSheetIds;

export const getPartGeometryForIdsLoading = (state: RootState) =>
  state.nesting.partGeometryForIdsLoading;

export const getSheetGeometryForSettingsLoading = (state: RootState) =>
  state.nesting.sheetGeometryForSettingsLoading;

export const getHoverPartId = (state: RootState) => state.nesting.hoverPartId;

export const getActiveTab = (state: RootState) => state.nesting.activeTab;

export const getDisableSaveAndNest = (state: RootState) =>
  state.nesting.disableSaveAndNest;

export const getNestingActive = (state: RootState) =>
  state.nesting.nestingActive;

export const getNestingFolderModalVisibility = (state: RootState) =>
  state.nesting.nestingFolderModalVisibility;

export const getReportProgress = (state: RootState) =>
  state.nesting.reportProgress;

export const getReportExportProgressBarVisibility = (state: RootState) =>
  state.nesting.reportExportProgressBarVisibility;

export const getReportExportLoaded = (state: RootState) =>
  state.nesting.reportExportLoaded;

export const getReportExportTotal = (state: RootState) =>
  state.nesting.reportExportTotal;
