import {
  ParametricPartDefinition,
  ParametricPartType,
  ParametricPartsRequest,
} from "../../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ShapeClass, getOrderedDimensions } from "./ParametricDefinitions";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../../common/commonTypes";
import { RootState } from "../../../app/store";
import { addOnTheFlyPartsToNest } from "../../nesting/nestingSlice";

type ParametricState = {
  parametricModalVisibility: boolean;
  parametricPartsRequestLoading: Loading;
  parametricTab: ParametricPartType;
  parametricTable: ShapeClass[];
};

const initialState: ParametricState = {
  parametricModalVisibility: false,
  parametricPartsRequestLoading: "idle",
  parametricTab: ParametricPartType.RectangleXY,
  parametricTable: [],
};

export const parametricPartsRequest = createAsyncThunk(
  "parametricParts/request",
  async (_, thunkAPI) => {
    const parts = (thunkAPI.getState() as RootState).parametric.parametricTable;
    const filteredEmptyParametric = parts.filter((shape) =>
      shape.isEmptyShape()
    );

    let errors = filteredEmptyParametric.map((shape) => {
      return shape.submitValidation();
    });

    if (errors.includes(true)) {
      return thunkAPI.rejectWithValue("Validation failed");
    }

    const formattedParts = filteredEmptyParametric.map((shape) => {
      const part = new ParametricPartDefinition();
      part.name = shape.name;
      part.type = shape.type;
      part.orderedDimensions = getOrderedDimensions(shape);
      part.quantity = Number(shape.quantity);
      return part;
    });

    const payload = new ParametricPartsRequest();
    payload.parts = formattedParts;

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(new ParametricPartsRequest(payload))
          .then((response) => {
            thunkAPI.dispatch(addOnTheFlyPartsToNest(response));
            thunkAPI.dispatch(setParametricTable([]));
            thunkAPI.dispatch(setParametricModalVisibility(false));

            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const parametricSlice = createSlice({
  name: "parametric",
  initialState,
  reducers: {
    setParametricModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.parametricModalVisibility = action.payload;
    },
    setParametricTab: (state, action: PayloadAction<ParametricPartType>) => {
      state.parametricTab = action.payload;
    },
    setParametricTable: (state, action: PayloadAction<ShapeClass[]>) => {
      state.parametricTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //POST: request to create parametric
      .addCase(parametricPartsRequest.pending, (state) => {
        state.parametricPartsRequestLoading = "pending";
      })
      .addCase(parametricPartsRequest.fulfilled, (state) => {
        state.parametricPartsRequestLoading = "succeeded";
      })
      .addCase(parametricPartsRequest.rejected, (state) => {
        state.parametricPartsRequestLoading = "failed";
      });
  },
});

export const {
  setParametricModalVisibility,
  setParametricTab,
  setParametricTable,
} = parametricSlice.actions;

export default parametricSlice.reducer;
