import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons";
import { lngsList } from "../../language/i18next";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div
      className="btn-group dropend w-100"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <button
        id="button-languageSwitcher"
        className="dropdown-item small"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon
          fixedWidth
          icon={faLanguage}
          className="me-2"
        />
        {lngsList.find((lng) => lng.value === i18n.resolvedLanguage)?.text ??
          ""}
      </button>
      <ul className="dropdown-menu shadow">
        {lngsList.map((lng) => (
          <li
            key={lng.id}
            className="nav-item small"
          >
            <button
              className={`dropdown-item   ${
                i18n.resolvedLanguage === lng.value ? "active" : ""
              }`}
              onClick={() => {
                i18n.changeLanguage(lng.value);
                localStorage.setItem("lng", lng.value);
              }}
            >
              {lng.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default LanguageSwitcher;
