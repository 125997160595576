import {
  AuthenticationUris,
  TermsAccepted,
  TermsAndConditionsContent,
} from "../../serviceClient/api.dtos";

import { Loading } from "./../common/commonTypes";
import { RootState } from "../../app/store";

export const getTermsAndConditions = (
  state: RootState
): TermsAndConditionsContent | undefined => state.landing.termsAndConditions;

export const getTermsAndConditionsStatus = (state: RootState) =>
  state.landing.userTermsStatus;

export const getAuthUrls = (state: RootState): AuthenticationUris | undefined =>
  state.landing.authenticationUris;

export const getAcceptTermsLoading = (state: RootState): Loading =>
  state.landing.acceptTermsLoading;

export const getTermsDeclineModalOpen = (state: RootState): boolean =>
  state.landing.termsDeclineModalOpen;

export const getAcceptTerms = (state: RootState): TermsAccepted =>
  state.landing.acceptTermsResponse;
