import { Loading, UserInfo } from "./commonTypes";
import {
  OptionSubmissionComplete,
  OptionsForUsageQuestions,
} from "../../serviceClient/api.dtos";

import { NavigationWarning } from "./commonSlice";
import { RootState } from "./../../app/store";

export const getNavigationWarning = (state: RootState): NavigationWarning =>
  state.common.navigationWarning;

export const getSurveyQuestionLoading = (state: RootState): Loading =>
  state.common.surveyQuestionLoading;

export const getSurveyAnswersLoading = (state: RootState): Loading =>
  state.common.surveyAnswersLoading;

export const getSurveyAnswersCompleted = (
  state: RootState
): OptionSubmissionComplete => state.common.surveyAnswersCompleted;

export const getQuestionOptions = (
  state: RootState
): OptionsForUsageQuestions => state.common.surveyQuestionOptions;

export const getPatchInProgress = (state: RootState): Boolean =>
  state.common.patchInProgress;

export const getNoInternetConnection = (state: RootState): boolean =>
  state.common.noInternetConnection;

export const getCloneModalOpen = (state: RootState): boolean =>
  state.common.cloneModalOpen;

export const getRotatePartModalVisibility = (state: RootState) =>
  state.common.rotatePartModalVisibility;

export const getDeletePartModalOpen = (state: RootState): boolean =>
  state.common.deletePartModalOpen;

export const getPartSearchCollapse = (state: RootState): boolean =>
  state.common.partSearchCollapse;

export const getMainNavCollapse = (state: RootState): boolean =>
  state.common.mainNavCollapse;

export const getSaveAsModalOpen = (state: RootState): boolean =>
  state.common.saveAsModalOpen;

export const getProductActionModalOpen = (state: RootState): boolean =>
  state.common.productActionModalOpen;

export const getUpdatePaymentModalOpen = (state: RootState): boolean =>
  state.common.updatePaymentModalOpen;

export const getUserInfo = (state: RootState): UserInfo =>
  state.common.userInfo;

export const getRowNestingPartsSheets = (state: RootState): string =>
  state.common.resizeNestingPartsSheets;

export const getTrialApplicationModalOpen = (state: RootState): boolean =>
  state.common.trialApplicationModalOpen;

export const getDownForMaintenanceMessage = (state: RootState): string =>
  state.common.downForMaintenanceMessage;

export const getRequestCCFreeTrialLoading = (state: RootState): Loading =>
  state.common.requestCCFreeTrialLoading;

export const getMsalProgress = (state: RootState) => state.common.msalProgress;
