import { FunctionComponent, memo } from "react";

import { FolderHierarchy } from "../../../serviceClient/api.dtos";
import MoveNestToFolderRow from "./MoveNestToFolderRow";

const MoveNestToFolderList: FunctionComponent<{
  folders: FolderHierarchy[];
  propCollapse?: boolean;
  onMouseOver: Function;
  onMouseOut: () => void;
}> = memo(({ folders, propCollapse, onMouseOut, onMouseOver }) => {
  return (
    <ul className="list-unstyled border-top-0">
      {folders.map((folder) => (
        <MoveNestToFolderRow
          propCollapse={propCollapse}
          folder={folder}
          key={folder.id}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
        />
      ))}
    </ul>
  );
});

export default MoveNestToFolderList;
