import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ApiError = "all" | "partial" | "none";

type apiErrorState = {
  apiErrorOpen: boolean;
  apiError: ApiError;
};

const initialState: apiErrorState = {
  apiErrorOpen: false,
  apiError: "none",
};

const apiErrorSlice = createSlice({
  initialState,
  name: "apiErrorSlice",
  reducers: {
    setApiErrorOpen(state, action: PayloadAction<boolean>) {
      state.apiErrorOpen = action.payload;
    },
    setApiError(state, action: PayloadAction<ApiError>) {
      state.apiError = action.payload;
    },
  },
});

export const { setApiErrorOpen, setApiError } = apiErrorSlice.actions;

export default apiErrorSlice.reducer;
