import {
  AccountDetails,
  AccountStates,
  AliveRequest,
  UserSettingsResponse,
} from "./../serviceClient/api.dtos";
import {
  Action,
  Middleware,
  MiddlewareAPI,
  ThunkAction,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  dispatchRetryWrapper,
  getTokenRedirect,
  guid,
  logOutThunk,
  pollDispatch,
} from "../Util";
import {
  fetchAccountDetails,
  fetchAccountRequest,
  fetchAvailablePlans,
  fetchReceipts,
  setAccountIsLockedMessage,
  setDownForMaintenance,
  setNoActiveSubscription,
} from "./../features/payment/paymentSlice";
import {
  fetchAuthenticationUrls,
  fetchTermsStatus,
} from "../features/landing/landingSlice";
import {
  fetchImageCookie,
  setSessionId,
} from "../features/nesting/nestingSlice";
import {
  fetchIsDarkMode,
  fetchUserSettings,
} from "../features/settings/settingsSlice";
import {
  fetchNestsFolders,
  fetchRecentNests,
  fetchSavedNests,
} from "../features/nests/nestsSlice";
import {
  fetchPartFolders,
  fetchParts,
} from "../features/libraryPartList/libraryPartListSlice";
import {
  fetchPendingInvite,
  fetchTeamStatus,
} from "../features/teams/teamsSlice";
import {
  setApiError,
  setApiErrorOpen,
} from "../features/apiError/apiErrorSlice";
import {
  setDownForMaintenanceMessage,
  setNoInternetConnection,
  setPatchInProgress,
} from "../features/common/commonSlice";

import { JsonServiceClient } from "@servicestack/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { UserTermsStatus } from "../serviceClient/api.dtos";
import { addToastMessage } from "../features/toastMessages/toastMessagesSlice";
import cookie from "react-cookies";
import { createRootReducer } from "./rootReducer";
import { fetchTermsAndConditions } from "./../features/landing/landingSlice";
import i18n from "../language/i18next";
import rg4js from "raygun4js";
import { setEfficientRouting } from "../features/efficientRouting/efficientRoutingSlice";
import { t } from "i18next";

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>;

export function isNoActiveSubscriptionException(action: any) {
  // No need to show error message from api - sends user to account page to buy plan
  if (
    action?.payload?.responseStatus?.errorCode ===
    "NoActiveSubscriptionException"
  ) {
    return true;
  }
  return false;
}

export function isAccountIsLockedException(action: any) {
  if (
    action?.payload?.responseStatus?.errorCode === "AccountIsLockedException"
  ) {
    store.dispatch(
      setAccountIsLockedMessage(action?.payload?.responseStatus?.message)
    );

    return true;
  }
  return false;
}

export function DownForMaintenanceExceptionOr503(action: any) {
  if (
    action?.payload?.responseStatus?.errorCode === "DownForMaintenance" ||
    action?.payload?.responseStatus?.errorCode === "503"
  ) {
    return true;
  }
  return false;
}

export function AnotherUserHasLoggedInException(action: any) {
  if (
    action?.payload?.responseStatus?.errorCode ===
    "AnotherUserHasLoggedInException"
  ) {
    return true;
  }
  return false;
}

export const errorInterceptor: Middleware =
  (store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (process.env.REACT_APP_BUILD_TYPE === "production") {
        const RayGunError = {
          type: action.type,
          payload: action.payload,
          message: action.error.message,
          aborted: action.meta.aborted,
          arg: action.meta.arg,
          condition: action.meta.condition,
          rejectedWithValue: action.meta.rejectedWithValue,
          requestId: action.meta.requestId,
          requestStatus: action.meta.requestStatus,
        };

        let Payload: string;
        if (typeof action.payload === "string") {
          Payload = action.payload;
        } else if (
          action.payload &&
          typeof action.payload === "object" &&
          !(action.payload instanceof Array)
        ) {
          Payload = JSON.stringify(action.payload);
        } else {
          Payload = "No Payload";
        }
        rg4js("logContentsOfXhrCalls", true);
        rg4js("setVersion", `MyNesting ${process.env.REACT_APP_BUILD_TYPE}`);
        rg4js("send", {
          error: action.type + " - " + Payload,
          customData: RayGunError,
          tags: [
            "redux_middleware",
            cookie.load("DARK-MODE") === "true" ? "dark_theme" : "light_theme",
          ],
        });
      }
      if (isNoActiveSubscriptionException(action)) {
        //Only comes back on Saved Nests and Historic Nests

        store.dispatch(setNoActiveSubscription(true));
        initialApiCalls();
        return;
      } else if (isAccountIsLockedException(action)) {
        // translation exists in the api
        store.dispatch(
          setEfficientRouting({
            accountLocked: true,
            loading: false,
          })
        );

        return;
      } else if (DownForMaintenanceExceptionOr503(action)) {
        //No translation exists - free text input from dashboard

        store.dispatch(
          setEfficientRouting({
            loading: false,
            downForMaintenance: true,
          })
        );

        store.dispatch(setDownForMaintenance(true));

        return;
      } else if (AnotherUserHasLoggedInException(action)) {
        // No translation exists - Logs user out
        store.dispatch(
          setEfficientRouting({
            mainAccessScreens: false,
            loading: false,
          })
        );

        return store.dispatch(logOutThunk());
      }
    }

    if (typeof action === "function") {
      // then call the function and pass `dispatch` and `getState` as arguments
      // Also, return whatever the thunk function returns
      return action(store.dispatch, store.getState);
    }

    //setting the state

    return next(action);
  };

async function getClient(): Promise<JsonServiceClient> {
  // Create a new JsonServiceClient
  let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

  client.exceptionFilter = function (e) {
    const projectId = e.headers.get("SavedProjectId");
    const MaintenanceMessage = e.headers.get("Maintenancemessage");
    if (projectId) {
      store.dispatch(setSessionId(projectId));
    }

    if (MaintenanceMessage) {
      store.dispatch(setDownForMaintenanceMessage(MaintenanceMessage));
    }
  };

  // Add a response filter to check for the PatchingInProgress header
  client.responseFilter = function (r) {
    if (r.headers.get("PatchingInProgress") === "true") {
      store.dispatch(setPatchInProgress(true));
    } else {
      store.dispatch(setPatchInProgress(false));
    }
  };

  await getTokenRedirect(window.MsalInstance as PublicClientApplication)
    .then((response) => {
      if (response) {
        client.headers.set("Accept-Language", i18n.language.replace(/_/g, "-"));
        client.headers.set("Authorization", "Bearer " + response.token);
      }
    })
    .catch(() => {
      throw new Error("getTokenRedirect failed");
    });

  return client;
}

const rootReducer = createRootReducer();

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  devTools: process.env.REACT_APP_BUILD_TYPE !== "production",
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: { getClient, getTokenRedirect },
      },
    }).concat(errorInterceptor),
});

export function catastrophicFail() {
  // further testing for api down here

  //is internet down first?

  if (!window.navigator.onLine) {
    store.dispatch(setNoInternetConnection(true));

    return;
  }

  return getClient().then(async (client) => {
    client
      .get(new AliveRequest())
      .then(() => {
        // api errored but not down
        // contact support
        // is there an invite?
        store
          .dispatch(fetchPendingInvite())
          .unwrap()
          .then((invite) => {
            // no invite returns a 204(no content) but still contains an object of properties with no values - not visible in dev tools.
            if (invite.id) {
              // if we have a and id with a value then the invite modal will be open at this point
              return;
            } else {
              // original request failed - api up
              store.dispatch(setApiError("partial"));
              store.dispatch(setApiErrorOpen(true));
            }
          });
      })
      .catch(() => {
        // don't contact - api down
        store.dispatch(setApiError("all"));
        store.dispatch(setApiErrorOpen(true));
      });
  });
}

// async function mainAccessApiCalls(): Promise<void> {
//   // Get Account Request
//   const promises = [
//     dispatchRetryWrapper(store.dispatch, fetchTeamStatus()).catch(() =>
//       store.dispatch(
//         addToastMessage({
//           id: guid(),
//           severity: "danger",
//           text: t("toastMessages.fetchTeamStatusError"),
//         })
//       )
//     ),
//   ];
//   return new Promise(function (resolve, reject) {
//     Promise.allSettled(promises)
//       .then(() => {
//         resolve();
//       })
//       .catch(() => {
//         reject();
//       });
//   });
// }

async function secondaryApiCalls() {
  dispatchRetryWrapper(store.dispatch, fetchTeamStatus()).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchTeamStatusError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchAvailablePlans()).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchPlansError"),
      })
    )
  );
  dispatchRetryWrapper(store.dispatch, fetchRecentNests()).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchRecentNestError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchNestsFolders()).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchNestFoldersError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchSavedNests({})).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchSavedNestError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchPartFolders()).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchPartFoldersError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchParts({})).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchPartsError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchReceipts({})).catch(() =>
    store.dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.fetchReceiptsError"),
      })
    )
  );

  dispatchRetryWrapper(store.dispatch, fetchImageCookie(), {
    failureCallback: () => {
      addToastMessage({
        id: guid(),
        severity: "warning",
        text: t("toastMessages.imageAuthenticationError"),
      });
    },
  });

  // Pending invites
  dispatchRetryWrapper(store.dispatch, fetchPendingInvite(), {
    failureCallback: () => {
      addToastMessage({
        id: guid(),
        severity: "warning",
        text: t("toastMessages.fetchPendingInviteError"),
      });
    },
  });
}

async function MainOrWizardSetup() {
  // Fetch User Settings
  await dispatchRetryWrapper<UserSettingsResponse>(
    store.dispatch,
    fetchUserSettings(),
    {
      failureCallback: catastrophicFail,
    }
  )
    .then((settings) => {
      // InitialSetup Complete ======================
      if (settings.initialSetupComplete) {
        dispatchRetryWrapper(store.dispatch, fetchIsDarkMode());
        return dispatchRetryWrapper(store.dispatch, fetchAccountRequest())
          .catch(() =>
            store.dispatch(
              addToastMessage({
                id: guid(),
                severity: "danger",
                text: t("toastMessages.fetchAccountError"),
              })
            )
          )
          .then(() => {
            store.dispatch(
              setEfficientRouting({
                loading: false,
                mainAccessScreens: true,
                // Override here For testing
                // productScreen: true,
                // wizardScreen: true,
                // termsScreen: true,
              })
            );
            secondaryApiCalls();
          })
          .catch(() => {
            store.dispatch(
              addToastMessage({
                id: guid(),
                severity: "danger",
                text: t("toastMessages.fetchAccountError"),
              })
            );
          });
      } else {
        // Wizard page ======================

        store.dispatch(
          setEfficientRouting({
            loading: false,
            wizardScreen: true,
          })
        );
      }
      return;
    })
    .catch(() => {
      store.dispatch(
        addToastMessage({
          id: guid(),
          severity: "warning",
          text: t("toastMessages.fetchUserSettingsError"),
        })
      );
    });
  return;
}

export async function initialApiCalls(order_id: string | null = null) {
  //try catch only catches dispatchRetryWrapper if async/await  is used

  try {
    await dispatchRetryWrapper(store.dispatch, fetchAuthenticationUrls(), {
      failureCallback: catastrophicFail,
    });
  } catch (e) {
    // handle error

    return;
  }
  // fetch Terms Status ======================

  try {
    await dispatchRetryWrapper<UserTermsStatus>(
      store.dispatch,
      fetchTermsStatus(),
      {
        retryAmount: 1,
        failureCallback: catastrophicFail,
      }
    ).then(async (termsStatus) => {
      // Go to Terms And Conditions Screen ============================================
      // hasAcceptedCurrentTerms  False
      if (!termsStatus.hasAcceptedCurrentTerms) {
        // Get Terms And Conditions
        try {
          await dispatchRetryWrapper(
            store.dispatch,
            fetchTermsAndConditions(),
            {
              failureCallback: catastrophicFail,
            }
          ).then(() => {
            store.dispatch(
              setEfficientRouting({
                loading: false,
                termsScreen: true,
              })
            );
          });
        } catch (e) {
          store.dispatch(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.fetchTermsAndConditionsError"),
            })
          );

          return;
        }
      } else {
        // Get Account Start ============================================

        try {
          await dispatchRetryWrapper<AccountDetails>(
            store.dispatch,
            fetchAccountDetails(),
            {
              failureCallback: catastrophicFail,
            }
          ).then(async (accountDetails) => {
            // Is Account Active  True

            if (accountDetails.isActive) {
              MainOrWizardSetup();
            } // Is Account Active  False
            else {
              // AccountState  None
              //Pending invites
              store.dispatch(fetchPendingInvite());
              if (
                accountDetails.accountState.toString().toLowerCase() ===
                AccountStates[AccountStates.None].toString().toLowerCase()
              ) {
                //order_id param is null - so not coming from initial Products checkout
                if (order_id == null) {
                  store.dispatch(
                    setEfficientRouting({
                      loading: false,
                      productScreen: true,
                    })
                  );
                } else {
                  //we have an order_id param - so coming from initial Products checkout
                  //check if order_id is the last order id

                  store.dispatch(
                    setEfficientRouting({
                      loading: true,
                    })
                  );

                  // Polling until lastOrderId === order_id
                  // =======================

                  await pollDispatch(
                    () =>
                      dispatchRetryWrapper<AccountDetails>(
                        store.dispatch,
                        fetchAccountDetails(),
                        {
                          failureCallback: catastrophicFail,
                          retryAmount: 40,
                        }
                      ),

                    "lastOrderId",
                    order_id,
                    {
                      failureCallback: () => {
                        store.dispatch(setApiError("partial"));
                        store.dispatch(setApiErrorOpen(true));
                      },
                    }
                  )
                    .then(() => {
                      MainOrWizardSetup();
                    })
                    .catch(() => {
                      store.dispatch(
                        addToastMessage({
                          id: guid(),
                          severity: "warning",
                          text: t("toastMessages.fetchUserPaymentStatusError"),
                        })
                      );
                    });

                  return;
                }
              } // AccountState is not None
              else {
                //Go to Account Screen ============================================
                dispatchRetryWrapper(store.dispatch, fetchIsDarkMode());

                if (order_id == null) {
                  store.dispatch(
                    setEfficientRouting({
                      loading: false,
                      accountScreen: true,
                    })
                  );
                } else if (
                  order_id != null &&
                  order_id !== accountDetails.lastOrderId
                ) {
                  store.dispatch(
                    setEfficientRouting({
                      loading: false,
                      accountScreen: true,
                    })
                  );

                  //////////////////////////////////////////
                  // Some polling here for account screen ? //
                  //////////////////////////////////////////
                } else {
                  //We shouldn't reach this point
                  store.dispatch(
                    addToastMessage({
                      id: guid(),
                      severity: "danger",
                      text: t("toastMessages.notPossibleError"),
                    })
                  );
                }
              }
            }
          });
        } catch (e) {
          store.dispatch(
            addToastMessage({
              id: guid(),
              severity: "warning",
              text: t("toastMessages.fetchAccountDetailsError"),
            })
          );

          return;
        }
      }
    });
  } catch (e) {
    //Silent fail

    return;
  }
}

export type AppThunk = ThunkAction<any, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
// Export a hook that can be reused to resolve types
// Use this to have access to "then" and "catch" otherwise if we use "useDispatch"
// set the function to be dispatched "as any"

export default store;
