import {
  AccountActualState,
  ExtendedAccountDetails,
  Links,
} from "./paymentSlice";
import {
  AccountDetails,
  AccountStates,
  PaymentPlanTypes,
} from "../../serviceClient/api.dtos";
import { readableDate, readableDateTime } from "../../Util";

import { t } from "i18next";

export function paymentAccountLogic(accountData: AccountDetails) {
  let marketingContent = "";
  let currentPlanDescriptionFirst = "";
  let currentPlanDescriptionSecond = "";
  let currentPlanLinks = new Array<Links>();

  let collapsePlans = true;
  let currentPlanShow = true;

  let accountActualState: AccountActualState = "None";

  //Free Account
  if (
    accountData.accountState.toString() ===
    AccountStates[AccountStates.FreeAccount].toString()
  ) {
    //If Free account remove all buttons from Current Plan - Account page
    currentPlanLinks = [];

    //Free account
    accountActualState = "Free";
    if (!accountData.freeUntil) {
      currentPlanDescriptionFirst = t(
        "account.accountStates.freeDay.contentFirst"
      );
    } else if (
      //Free account with freeUntil date

      accountData.freeUntil
    ) {
      currentPlanDescriptionFirst = t(
        "account.accountStates.trialDay.contentFirst",
        { date: readableDate(accountData?.freeUntil) }
      );
    }
  } else if (accountData.isRecurring === false) {
    // users current plan is one day pass (e.g. not recurring)

    /////////////////////////////////////////////
    // If not on trial and day plan can upgrade to business

    if (accountData.isActive) {
      //  within plan time (e.g. now is before activeUntil date)
      if (
        accountData.accountState.toString() ===
        AccountStates[AccountStates.Active].toString()
      ) {
        accountActualState = "Active Day";

        currentPlanDescriptionFirst = t(
          "account.accountStates.activeDay.contentFirst",
          { date: readableDateTime(accountData?.activeUntil) }
        );

        currentPlanLinks.push({
          linkName: "Upgrade Plan",
        });
        //Cancelled Monthly trial - sometimes recurring comes back as true see below
      } else if (
        accountData.accountState.toString() ===
        AccountStates[AccountStates.None].toString()
      ) {
        accountActualState = "Cancelled Trial";

        currentPlanDescriptionFirst = t(
          "account.accountStates.cancelledTrialActiveMonthly.contentFirst"
        );

        currentPlanDescriptionSecond = t(
          "account.accountStates.cancelledTrialActiveMonthly.contentSecond",
          { date: readableDate(accountData?.activeUntil) }
        );

        currentPlanLinks.push({
          linkName: "Show Plans",
        });
      }
    } // day-pass has run out
    else {
      collapsePlans = false;
      currentPlanShow = false;
    }
  } // users current plan is monthly pass
  else {
    // Not Free Account - Is recurring

    switch (accountData.accountState.toString()) {
      // User has signed up to a monthly subscription but have an initial trial period before being charged
      case AccountStates[AccountStates.Trial].toString():
        accountActualState = "Trial Monthly";

        currentPlanDescriptionFirst = t(
          "account.weWillTakeYourFirstPaymentOn",
          { date: readableDate(accountData?.nextBillingDate) }
        );

        currentPlanLinks.push({
          linkName: "Cancel Subscription",
        });

        break;

      // user has an active monthly subscription ======================================
      case AccountStates[AccountStates.Active].toString():
        accountActualState = "Active Monthly";

        currentPlanDescriptionFirst = t(
          "account.accountStates.activeMonthly.contentFirst"
        );
        currentPlanDescriptionSecond = t(
          "account.accountStates.activeMonthly.contentSecond",
          { date: readableDate(accountData?.nextBillingDate) }
        );

        if (
          accountData.planType.toString() ===
          PaymentPlanTypes[PaymentPlanTypes.Solo].toString()
        ) {
          marketingContent = t("planContent.marketingContent");
          currentPlanLinks.push({
            linkName: "Upgrade To Business",
          });
        }
        currentPlanLinks.push({
          linkName: "Cancel Subscription",
        });

        break;
      // Payment failed on automatic renewal of monthly plan, user within grace period where they can still access MNO until their payment sorted out, we will attempt to collect payment again
      case AccountStates[AccountStates.PastDue].toString():
        //use error in useAccountWarning
        accountActualState = "PastDue Monthly";

        currentPlanDescriptionFirst = accountData.paymentDetails
          .lastPaymentFailed
          ? t("account.accountStates.pastDueMonthly.contentFirst", {
              date: readableDate(
                accountData.paymentDetails.lastPaymentFailedDate
              ),
              nextPaymentDate: readableDate(accountData?.nextBillingDate),
            })
          : "";

        currentPlanDescriptionSecond = t(
          "account.accountStates.pastDueMonthly.contentSecond"
        );

        currentPlanLinks.push(
          {
            linkName: "Update Payment Details",
          },
          {
            linkName: "Cancel Subscription",
          }
        );
        break;
      // final payment attempt has failed so we have remove access for the user (isActive must be false)
      case AccountStates[AccountStates.Delinquent].toString():
        if (accountData.isActive === false) {
          //use error in useAccountWarning
          accountActualState = "Delinquent Monthly";

          currentPlanDescriptionFirst = t(
            "account.accountStates.delinquentMonthly.contentFirst"
          );
          currentPlanDescriptionSecond = t(
            "account.accountStates.delinquentMonthly.contentSecond",
            {
              date: readableDate(
                accountData.paymentDetails?.lastPaymentFailedDate
              ),
            }
          );

          currentPlanLinks.push(
            {
              linkName: "Update Payment Details",
            },
            {
              linkName: "Cancel Subscription",
            }
          );
        }
        break;
      // user has cancelled the renewal of their plan, they may or may not be active
      case AccountStates[AccountStates.Cancelled].toString():
        // if the user is within their activeUntil date

        currentPlanLinks.push({
          linkName: "Restart Subscription",
        });
        if (accountData.isActive) {
          accountActualState = "Cancelled Active Monthly";
          currentPlanDescriptionFirst = t(
            "account.accountStates.cancelledActiveMonthly.contentFirst"
          );

          currentPlanDescriptionSecond = t(
            "account.accountStates.cancelledActiveMonthly.contentSecond",
            { date: readableDate(accountData?.activeUntil) }
          );
        } // if the user is out of their activeUntil date
        else {
          //Expired

          accountActualState = "Cancelled Expired Monthly";

          currentPlanDescriptionFirst = t(
            "account.accountStates.cancelledExpiredMonthly.contentFirst",
            { date: readableDate(accountData?.activeUntil) }
          );

          currentPlanDescriptionSecond = t(
            "account.accountStates.cancelledExpiredMonthly.contentSecond",
            { name: accountData.mnoPlanName }
          );

          currentPlanLinks.push({
            linkName: "Switch Plan",
          });
        }
        break;

      // sometimes recurring is true after cancellation (e.g. if the user cancels their plan but is still within their activeUntil date)
      case AccountStates[AccountStates.None].toString():
        accountActualState = "Cancelled Trial";

        // Don't show plans because when cancelled we don't have credit card details
        collapsePlans = true;

        currentPlanDescriptionFirst = t(
          "account.accountStates.cancelledTrialActiveMonthly.contentFirst"
        );

        currentPlanDescriptionSecond = t(
          "account.accountStates.cancelledTrialActiveMonthly.contentSecond",
          { date: readableDate(accountData?.activeUntil) }
        );
        currentPlanLinks.push({
          linkName: "Show Plans",
        });

        break;
      default:
        //error

        break;
    }
  }

  const accountDataPlus = {
    ...accountData,
    marketingContent,
    currentPlanDescriptionFirst,
    currentPlanDescriptionSecond,
    currentPlanLinks,
    collapsePlans: collapsePlans,
    currentPlanShow: currentPlanShow,
    accountActualState: accountActualState,
  } as ExtendedAccountDetails;

  return accountDataPlus;
}
