import * as signalR from "@microsoft/signalr";

import { IServerEvents } from "./IServerEvents";
import { PublicClientApplication } from "@azure/msal-browser";
import { getServerEventHandlers } from "./ServerEventHandlers";
import { getTokenRedirect } from "../../Util";
import { setImporterSignalRConnectionState } from "../../features/importer/importerSlice";
import { showImporterTimerModal } from "../../features/importer/importerSliceThunks";

const importerEvents = [
  "ModelProcessingComplete",
  "ResultProcessingComplete",
  "FileProcessingErrorResult",
  "FileProcessingApiErrorResult",
  "FileStatusChanged",
  "FileUploadComplete",
  "FileTooLarge",
];
const serverEventURI = "/importerhub";
export default class ServerEventsImport implements IServerEvents {
  protected _sessionId: string;

  private _dispatch: (action: any) => void;

  private _hubConnection = new signalR.HubConnectionBuilder()

    .configureLogging(
      process.env.REACT_APP_BUILD_TYPE === "production"
        ? signalR.LogLevel.Warning
        : signalR.LogLevel.Debug
    )
    .withUrl(process.env.REACT_APP_API_BASE_URL + serverEventURI, {
      accessTokenFactory: async () => {
        let token: string = "";
        await getTokenRedirect(window.MsalInstance as PublicClientApplication)
          .then((response) => {
            if (response) {
              token = response.token ?? "";
            }
          })
          .catch(() => "");
        return token;
      },

      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();

  private _startSignalRConnection = () => {
    return this._hubConnection
      .start()
      .then(async () => {
        console.debug(
          "Websocket Connection Established - sever events *import*  ",
          this._hubConnection.state
        );
        await this._hubConnection
          .invoke("ListenForUpdates", this._sessionId)
          .then(() => {
            console.debug("Listening for updates", new Date().toISOString());
          })
          .catch(function (err) {
            return console.debug("SignalR ", err.toString());
          });

        this._dispatch(
          setImporterSignalRConnectionState(this._hubConnection.state)
        );
      })
      .catch(() => {
        this._dispatch(
          setImporterSignalRConnectionState(this._hubConnection.state)
        );
        if (
          this._hubConnection.state !== signalR.HubConnectionState.Disconnected
        ) {
          setTimeout(() => {
            this._hubConnection.invoke("ListenForUpdates", this._sessionId);
          }, 3000);
        }
      });
  };
  public constructor() {
    this.initialiseConnection();
  }

  public connectionIsLive(): boolean {
    console.debug("connectionIsLive", this._hubConnection.state);

    return this._hubConnection.state === "Connected";
  }

  public async dispose() {
    console.debug("dispose", this._hubConnection.state);

    await this._hubConnection.stop();
    this._dispatch(
      setImporterSignalRConnectionState(this._hubConnection.state)
    );
  }

  public setDispatch(dispatch: (action: any) => void) {
    this._dispatch = dispatch;
  }

  public async setSessionId(sessionId: string) {
    this._sessionId = sessionId;

    await this.resetChannels();
  }

  public triggerEvent(event: { eventName: string; params: object }) {
    getServerEventHandlers(this._dispatch)[event.eventName](event.params);
  }

  protected _onConnection() {
    importerEvents.map(
      (event) =>
        this._hubConnection.on(event, (model: any) => {
          this.triggerEvent({
            eventName: event,
            params: model,
          });
        }),

      this._onCloseConnection()
    );
  }
  protected _onCloseConnection() {
    // Let the user re-establish the connection if connection dropped
    this._hubConnection.onclose(() => {
      //only if the user is on the importer page or importer modal

      console.debug("onclose --");
      return this._dispatch(showImporterTimerModal(true));
      //  setTimeout(this._onConnection, 5000);
    });
  }

  protected async initialiseConnection(): Promise<void> {
    this._onConnection();
    if (this._sessionId) {
      this._onCloseConnection();
    }
  }

  private async resetChannels(): Promise<void> {
    if (this._hubConnection.state !== "Disconnected") {
      await this._hubConnection.stop();
    }

    if (this._sessionId) {
      await this._startSignalRConnection();
    }
  }
  public getHubConnection = () =>
    this._hubConnection.state === signalR.HubConnectionState.Connected
      ? this._hubConnection
      : null;
}
