import { CSSProperties, lazy } from "react";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

import { FolderHierarchy } from "./serviceClient/api.dtos";
import { LinkName } from "./features/payment/paymentSlice";
import { Retry } from "./features/common/commonTypes";
import cookie from "react-cookies";
import { setNoInternetConnection } from "./features/common/commonSlice";
import { t } from "i18next";
import { useAppDispatch } from "./app/hooks/reduxTypedHooks";

export function isObjectEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}

// This function is used to check if an object has any keys.
// If the object has no keys, it returns false.
// Otherwise, it returns true.

export function isObjectWithValue(obj: Object | null | undefined) {
  // If object has no keys, return false
  if (obj == null || Object.keys(obj).length === 0) {
    return false;
  }
  // Otherwise, return true
  return true;
}

export const enumKeys = (targetEnum: object) =>
  Object.keys(targetEnum).reduce((arr: string[], key) => {
    if (!arr.includes(targetEnum[key])) {
      arr.push(targetEnum[key]);
    }
    return arr;
  }, []);
// Helper

export function enumToKeyValueArray(value: object) {
  let array: { id: string; text: string; value: string }[] = [];

  for (const [propertyKey, propertyValue] of Object.entries(value)) {
    array.push({ id: propertyKey, text: propertyKey, value: propertyValue });
  }

  return array;
}

export function stringArrayToKeyValueArray(value: string[]) {
  let array: { id: string; text: string; value: string }[] = [];

  for (const propertyKey of value) {
    array.push({ id: propertyKey, text: propertyKey, value: propertyKey });
  }

  return array;
}

export function enumToKeyArray<T extends object>(value: T) {
  let array: string[] = [];

  for (const [propertyKey] of Object.entries(value)) {
    if (Number.isNaN(Number(propertyKey))) {
      array.push(propertyKey);
    }
  }

  return array;
}

export const guid = () => {
  const foo: any = [1e7];
  return (foo + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,

    (c) =>
      (
        c ^
        (crypto.getRandomValues(
          // eslint-disable-line no-mixed-operators
          // tslint:disable-next-line:no-bitwise
          new Uint8Array(1)
        )[0] &
          (15 >> (c / 4)))
      ) // eslint-disable-line no-mixed-operators
        .toString(16)
  );
};

export const parseFloatNoNaN = (input: string, defaultValue: number) => {
  const parsedValue = parseFloat(input);

  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

export const parseIntNoNaN = (input: string, defaultValue: number) => {
  const parsedValue = parseInt(input, 10);

  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

export const parseIntNoNaNString = (input: string, defaultValue: string) => {
  const parsedValue = parseInt(input, 10);

  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

/**
 * This is a function that takes in an object of CSS properties and converts it
 * to a string of CSS properties.
 * @param cssProperties An object containing CSS properties.
 * @returns A string of CSS properties.
 */
export const convertCSSPropertiesToString = (cssProperties: CSSProperties) => {
  let cssString = "";

  for (const objectKey in cssProperties) {
    if (cssProperties.hasOwnProperty(objectKey)) {
      const string = `${objectKey.replace(
        /([A-Z])/g,
        (g) => `-${g[0].toLowerCase()}` // Replace any uppercase letters with a hyphen and lowercase letter.
      )}: ${cssProperties[objectKey]};`;

      //if the property value is not empty string, add it to the string
      if (cssProperties[objectKey]) {
        cssString += string;
      }
    }
  }

  return cssString;
};

export function sleep(ms: number): Promise<any> {
  // tslint:disable-next-line:ban
  return new Promise((resolve): NodeJS.Timeout => setTimeout(resolve, ms));
}

export function ObjectToStringValuesAndStringify(paramObj: object) {
  ///take a copy to remove the store reference.
  const ParamObj = { ...paramObj };

  let compareMap = Object.keys(ParamObj).map((key) => {
    if (typeof ParamObj[key] === "object") {
      return ObjectToStringValuesAndStringify(ParamObj[key]);
    }

    return (ParamObj[key] = String(ParamObj[key]));
  });

  return JSON.stringify(compareMap);
}

/**
 * try/catch only catches dispatchRetryWrapper if async/await is used
 * @type   Return type
 * @param { typeof useAppDispatch }  dispatch  A useAppDispatch param.
 * @param {any} dispatchAction  AsyncThunkAction
 * @param {Retry} options  Retry Object.
 * @returns {void} Void
 */
export async function dispatchRetryWrapper<T>(
  dispatch: ReturnType<typeof useAppDispatch>,

  dispatchAction: any,
  {
    retryAmount = 1,
    failureCallback,
    sleepAmount = 1000,
    tryFunction,
  }: Partial<Retry> = {}
): Promise<T> {
  return new Promise((resolve, reject) => {
    if (window.navigator.onLine) {
      //

      dispatch(dispatchAction)
        .unwrap()
        .then(
          (action: T) => {
            resolve(action);
          },
          // Fail
          async (e: any) => {
            //stop retry if any of these errors
            const failedToFetch = e.message?.includes("Failed to fetch");

            const errorStop =
              e.responseStatus?.errorCode === "NoActiveSubscriptionException" ||
              e.responseStatus?.errorCode === "AccountIsLockedException" ||
              e.responseStatus?.errorCode === "DownForMaintenance" ||
              e.responseStatus?.errorCode === "503";

            if (retryAmount > 0 && !errorStop && !failedToFetch) {
              //function to try between retries

              tryFunction && (await tryFunction().catch((e) => reject(e)));

              await sleep(sleepAmount);

              await dispatchRetryWrapper(dispatch, dispatchAction, {
                retryAmount: retryAmount - 1,
                failureCallback,
                sleepAmount,
              });
            } else {
              if (!errorStop || failedToFetch) {
                failureCallback && failureCallback();
              }
              reject(e);
            }
          }
        )
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          dispatch(setNoInternetConnection(false));
        });
    } else {
      dispatch(setNoInternetConnection(true));
      reject("No Internet Connection");
    }
  });
}

export async function pollDispatch<T>(
  dispatchFunction: any,
  resultProperty: string,
  equality: any,
  { retryAmount = 20, failureCallback, sleepAmount = 2000 }: Partial<Retry> = {}
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    await dispatchFunction()
      .then(async (res: T) => {
        if (res[resultProperty] === equality) {
        } else {
          if (retryAmount > 0) {
            await sleep(sleepAmount);

            await pollDispatch(dispatchFunction, resultProperty, equality, {
              retryAmount: retryAmount - 1,
              failureCallback,
              sleepAmount,
            });
          } else {
            //Fail
            failureCallback && failureCallback();
            reject();
          }
        }
      })
      .then((res: T) => {
        resolve(res);
      })
      .catch((e: any) => {
        failureCallback && failureCallback();
        reject(e);
      });
  });
}

// This function gets all the cache names and delete them.
// It is used to clear the cache data.
export function clearCacheData() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
}

export const logOutThunk = (): any => () => {
  cookie.remove("ImageAuthentication");

  clearCacheData();
  if (window?.productFruits && "services" in window.productFruits) {
    (window.productFruits.services as any).destroy();
  }
  window.MsalInstance.logoutRedirect();
};

/**
 * This function is used to check if an image exists
 * @param {string | null | undefined} imgUrl
 * @returns {Promise<boolean>}
 */
export async function imageExists(
  imgUrl: string | null | undefined
): Promise<boolean> {
  if (!imgUrl) {
    return false;
  }

  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src = imgUrl;
  });
}

export function removeDuplicateObjectFromArray<T>(
  array: Array<T>,
  key: string
) {
  let check = new Set();
  return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
}

export const cleanFloat = (inputText, defaultValue) => {
  return parseFloatNoNaN(inputText?.replace(/[^\d.]/, ""), defaultValue);
};

// This function takes a function that returns a Promise and returns a React
// component that can be used with React.lazy. The component will have a
// preload static method that can be used to preload the code before it
// is used for the first time.
export function lazyWithPreload(factory: any) {
  // Create the lazy component.
  const Component: any = lazy(factory);

  // Add a preload method to the component.
  Component.preload = factory;

  // Return the component.
  return Component;
}

// This function converts a date string into a human-readable date string using the current locale.
// e.g. 2021-01-01T00:00:00.000Z => 1st January 2021
export function readableDate(date: string | undefined) {
  if (!date) return "";

  return new Date(date).toLocaleDateString(window?.navigator?.language!, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

export function readableDateTime(date: string | undefined) {
  if (!date) return "";

  return new Date(date).toLocaleTimeString(window?.navigator?.language!, {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export function buttonName(linkName: LinkName) {
  let name: string = "";
  switch (linkName) {
    case "Upgrade Plan":
      name = t("account.linkName.upgradePlan");
      break;
    case "Buy Plan":
      name = t("account.linkName.buyPlan");
      break;
    case "Cancel Subscription":
      name = t("account.linkName.cancelSubscription");
      break;
    case "Restart Subscription":
      name = t("account.linkName.restartSubscription");
      break;
    case "Switch Plan":
      name = t("account.linkName.switchPlan");
      break;
    case "Update Payment Details":
      name = t("account.linkName.updatePaymentDetails");
      break;
    case "Upgrade To Business":
      name = t("account.linkName.upgradeToBusiness");
      break;
    case "Show Plans":
      name = t("account.linkName.showPlans");
      break;
    default:
      break;
  }

  return name;
}

export function getBase64(file: File) {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null;
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;

      resolve(baseURL);
    };
  });
}

/**
 * Flattens a hierarchical array of folders by removing the 'childFolders' property from each folder object.
 * @param {FolderHierarchy[]} arr - The array of folder hierarchies to flatten.
 * @returns {FolderHierarchy[]} - The flattened array of folder hierarchies.
 */
export function flattenFolders(arr: FolderHierarchy[]): FolderHierarchy[] {
  function dirtyArr(arr: FolderHierarchy[]): FolderHierarchy[] {
    let result: FolderHierarchy[] = [];

    arr.forEach((arrItem) => {
      // Add the current folder to the result array
      result.push(arrItem);

      // If the current folder has child folders
      if (Array.isArray(arrItem.childFolders)) {
        // Call dirtyArr recursively with the child folders and concatenate the result with the current result array
        result = result.concat(dirtyArr(arrItem.childFolders));
      }
    });

    return result;
  }

  //Remove childFolders property
  function cleanArr(arr: FolderHierarchy[]) {
    // Iterate over each folder in the array
    arr.forEach((arrItem) => {
      // If the folder has a childFolders property
      if (arrItem.hasOwnProperty("childFolders")) {
        // Delete the childFolders property
        delete (arrItem as any).childFolders;
      }
    });
    return arr;
  }

  return cleanArr(dirtyArr(arr));
}

/**
 * Finds a folder in a nested structure of folders by its ID.
 * Current selected folder and children.
 *
 * @param {string} id - The ID of the folder to find.
 * @param {FolderHierarchy[]} folders - The array of folders to search through.
 * @returns {FolderHierarchy} The folder with the matching ID, or an empty object if no match was found.
 */
export function findFolderTreeById(
  id: string,
  folders: FolderHierarchy[]
): FolderHierarchy {
  let res: FolderHierarchy = {} as FolderHierarchy;

  function findFolders(folders: FolderHierarchy[], id: string) {
    // Iterate over each folder in the array
    for (let i = 0; i < folders.length; i++) {
      // If the current folder's ID matches the given ID
      if (folders[i].id === id) {
        // Assign the current folder to res and break the loop
        res = folders[i];

        break;
      }
      // If the current folder has child folders
      if (folders[i].childFolders) {
        // Call findFolders recursively with the child folders
        findFolders(folders[i].childFolders, id);
      }
    }
  }
  // Call findFolders with the initial array of folders and the ID
  findFolders(folders, id);

  return res;
}
/**
 * Recursive helper function to find a child folder by its ID in a given folder's hierarchy.
 *
 * @param {string} id - The ID of the child folder to find.
 * @param {FolderHierarchy} folder - The folder in which to search for the child.
 * @param {string[]} parents - An array to store the IDs of the parent folders.
 * @returns {boolean} - Returns true if the child folder is found, false otherwise.
 */
export function isFoundChild(
  id: string,
  folder: FolderHierarchy,
  parents: string[]
): boolean {
  // Check if the folder has a direct child with the given ID
  if (folder.childFolders?.find((item) => item.id === id)) {
    return true;
  } else {
    // If not, iterate over each child folder
    for (let item of folder.childFolders!) {
      // If a child folder has its own child folders
      if (item.childFolders?.length)
        if (isFoundChild(id, item, parents)) {
          // Call isFoundChild recursively to search in the child folder's hierarchy
          // If the child folder is found in the subfolders, add the current folder's ID to the parents array
          parents.push(item.id);
          return true;
        }
    }
    // If the child folder is not found in any of the subfolders, return false
    return false;
  }
}
/**
 * Retrieves the parent IDs of a given folder ID in a folder hierarchy.
 * @param id - The ID of the folder to find the parents for.
 * @param folders - An array of FolderHierarchy objects representing the folder hierarchy.
 * @returns An array of string values representing the parent IDs of the folder.
 */
export function getParentsById(
  id: string,
  folders: FolderHierarchy[]
): string[] {
  // Initialize an empty array to store the IDs of the parent folders
  const parents: string[] = [];

  // Check if the folders array contains a folder with the given ID
  if (folders.find((item) => item.id === id)) {
    // If it does, return an empty array because the folder has no parents
    return [];
  } else {
    // If not, iterate over each folder in the folders array
    for (let item of folders) {
      // If a folder has child folders
      if (item.childFolders?.length) {
        // Call isFoundChild to check if the folder with the given ID is a child of the current folder
        if (isFoundChild(id, item, parents)) {
          // If it is, add the current folder's ID to the parents array and break the loop
          parents.push(item.id);
          break;
        }
      }
    }
  }

  // Return the parents array, which contains the IDs of all parent folders of the folder with the given ID
  return parents;
}

/**
 * Retrieves the IDs of all folders in a hierarchical structure.
 *
 * @param childFolders - The child folders to traverse.
 * @param ids - The array to store the folder IDs. Defaults to an empty array.
 * @returns An array of folder IDs.
 */
export function getTreeIds(
  childFolders: FolderHierarchy[] | undefined,
  ids: string[] = []
): string[] {
  if (!childFolders) return ids;

  const getId = (childFolders: FolderHierarchy[]) =>
    childFolders?.forEach((f) => {
      ids.push(f.id);

      getId(f.childFolders);
    });
  getId(childFolders);
  return ids;
}

export function deleteById(folders: FolderHierarchy[], id: string) {
  folders.forEach((folder) => {
    if (folder.id === id) {
      folders.splice(folders.indexOf(folder), 1);
    } else {
      deleteById(folder.childFolders, id);
    }
  });
}

export function uncapitalise(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export async function getTokenRedirect(
  msalInstance: PublicClientApplication
): Promise<void | {
  token: string;
  isFromCache: boolean;
}> {
  const activeAccount = msalInstance.getActiveAccount();

  const silentRequest = {
    scopes: [process.env.REACT_APP_CLIENT_ID as string],
    account: activeAccount || msalInstance.getAllAccounts()[0],
  };

  const tokenResponse = await msalInstance
    .acquireTokenSilent(silentRequest)
    .then((accessTokenResponse) => {
      const JWT = accessTokenResponse.idToken;

      // Call your API with token
      return { token: JWT, isFromCache: accessTokenResponse.fromCache };
    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(silentRequest);
      }
    });

  return tokenResponse;
}

export function getDepth(
  folder: FolderHierarchy,
  folders: FolderHierarchy[]
): number {
  let depth = 0;
  let parentId = folder.parentId;

  while (parentId) {
    depth++;
    const parentFolder = folders[parentId];
    if (!parentFolder) break; // If parent folder is not found, break the loop
    parentId = parentFolder.parentId;
  }

  return depth;
}
