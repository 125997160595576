import { FunctionComponent, useEffect, useRef } from "react";
import {
  faCog,
  faComment,
  faCreditCard,
  faKey,
  faSignOutAlt,
  faTriangleExclamation,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import {
  setFeedbackModalVisibility,
  setFeedbackText,
} from "../feedback/feedbackSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { Collapse } from "bootstrap";
import EfficientTooltip from "../common/EfficientTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Library } from "../../assets/icons/Library.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as Nests } from "../../assets/icons/Nests.svg";
import { ReactComponent as NewNest } from "../../assets/icons/NewNest.svg";
import { getAuthUrls } from "../landing/landing";
import { getDarkMode } from "../settings/settings";
import { getEfficientRouting } from "../efficientRouting/efficientRouting";
import { getMainNavCollapse } from "../common/common";
import { getUser } from "../payment/payment";
import { logOutThunk } from "../../Util";
import { resetNesting } from "../nesting/nestingSlice";
import { setMainNavCollapse } from "../common/commonSlice";
import { useAccountWarning } from "../../app/hooks/useAccountWarning";
import useIntercept from "../../app/hooks/useIntercept";
import { useTranslation } from "react-i18next";
import useUpgrade from "../../app/hooks/useUpgrade";

const MobileNav: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { upgradeAccount } = useUpgrade();
  const interceptClick = useIntercept();
  const authUris = useAppSelector(getAuthUrls);
  const darkMode = useAppSelector(getDarkMode);
  const user = useAppSelector(getUser);
  const { accountWarning } = useAccountWarning();
  const { i18n } = useTranslation();
  const efficientRoutingState = useAppSelector(getEfficientRouting);

  const mainNavCollapse = useAppSelector(getMainNavCollapse);

  const mainNavRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const openNavCollapse = () => {
    const navElement = mainNavRef.current;
    const bsCollapse = new Collapse(navElement, {});
    bsCollapse.show();
  };

  const closeNavCollapse = () => {
    const navElement = mainNavRef.current;
    const bsCollapse = Collapse.getInstance(navElement);
    bsCollapse?.hide();
  };

  useEffect(() => {
    if (mainNavRef.current) {
      if (mainNavCollapse) {
        openNavCollapse();
      } else {
        closeNavCollapse();
      }
    }
  }, [mainNavCollapse]);

  return (
    typeof authUris !== "undefined" && (
      <>
        {/* Navbar Toggle Button */}
        <nav
          className={`col navbar navbar-${
            darkMode ? "dark" : "light"
          }  me-2 d-flex d-md-none align-items-center justify-content-end`}
        >
          <button
            className="navbar-toggler"
            onClick={() => dispatch(setMainNavCollapse(!mainNavCollapse))}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
        <div className="col-12 col-md-auto d-flex align-items-center">
          <nav
            className={`navbar navbar-expand-md p-0 w-100 navbar-${
              darkMode ? "dark" : "light"
            } d-xxxl-none`}
          >
            <div className="col">
              <div
                ref={mainNavRef}
                className="collapse navbar-collapse w-100"
                id="navbarAlternate"
              >
                <ul className="navbar-nav mx-auto d-flex align-items-md-center">
                  {efficientRoutingState.mainAccessScreens && (
                    <>
                      {/* ================== Mobile New Nests ================== */}
                      <li className="nav-item">
                        <NavLink
                          title={t("titles.newNest")!}
                          to={"/" + i18n.language + "/" + t("paths.nesting")!}
                          id="button-newnestMobile"
                          className={({ isActive }) =>
                            "text-capitalize theme-mobile-nav text-decoration-none nav-link" +
                            (isActive ? " theme-nav-active-mobile active" : "")
                          }
                          onClick={(event) => {
                            interceptClick({
                              event,
                              link:
                                "/" + i18n.language + "/" + t("paths.nesting")!,
                              callback: () => dispatch(resetNesting()),
                              sessionType: ["nestingSession", "importSession"],
                            });
                          }}
                        >
                          <NewNest
                            className="me-2 theme-nav-icon theme-nav-icon-color"
                            width={22}
                          />

                          {t("nav.newNest")}
                        </NavLink>
                      </li>
                      {/* ==================== Mobile Nests ==================== */}
                      <li className="nav-item">
                        <NavLink
                          id="button-nestsMobile"
                          title={t("titles.nests")!}
                          to={"/" + i18n.language + "/" + t("paths.nests")!}
                          className={({ isActive }) =>
                            "text-capitalize theme-mobile-nav text-decoration-none nav-link" +
                            (isActive ? " theme-nav-active-mobile active" : "")
                          }
                          onClick={(event) => {
                            interceptClick({
                              event,
                              link:
                                "/" + i18n.language + "/" + t("paths.nests")!,
                              sessionType: ["nestingSession", "importSession"],
                            });
                          }}
                        >
                          <>
                            <Nests
                              className="me-2 theme-nav-icon theme-nav-icon-color"
                              width={22}
                            />
                            {t("nav.nests")}
                          </>
                        </NavLink>
                      </li>
                      {/* ==================== Mobile Library ==================== */}
                      <li className="nav-item">
                        <EfficientTooltip
                          content={
                            user.teamPlan.partsLibraryEnabled
                              ? []
                              : [t("tooltip.clickToUpgrade")]
                          }
                          delayShow={500}
                        >
                          <NavLink
                            id="button-libraryMobile"
                            title={
                              user.teamPlan.partsLibraryEnabled
                                ? t("titles.library")!
                                : ""
                            }
                            to={"/" + i18n.language + "/" + t("paths.library")!}
                            className={({ isActive }) =>
                              "text-capitalize theme-mobile-nav text-decoration-none nav-link  " +
                              (user.teamPlan.partsLibraryEnabled
                                ? ""
                                : "faux-disabled ") +
                              (isActive
                                ? " theme-nav-active-mobile active"
                                : "")
                            }
                            onClick={(event) => {
                              //Disable Link
                              if (!user.teamPlan.partsLibraryEnabled) {
                                upgradeAccount();
                                event.preventDefault();
                                return;
                              }
                              interceptClick({
                                event,
                                link:
                                  "/" +
                                  i18n.language +
                                  "/" +
                                  t("paths.library")!,
                                sessionType: [
                                  "nestingSession",
                                  "importSession",
                                ],
                              });
                            }}
                          >
                            <>
                              <Library
                                className="me-2 theme-nav-icon theme-nav-icon-color"
                                width={22}
                              />
                              {t("nav.library")}
                            </>
                          </NavLink>
                        </EfficientTooltip>
                      </li>
                      {/* ==================== Mobile FeedBack ==================== */}
                      <li className="nav-item d-md-none">
                        <a
                          id="button-feedbackMobile"
                          href="/#"
                          role="button"
                          title={t("titles.feedback")!}
                          className="text-capitalize theme-mobile-nav text-decoration-none nav-link"
                          onClick={(e) => {
                            e.preventDefault();
                            window.requestAnimationFrame(() => {
                              dispatch(setFeedbackModalVisibility(true));
                              dispatch(setFeedbackText(""));
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faComment}
                            className="me-2"
                          />
                          {t("nav.feedback")}
                        </a>
                      </li>
                      {/* ==================== Mobile Settings ==================== */}
                      <li className="nav-item d-md-none">
                        <NavLink
                          id="button-settingsMobile"
                          title={t("titles.settings")!}
                          to={"/" + i18n.language + "/" + t("paths.settings")!}
                          className={({ isActive }) =>
                            "text-capitalize theme-mobile-nav text-decoration-none nav-link" +
                            (isActive ? " theme-nav-active-mobile active" : "")
                          }
                          onClick={(event) => {
                            interceptClick({
                              event,
                              link:
                                "/" +
                                i18n.language +
                                "/" +
                                t("paths.settings")!,
                              sessionType: ["nestingSession", "importSession"],
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faCog}
                            className="me-2"
                          />
                          {t("nav.settings")}
                        </NavLink>
                      </li>
                      <hr className="d-md-none"></hr>
                      {/* ==================== Mobile Account ==================== */}
                      <li className="nav-item d-md-none">
                        <NavLink
                          title={t("titles.account")!}
                          to={"/" + i18n.language + "/" + t("paths.account")!}
                          className="text-capitalize theme-mobile-nav text-decoration-none nav-link"
                          id="button-accountMobile"
                          onClick={(event) => {
                            interceptClick({
                              event,
                              link:
                                "/" + i18n.language + "/" + t("paths.account")!,
                              sessionType: ["nestingSession", "importSession"],
                            });
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <span>
                              <FontAwesomeIcon
                                fixedWidth
                                icon={faCreditCard}
                                className="me-2"
                              />
                              {t("nav.account")}
                            </span>
                            {/* Warning Triangle For Invalid Card && Missed Payment */}
                            {accountWarning && (
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                fixedWidth
                                className={`text-danger  `}
                              />
                            )}
                          </div>
                        </NavLink>
                      </li>
                      {/* ================== Teams Management =================== */}

                      <li className="nav-item d-md-none">
                        <NavLink
                          id="button-teamsMobile"
                          title={t("titles.teams")!}
                          to={"/" + i18n.language + "/" + t("paths.teams")!}
                          className={`text-capitalize theme-mobile-nav text-decoration-none nav-link ${
                            user.teamPlan.teamsEnabled ? "" : " disabled"
                          }`}
                          onClick={(event) => {
                            if (!user.teamPlan.teamsEnabled) return;
                            interceptClick({
                              event,
                              link:
                                "/" + i18n.language + "/" + t("paths.teams")!,
                              sessionType: ["nestingSession", "importSession"],
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faUserGroup}
                            className="me-2"
                          />
                          {t("nav.teams")}
                        </NavLink>
                      </li>
                    </>
                  )}
                  {/* ==================== Mobile Change Password ==================== */}
                  <li className="nav-item d-md-none">
                    <a
                      title={t("titles.changePassword")!}
                      className="text-capitalize theme-mobile-nav text-decoration-none nav-link"
                      href={authUris?.resetPasswordUri}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faKey}
                        className="me-2"
                      />
                      {t("nav.changePassword")}
                    </a>
                  </li>

                  {/* <li className="nav-item d-md-none">
                      <LanguageSwitcherMobile />
                    </li> */}

                  {/* ==================== Mobile Logout ==================== */}
                  <li className="nav-item d-md-none">
                    <a
                      id="button-logoutMobile"
                      role="button"
                      href="/#"
                      title={t("titles.logOut")!}
                      className="text-capitalize theme-mobile-nav ps-0 text-decoration-none w-100 text-start nav-link"
                      onClick={(event) => {
                        event.preventDefault();
                        interceptClick({
                          event,
                          callback: () => dispatch(logOutThunk()),
                          sessionType: ["nestingSession", "importSession"],
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faSignOutAlt}
                        className="me-2"
                      />
                      {t("nav.logout")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </>
    )
  );
};

export default MobileNav;
