import {
  setCheckoutSuccessLoader,
  setCurrency,
  setPaddleProgress,
  setProductsLoader,
  setSelectedProduct,
  setSuccessType,
  setTrial,
} from "../../features/payment/paymentSlice";

import { useAppDispatch } from "./reduxTypedHooks";

function usePaddle(Paddle?: any) {
  const dispatch = useAppDispatch();

  //const Paddle = window.Paddle;
  function PaddleSetup(data) {
    dispatch(setCheckoutSuccessLoader(false));
    // Stop paddle spinner on payment complete  / appears on top of efficient loading overlay
    const trial =
      data.eventData?.checkout?.prices?.customer?.items[0]?.recurring
        ?.trial_days > 0;

    if (trial) {
      dispatch(setSuccessType("trial"));
    } else {
      dispatch(setSuccessType("payment"));
    }

    dispatch(setTrial(trial));
    if (
      data.event === "PaymentComplete" ||
      data.event === "Checkout.Complete"
    ) {
      Paddle.Spinner.hide();

      dispatch(setCheckoutSuccessLoader(true));
      dispatch(setProductsLoader(true));
    }
    if (data.event === "Checkout.Complete") {
      //  dispatch(setSuccessType(""));
    }
    if (data.event === "Checkout.Loaded") {
      dispatch(setProductsLoader(false));
    }

    dispatch(setCurrency(data.eventData?.checkout?.prices?.customer?.currency));

    // if no trial then update discount coupon prices
    if (!trial) {
      dispatch(
        setSelectedProduct(
          data.eventData?.checkout?.prices?.customer?.items[0]?.line_price
        )
      );
    }

    dispatch(setPaddleProgress(data.event));
  }

  return {
    PaddleSetup,
  };
}

export default usePaddle;
