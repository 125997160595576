import {
  GetOptionsForUsageQuestions,
  OptionSubmissionComplete,
  OptionsForUsageQuestions,
  RequestCCFreeTrial,
  SubmitOptionsForUsageQuestions,
} from "../../serviceClient/api.dtos";
import { Loading, SessionType, UserInfo } from "./commonTypes";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { InteractionStatus } from "@azure/msal-browser";
import { JsonServiceClient } from "@servicestack/client";

type RotateType = "library" | "nesting" | "";
type RotateModal = {
  type: RotateType;
  visible: boolean;
};
//
export type NavigationWarning = {
  destination: string;
  message: string;
  open: boolean;
  callback?: Function;
  sessionType: SessionType[];
};

type CommonState = {
  cloneModalOpen: boolean;
  deletePartModalOpen: boolean;
  downForMaintenanceMessage: string;
  mainNavCollapse: boolean;
  msalProgress: InteractionStatus;
  navigationWarning: NavigationWarning;
  noInternetConnection: boolean;
  partSearchCollapse: boolean;
  patchInProgress: boolean;
  productActionModalOpen: boolean;
  requestCCFreeTrialLoading: Loading;
  resizeNestingPartsSheets: string;
  rotatePartModalVisibility: RotateModal;
  saveAsModalOpen: boolean;
  surveyAnswersCompleted: OptionSubmissionComplete;
  surveyAnswersLoading: Loading;
  surveyQuestionLoading: Loading;
  surveyQuestionOptions: OptionsForUsageQuestions;
  trialApplicationModalOpen: boolean;
  updatePaymentModalOpen: boolean;
  userInfo: UserInfo;
};

const initialState: CommonState = {
  cloneModalOpen: false,
  deletePartModalOpen: false,
  downForMaintenanceMessage: "",
  mainNavCollapse: false,
  msalProgress: "none",
  navigationWarning: {
    destination: "",
    message: "",
    open: false,
    callback: undefined,
    sessionType: [] as SessionType[],
  },
  noInternetConnection: false,
  partSearchCollapse: false,
  patchInProgress: false,
  productActionModalOpen: false,
  requestCCFreeTrialLoading: "idle",
  resizeNestingPartsSheets: "",
  rotatePartModalVisibility: { type: "", visible: false } as RotateModal,
  saveAsModalOpen: false,
  surveyAnswersCompleted: new OptionSubmissionComplete(),
  surveyAnswersLoading: "pending",
  surveyQuestionLoading: "pending",
  surveyQuestionOptions: new OptionsForUsageQuestions(),
  trialApplicationModalOpen: false,
  updatePaymentModalOpen: false,
  userInfo: {} as UserInfo,
};

export const requestCCFreeTrial = createAsyncThunk(
  "commonSlice/requestCCFreeTrial",
  async (form: Partial<RequestCCFreeTrial>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new RequestCCFreeTrial(form))
        .then((data) => {
          return data;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchSurveyQuestions = createAsyncThunk(
  "commonSlice/fetchSurveyQuestions",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetOptionsForUsageQuestions())
        .then((data) => {
          return data;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const submitSurveyAnswers = createAsyncThunk(
  "commonSlice/submitSurveyAnswers",
  async (answers: SubmitOptionsForUsageQuestions, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new SubmitOptionsForUsageQuestions(answers))
        .then((data) => {
          return data;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const commonSlice = createSlice({
  initialState: initialState,
  name: "commonSlice",
  reducers: {
    setMsalProgress(state, action: PayloadAction<InteractionStatus>) {
      state.msalProgress = action.payload;
    },

    setDownForMaintenanceMessage(state, action: PayloadAction<string>) {
      state.downForMaintenanceMessage = action.payload;
    },

    setTrialApplicationModalOpen(state, action: PayloadAction<boolean>) {
      state.trialApplicationModalOpen = action.payload;
    },

    setRowNestingPartsSheets(state, action: PayloadAction<string>) {
      state.resizeNestingPartsSheets = action.payload;
    },

    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },

    setPatchInProgress(state, action: PayloadAction<boolean>) {
      state.patchInProgress = action.payload;
    },

    setProductActionModalOpen(state, action: PayloadAction<boolean>) {
      state.productActionModalOpen = action.payload;
    },

    setCloneModalOpen(state, action: PayloadAction<boolean>) {
      state.cloneModalOpen = action.payload;
    },

    setUpdatePaymentModalOpen(state, action: PayloadAction<boolean>) {
      state.updatePaymentModalOpen = action.payload;
    },

    setRotatePartModalVisibility(state, action: PayloadAction<RotateModal>) {
      state.rotatePartModalVisibility = action.payload;
    },

    setDeletePartModalOpen(state, action: PayloadAction<boolean>) {
      state.deletePartModalOpen = action.payload;
    },

    setSaveAsModalOpen(state, action: PayloadAction<boolean>) {
      state.saveAsModalOpen = action.payload;
    },

    setPartSearchCollapse(state, action: PayloadAction<boolean>) {
      state.partSearchCollapse = action.payload;
    },

    setMainNavCollapse(state, action: PayloadAction<boolean>) {
      state.mainNavCollapse = action.payload;
    },

    setNavigationWarning(
      state,
      action: PayloadAction<Partial<NavigationWarning>>
    ) {
      state.navigationWarning = {
        ...state.navigationWarning,
        ...action.payload,
      };
    },

    setNoInternetConnection(state, action: PayloadAction<boolean>) {
      state.noInternetConnection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // =======================================================================
      // GET: Survey Questions
      .addCase(fetchSurveyQuestions.pending, (state) => {
        state.surveyQuestionLoading = "pending";
      })
      .addCase(fetchSurveyQuestions.fulfilled, (state, action) => {
        state.surveyQuestionOptions =
          action.payload as OptionsForUsageQuestions;
        state.surveyQuestionLoading = "succeeded";
      })
      .addCase(fetchSurveyQuestions.rejected, (state) => {
        state.surveyQuestionLoading = "failed";
      })

      // =======================================================================
      // POST: Submit Survey Answers
      .addCase(submitSurveyAnswers.pending, (state) => {
        state.surveyAnswersLoading = "pending";
      })
      .addCase(submitSurveyAnswers.fulfilled, (state, action) => {
        state.surveyAnswersCompleted =
          action.payload as OptionSubmissionComplete;
        state.surveyAnswersLoading = "succeeded";
      })
      .addCase(submitSurveyAnswers.rejected, (state) => {
        state.surveyAnswersLoading = "failed";
      })

      // =======================================================================
      // POST: Request CC Free Trial
      .addCase(requestCCFreeTrial.pending, (state) => {
        state.requestCCFreeTrialLoading = "pending";
      })
      .addCase(requestCCFreeTrial.fulfilled, (state) => {
        state.requestCCFreeTrialLoading = "succeeded";
      })
      .addCase(requestCCFreeTrial.rejected, (state) => {
        state.requestCCFreeTrialLoading = "failed";
      });
  },
});

export const {
  setCloneModalOpen,
  setDeletePartModalOpen,
  setDownForMaintenanceMessage,
  setMainNavCollapse,
  setMsalProgress,
  setNavigationWarning,
  setNoInternetConnection,
  setPartSearchCollapse,
  setPatchInProgress,
  setProductActionModalOpen,
  setRotatePartModalVisibility,
  setRowNestingPartsSheets,
  setSaveAsModalOpen,
  setTrialApplicationModalOpen,
  setUpdatePaymentModalOpen,
  setUserInfo,
} = commonSlice.actions;

export default commonSlice.reducer;
