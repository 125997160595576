import {
  FunctionComponent,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  getNestName,
  getNestingSessionDirty,
} from "../features/nesting/nesting";
import { useAppDispatch, useAppSelector } from "./hooks/reduxTypedHooks";

import AppFrame from "../features/appFrame/AppFrame";
import ChooseNestingFolderDynamicModal from "../features/nesting/ChooseNestingFolderDynamicModal";
import DownForMaintenance from "../features/account/DownForMaintenance";
import ErrorBoundary from "./errorHandling/ErrorBoundary";
import ExampleProjectDynamicModal from "../features/nests/ExampleProjectDynamicModal";
import { InteractionType } from "@azure/msal-browser";
import { LandingScreenContainer } from "../features/landing/LandingScreenContainer";
import Main from "../features/common/Main";
import NestProjectImportScreen from "../features/nesting/NestingProjectImportScreen";
import { ProductFruits } from "react-product-fruits";
import ProgressBar from "../features/libraryPartList/progressBar/ProgressBar";
import RemoveRevokeMemberDynamicModal from "../features/teams/RemoveRevokeMemberDynamicModal";
import TermsScreen from "../features/landing/TermsScreen";
import appConfig from "./config.json";
import { getDarkMode } from "../features/settings/settings";
import { getEfficientRouting } from "../features/efficientRouting/efficientRouting";
import { getImportSessionDirty } from "../features/importer/importer";
import { getNoInternetConnection } from "../features/common/common";
import { getUser } from "../features/payment/payment";
import { initialApiCalls } from "./store";
import { lazyWithPreload } from "../Util";
import { lngsList } from "../language/i18next";
import rg4js from "raygun4js";
import { setUserInfo } from "../features/common/commonSlice";
import usePaddle from "./hooks/usePaddle";
import { useTranslation } from "react-i18next";

const ParametricDynamicModal = lazy(
  () => import("../features/libraryPartList/parametric/ParametricDynamicModal")
);

const DeleteNoteDynamicModal = lazy(
  () => import("../features/notes/DeleteNoteDynamicModal")
);

const ToastMessagesScreen = lazy(
  () => import("../features/toastMessages/ToastMessagesScreen")
);

const NavigationWarningDynamicModal = lazy(
  () => import("../features/common/NavigationWarningDynamicModal")
);

const ApiErrorDynamicModal = lazy(
  () => import("../features/apiError/ApiErrorDynamicModal")
);

const InviteDynamicModal = lazy(
  () => import("../features/teams/InviteDynamicModal")
);

const WizardScreen = lazy(() => import("../features/settings/WizardScreen"));

const SidebarNav = lazyWithPreload(
  () => import("../features/common/SidebarNav")
);
const AccountIsLockedAScreen = lazyWithPreload(
  () => import("../features/account/AccountIsLockedScreen")
);
const NestingScreen = lazyWithPreload(
  () => import("../features/nesting/NestingScreen")
);
const NestsScreen = lazyWithPreload(
  () => import("../features/nests/NestsScreen")
);
const LibraryPartListScreen = lazyWithPreload(
  () => import("../features/libraryPartList/LibraryPartListScreen")
);
const FeedbackDynamicModal = lazyWithPreload(
  () => import("../features/feedback/FeedbackDynamicModal")
);
const SettingsScreen = lazyWithPreload(
  () => import("../features/settings/SettingsScreen")
);
const ImporterScreen = lazyWithPreload(
  () => import("../features/importer/ImporterScreen")
);
const ProductsScreen = lazyWithPreload(
  () => import("../features/products/ProductsScreen")
);
const AccountScreen = lazyWithPreload(
  () => import("../features/account/accountScreen/AccountScreen")
);
const SupportUploadScreen = lazyWithPreload(
  () => import("../features/nesting/NestingProjectImportScreen")
);
const RotatePartDynamicModal = lazyWithPreload(
  () => import("../features/libraryPartList/RotatePartDynamicModal")
);
const ImportCSVDynamicModal = lazyWithPreload(
  () => import("../features/importCSV/ImportCSVDynamicModal")
);
const ExampleProjectScreen = lazyWithPreload(
  () => import("../features/nests/ExampleProjectScreen")
);
const ProductActionDynamicModal = lazyWithPreload(
  () => import("../features/products/productAction/ProductActionDynamicModal")
);
const DeleteNestFolderDynamicModal = lazyWithPreload(
  () => import("../features/nests/DeleteNestFolderDynamicModal")
);
const DeletePartFolderDynamicModal = lazyWithPreload(
  () =>
    import(
      "../features/libraryPartList/partFolders/DeletePartFolderDynamicModal"
    )
);
const MovePartToFolderDynamicModal = lazyWithPreload(
  () =>
    import(
      "../features/libraryPartList/movePartToFolder/MovePartToFolderDynamicModal"
    )
);
const MoveNestToFolderDynamicModal = lazyWithPreload(
  () =>
    import("../features/nests/moveNestToFolder/MoveNestToFolderDynamicModal")
);
const NestDeleteDynamicModal = lazyWithPreload(
  () => import("../features/nests/NestDeleteDynamicModal")
);
const SettingsDynamicModal = lazyWithPreload(
  () => import("../features/settings/SettingsDynamicModal")
);
const SaveAsDynamicModal = lazyWithPreload(
  () => import("../features/nesting/SaveAsDynamicModal")
);
const ClonePartDynamicModal = lazyWithPreload(
  () => import("../features/libraryPartList/controls/ClonePartDynamicModal")
);
const DeletePartDynamicModal = lazyWithPreload(
  () => import("../features/libraryPartList/DeletePartDynamicModal")
);
const UpdatePaymentDynamicModal = lazyWithPreload(
  () => import("../features/account/paymentMethod/UpdatePaymentDynamicModal")
);
const PartsLibraryDynamicModal = lazyWithPreload(
  () => import("../features/libraryPartList/PartsLibraryDynamicModal")
);

const DuplicatePartNameDynamicModal = lazyWithPreload(
  () => import("../features/importer/DuplicatePartNameDynamicModal")
);
const ImporterTimerDynamicModal = lazyWithPreload(
  () => import("../features/importer/ImporterTimerDynamicModal")
);
const ImporterPartDynamicModal = lazyWithPreload(
  () => import("../features/importer/ImporterPartDynamicModal")
);

const TeamsManagementScreen = lazyWithPreload(
  () => import("../features/teams/TeamsManagementScreen")
);

const App: FunctionComponent<{}> = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const nestingSessionDirty = useAppSelector(getNestingSessionDirty);
  const importSessionDirty = useAppSelector(getImportSessionDirty);

  const efficientRoutingState = useAppSelector(getEfficientRouting);
  const noInternetConnection = useAppSelector(getNoInternetConnection);

  const user = useAppSelector(getUser);
  const Paddle = window.Paddle;
  const { PaddleSetup } = usePaddle(Paddle);
  const { accounts, inProgress } = useMsal();

  const account = useAccount(accounts[0] || {});
  // ============================== Paddle ==============================
  // Seems to initialise paddle
  process.env.REACT_APP_BUILD_TYPE !== "production" &&
    Paddle.Environment.set("sandbox");

  Paddle.Setup({
    vendor: Number(process.env.REACT_APP_PADDLE_ID),
    eventCallback: PaddleSetup,
  });

  console.log(" window?.productFruits", window?.productFruits);
  console.log("account", account);

  // ============================ End Paddle ============================

  // =============================== MSAL ===============================
  //
  const isInitialised = useRef(false);

  useEffect(() => {
    if (isInitialised.current) return;

    if (inProgress === "none" && account) {
      console.log(
        "%c initialApiCalls  start ",
        "color: white; background: #613179; "
      );

      (async () => {
        // RayGun Set User

        rg4js("setUser", {
          email: account?.idTokenClaims?.emails
            ? account?.idTokenClaims?.emails[0]
            : "",
          firstName: account.idTokenClaims?.given_name as string,
          fullName: `${account.idTokenClaims?.given_name ?? ""} ${
            account.idTokenClaims?.family_name ?? ""
          }`,
          identifier: account.idTokenClaims?.sub as string,
          isAnonymous: false,
        });
        await initialApiCalls();

        isInitialised.current = true;

        dispatch(
          setUserInfo({
            email: account?.idTokenClaims?.emails
              ? account?.idTokenClaims?.emails[0]
              : "",
            firstName: account.idTokenClaims?.given_name as string,
            lastName: account.idTokenClaims?.family_name as string,
            fullName: `${account.idTokenClaims?.given_name ?? ""} ${
              account.idTokenClaims?.family_name ?? ""
            }`,
            id: account.idTokenClaims?.sub as string,
          })
        );
      })();
    }
  }, [account, dispatch, inProgress]);

  // ============================= End MSAL =============================

  function docTitle(title: string) {
    document.title = `${title ? title + " - " : ""}${appConfig.AppName}`;
  }

  const handleLeave = useCallback(
    (event) => {
      const url = location.pathname + location.hash;
      const nestingUrlMatch = location.pathname.match(/nesting/);
      const inNesting =
        nestingUrlMatch !== null && nestingUrlMatch.includes("nesting");
      const inImporter = /(([/a-z]*)([#]|[/]))*import/.test(url);

      if (
        (inImporter && importSessionDirty) ||
        (inNesting && nestingSessionDirty)
      ) {
        event.preventDefault();
        event.returnValue = "";
        return false;
      }
    },
    [location, nestingSessionDirty, importSessionDirty]
  );

  const themeType = useAppSelector(getDarkMode)
    ? "theme-dark-mode"
    : "theme-light-mode";

  const darkMode = useAppSelector(getDarkMode);

  const activeNestName = useAppSelector(getNestName);

  useEffect(() => {
    let title: string = "";

    const pathName = new URL(window.location.href).pathname.split("/")[1];

    switch (pathName) {
      case "/set-up":
        title = "Set Up";
        break;
      case t("paths.nesting"):
        title = `${activeNestName || t("titles.nestingPage")}`;
        break;
      case t("paths.nests"):
        title = t("titles.nests");
        break;
      case t("paths.account"):
        title = t("titles.account");
        break;
      case t("paths.library"):
        title = t("titles.library");
        break;
      case t("paths.library") + t("paths.importer"):
        title = t("titles.importer");
        break;
      case t("paths.settings"):
        title = t("titles.settings");
        break;
      case t("paths.downForMaintenance"):
        title = t("titles.downForMaintenance");
        break;
      case t("paths.accountIsLocked"):
        title = t("titles.accountIsLocked");
        break;

      default:
        break;
    }

    docTitle(title);
  }, [location, activeNestName, t]);

  useEffect(() => {
    switch (darkMode) {
      case true:
        document.body.classList.add("theme-dark-mode");
        document.documentElement.setAttribute("data-bs-theme", "dark");
        break;
      case false:
      default:
        document.body.classList.remove("theme-dark-mode");
        document.documentElement.setAttribute("data-bs-theme", "light");
        break;
    }
  }, [darkMode]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleLeave);

    return () => {
      window.removeEventListener("beforeunload", handleLeave);
    };
  }, [handleLeave]);

  useEffect(() => {
    // Remove the language cookie if the build type is production
    if (process.env.REACT_APP_BUILD_TYPE === "production") {
      localStorage.removeItem("lng");
    }

    i18n.changeLanguage(i18n.resolvedLanguage);
    if (process.env.REACT_APP_BUILD_TYPE !== "production") {
      const Lngs = lngsList.map((lng) => lng.value);
      // Check if the language in the URL is the same as the resolved language and change it if not
      const lng = new URL(window.location.href).pathname.split("/")[1];

      if (Lngs.includes(lng) && i18n.resolvedLanguage !== lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
      }

      // First time visit - use browser language
      // if no cookie set the language to the browser language
      if (
        Lngs.includes(window.navigator.language) &&
        !localStorage.getItem("lng")
      ) {
        i18n.changeLanguage(window.navigator.language.split("-")[0]);
        localStorage.setItem("lng", window.navigator.language.split("-")[0]);
      }
    }
  }, [i18n]);

  return (
    <>
      <UnauthenticatedTemplate>
        {/* // UnauthenticatedTemplate */}
      </UnauthenticatedTemplate>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductFruits
          workspaceCode="krygxFhgqmBKDuTn"
          language="en"
          user={{
            username: account?.username ?? "",
            firstname: account?.idTokenClaims?.given_name as string,
            lastname: account?.idTokenClaims?.family_name as string,
            email: account?.idTokenClaims?.emails
              ? account?.idTokenClaims?.emails[0]
              : "",
          }}
        />

        <ProgressBar />
        <div
          data-theme={themeType}
          className={`position-relative container-fluid h-100 d-flex flex-column ${themeType}`}
          data-build={process.env.REACT_APP_BUILD_TYPE}
        >
          {/* {process.env.REACT_APP_BUILD_TYPE === "development" && (
        <EfficientRoutingTesting />
      )} */}

          <AppFrame>
            {/* Catch all loading page before loading in MainRoutes */}
            <Suspense
              fallback={
                <LandingScreenContainer>
                  <h6 className="opacity-75 mb-0 text-capitalize">
                    {t("loading")}
                    <div className="theme-loading-container">
                      <span className="theme-loading"></span>
                    </div>
                  </h6>
                </LandingScreenContainer>
              }
            >
              <ToastMessagesScreen />
              {(() => {
                if (efficientRoutingState.loading) {
                  return (
                    <LandingScreenContainer>
                      {noInternetConnection ? (
                        <p className="text-start">
                          Try:
                          <ul>
                            <li>
                              Checking the network cables, modem and router
                            </li>
                            <li>Reconnecting to Wi-Fi</li>
                          </ul>
                          <button
                            className="btn btn-secondary"
                            onClick={() => window.location.reload()}
                          >
                            Refresh
                          </button>
                        </p>
                      ) : (
                        <h6 className="opacity-75 mb-0 text-capitalize">
                          {t("loading")}
                          <div className="theme-loading-container">
                            <span className="theme-loading"></span>
                          </div>
                        </h6>
                      )}
                    </LandingScreenContainer>
                  );
                } else if (efficientRoutingState.termsScreen) {
                  return (
                    <Suspense fallback={<></>}>
                      <Routes>
                        <Route
                          path={"*"}
                          element={
                            <Navigate
                              to={
                                i18n.resolvedLanguage + "/" + t("paths.terms")!
                              }
                            />
                          }
                        ></Route>

                        <Route path={i18n.resolvedLanguage}>
                          <Route
                            path={t("paths.terms")!}
                            element={<TermsScreen />}
                          />
                        </Route>
                      </Routes>
                    </Suspense>
                  );
                } else if (efficientRoutingState.productScreen) {
                  return (
                    <Suspense fallback={<></>}>
                      <Routes>
                        <Route
                          path={"*"}
                          element={
                            <Navigate
                              to={
                                i18n.resolvedLanguage +
                                "/" +
                                t("paths.products")!
                              }
                            />
                          }
                        ></Route>

                        <Route path={i18n.resolvedLanguage}>
                          <Route
                            path={t("paths.products")!}
                            element={<ProductsScreen />}
                          />
                        </Route>
                      </Routes>
                    </Suspense>
                  );
                } else if (efficientRoutingState.wizardScreen) {
                  return (
                    <Suspense fallback={<></>}>
                      <Routes>
                        <Route
                          path={"*"}
                          element={
                            <Navigate
                              to={
                                i18n.resolvedLanguage + "/" + t("paths.setup")!
                              }
                            />
                          }
                        ></Route>

                        <Route path={i18n.resolvedLanguage}>
                          <Route
                            path={t("paths.setup")!}
                            element={<WizardScreen />}
                          />
                        </Route>
                      </Routes>
                    </Suspense>
                  );
                } else if (efficientRoutingState.accountScreen) {
                  // Account screen - solo
                  return (
                    <Main>
                      <Suspense fallback={<></>}>
                        <Routes>
                          <Route
                            path={"*"}
                            element={
                              <Navigate
                                to={
                                  i18n.resolvedLanguage +
                                  "/" +
                                  t("paths.account")!
                                }
                              />
                            }
                          ></Route>

                          <Route path={i18n.resolvedLanguage}>
                            <Route
                              path={t("paths.account")!}
                              element={<AccountScreen />}
                            />
                          </Route>
                        </Routes>
                      </Suspense>
                    </Main>
                  );
                } else if (efficientRoutingState.accountLocked) {
                  // Account is Locked screen - solo
                  return (
                    <Main>
                      <Suspense fallback={<></>}>
                        <Routes>
                          <Route
                            path={"*"}
                            element={
                              <>
                                <Navigate
                                  to={
                                    "/" +
                                    i18n.resolvedLanguage +
                                    "/" +
                                    t("paths.accountIsLocked")!
                                  }
                                />
                              </>
                            }
                          ></Route>
                          <Route path={i18n.resolvedLanguage}>
                            <Route
                              path={t("paths.accountIsLocked")!}
                              element={<AccountIsLockedAScreen />}
                            />
                          </Route>
                        </Routes>
                      </Suspense>
                    </Main>
                  );
                } else if (efficientRoutingState.downForMaintenance) {
                  // Down for maintenance screen - solo
                  return (
                    <Main>
                      <Suspense fallback={<></>}>
                        <Routes>
                          <Route
                            path={"*"}
                            element={
                              <Navigate
                                to={
                                  i18n.resolvedLanguage +
                                  "/" +
                                  t("paths.downForMaintenance")!
                                }
                              />
                            }
                          ></Route>

                          <Route path={i18n.resolvedLanguage}>
                            <Route
                              path={t("paths.downForMaintenance")!}
                              element={<DownForMaintenance />}
                            />
                          </Route>
                        </Routes>
                      </Suspense>
                    </Main>
                  );
                } else if (efficientRoutingState.mainAccessScreens) {
                  SidebarNav.preload();
                  NestingScreen.preload();
                  NestsScreen.preload();
                  SettingsScreen.preload();
                  ImporterScreen.preload();
                  FeedbackDynamicModal.preload();
                  ProductsScreen.preload();
                  AccountScreen.preload();
                  SupportUploadScreen.preload();
                  user.teamPlan.partsLibraryEnabled &&
                    LibraryPartListScreen.preload();
                  user.teamPlan.teamsEnabled && TeamsManagementScreen.preload();
                  //For Playwright testing purposes only
                  process.env.REACT_APP_BUILD_TYPE === "development" &&
                    ExampleProjectScreen.preload();

                  return (
                    <>
                      {/* ================== Side Nav ================== */}
                      <SidebarNav />
                      {/* ==================== Page ==================== */}
                      <Main>
                        {/*
                         ** Suspense wrapped around the components stops page flash on initial renders
                         */}
                        <Suspense fallback={<></>}>
                          <Routes>
                            <Route
                              path={"*"}
                              element={
                                <Navigate
                                  to={
                                    i18n.resolvedLanguage +
                                    "/" +
                                    t("paths.nesting")!
                                  }
                                />
                              }
                            />
                            <Route path={i18n.resolvedLanguage}>
                              <Route
                                path={"*"}
                                element={<Navigate to={t("paths.nesting")!} />}
                              />
                              <Route
                                path={t("paths.nesting")!}
                                element={<NestingScreen />}
                              />

                              <Route
                                path={t("paths.nests")!}
                                element={<NestsScreen />}
                              />
                              {user.teamPlan.partsLibraryEnabled && (
                                <Route
                                  path={t("paths.library")!}
                                  element={<LibraryPartListScreen />}
                                />
                              )}

                              <Route
                                path={t("paths.library")!}
                                element={<ImporterScreen />}
                              >
                                <Route
                                  path={t("paths.importer")!}
                                  element={<ImporterScreen />}
                                />
                              </Route>

                              <Route
                                path={t("paths.nesting")!}
                                element={<ImporterScreen />}
                              >
                                <Route
                                  path={t("paths.importer")!}
                                  element={<ImporterScreen />}
                                />
                              </Route>

                              <Route
                                path={t("paths.account")!}
                                element={<AccountScreen />}
                              />

                              <Route
                                path={t("paths.nestingProject")!}
                                element={<NestProjectImportScreen />}
                              />

                              <Route
                                path={t("paths.settings")!}
                                element={<SettingsScreen />}
                              />
                              {user.teamPlan.teamsEnabled && (
                                <Route
                                  path={t("paths.teams")!}
                                  element={<TeamsManagementScreen />}
                                />
                              )}

                              {/* For Playwright testing purposes only */}
                              {process.env.REACT_APP_BUILD_TYPE ===
                                "development" && (
                                <Route
                                  path={t("paths.nestingProject")!}
                                  element={<ExampleProjectScreen />}
                                />
                              )}
                            </Route>
                          </Routes>
                          {/*  Modals */}
                          <ErrorBoundary>
                            <ChooseNestingFolderDynamicModal />
                            <ClonePartDynamicModal />
                            <DeleteNestFolderDynamicModal />
                            <DeletePartDynamicModal />
                            <DeletePartFolderDynamicModal />
                            <DuplicatePartNameDynamicModal />
                            <FeedbackDynamicModal />
                            <ImportCSVDynamicModal />
                            <MoveNestToFolderDynamicModal />
                            <MovePartToFolderDynamicModal />
                            <NestDeleteDynamicModal />
                            <PartsLibraryDynamicModal />
                            <RotatePartDynamicModal />
                            <SaveAsDynamicModal />
                            <SettingsDynamicModal />
                            <ImporterPartDynamicModal />
                            <RemoveRevokeMemberDynamicModal />
                            <DeleteNoteDynamicModal />
                            <ParametricDynamicModal />
                          </ErrorBoundary>
                        </Suspense>
                      </Main>
                    </>
                  );
                }
              })()}
              {/* Global Modals */}
              <InviteDynamicModal />
              <UpdatePaymentDynamicModal />
              <ProductActionDynamicModal />
              <ApiErrorDynamicModal />
              <ExampleProjectDynamicModal />
              <ImporterTimerDynamicModal />
              <NavigationWarningDynamicModal />
            </Suspense>
          </AppFrame>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default App;
