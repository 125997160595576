import {
  AddFolderRequest,
  ClonePartRequest,
  DeleteFolderRequest,
  DeleteParts,
  DeletedPartResult,
  FolderHierarchy,
  FolderSearchType,
  GetImagesForPart,
  GetPartsFolderHierarchy,
  LibraryPart,
  MirrorPartRequest,
  MoveFolderRequest,
  PartImages,
  PartSearchResults,
  RenameFolderRequest,
  RotatePartRequest,
  SearchPart,
  SearchedLibraryPart,
  SourceFileRequest,
  SourceFilesRequest,
  UpdatePartBasics,
} from "../../serviceClient/api.dtos";
import { AppThunk, RootState } from "../../app/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  findFolderTreeById,
  flattenFolders,
  getParentsById,
  getTreeIds,
  guid,
} from "../../Util";

import { ImportedPart } from "./PartsLibrary.dtos";
import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./../common/commonTypes";
import { NestablePart } from "./../nesting/nestingSlice";
import { SortDescriptor } from "../nests/nestsSlice";
import cookie from "react-cookies";

export type ClonedPart = ImportedPart & {
  partId: string;
};
export type StaticFolder = "library" | "non-foldered" | "";

export type LibraryView = "list" | "grid";

type LibraryPartListState = {
  currentPage: number;
  deleteFolderLoading: Loading;
  deleteFolderModalVisibility: boolean;
  deletedPartsErrorMessage: string;
  deletedPartsLoading: Loading;
  deletedPartsResult: DeletedPartResult;
  fetchedPartCount: number;
  idempotencyToken: string;
  imagesForPartErrorMessage: string;
  imagesForPartLoading: Loading;
  imagesForPartResponse: PartImages;
  itemsPerPage: number;
  libraryParts: NestablePart[];
  libraryScreenModalOpen: boolean;
  libraryView: LibraryView;
  mirrorPartErrorMessage: string;
  mirrorPartLoading: Loading;
  mirrorPartResponse: LibraryPart;
  movePartsToFolderLoading: Loading;
  movePartsToFolderModalVisibility: boolean;
  partFolders: FolderHierarchy[];
  partFoldersFlattened: FolderHierarchy[];
  partFoldersLastPulled: string;
  partFoldersLoading: Loading;
  partsErrorMessage: string;
  partsListBusy: boolean;
  partsLoading: Loading;
  postClonePartErrorMessage: string;
  postClonePartLoading: Loading;
  postClonePartResponse: SearchedLibraryPart[];
  progressBarVisibility: boolean;
  rotatePartErrorMessage: string;
  rotatePartLoading: Loading;
  rotatePartResponse: LibraryPart;
  selectedPartFolder: FolderHierarchy | undefined;
  selectedPartFolderId: string;
  selectedPartFolderMoveId: string;
  selectedPartFolderParentTreeIds: string[];
  selectedPartFolderTreeIds: string[];
  selectedStaticFolder: StaticFolder;
  sort: SortDescriptor;
  sourceFileRequestErrorMessage: string;
  sourceFileRequestLoading: Loading;
  sourceFilesRequestErrorMessage: string;
  sourceFilesRequestLoading: Loading;
  totalParts: number;
  updatePartErrorMessage: string;
  updatePartLoading: Loading;
  updatePartResponse: LibraryPart;
};

export const librarySortDescriptors: SortDescriptor[] = [
  {
    order: "asc",
    property: "name",
    title: "Name",
  },
  {
    order: "asc",
    property: "createdDate",
    title: "Created date",
  },
];

const initialState: LibraryPartListState = {
  currentPage: 1,
  deleteFolderLoading: "idle",
  deleteFolderModalVisibility: false,
  deletedPartsErrorMessage: "",
  deletedPartsLoading: "idle",
  deletedPartsResult: new DeletedPartResult(),
  fetchedPartCount: 0,
  idempotencyToken: "",
  imagesForPartErrorMessage: "",
  imagesForPartLoading: "idle",
  imagesForPartResponse: new PartImages(),
  itemsPerPage: cookie.load("PARTS-PER-PAGE") ?? 8,
  libraryParts: [],
  libraryScreenModalOpen: false,
  libraryView: "grid",
  mirrorPartErrorMessage: "",
  mirrorPartLoading: "idle",
  mirrorPartResponse: new LibraryPart(),
  movePartsToFolderLoading: "idle",
  movePartsToFolderModalVisibility: false,
  partFolders: [] as FolderHierarchy[],
  partFoldersFlattened: [] as FolderHierarchy[],
  partFoldersLastPulled: "",
  partFoldersLoading: "idle",
  partsErrorMessage: "",
  partsListBusy: false,
  partsLoading: "idle",
  postClonePartErrorMessage: "",
  postClonePartLoading: "idle",
  postClonePartResponse: [],
  progressBarVisibility: false,
  rotatePartErrorMessage: "",
  rotatePartLoading: "idle",
  rotatePartResponse: new LibraryPart(),
  selectedPartFolder: new FolderHierarchy(),
  selectedPartFolderId: "",
  selectedPartFolderMoveId: "",
  selectedPartFolderParentTreeIds: [],
  selectedPartFolderTreeIds: [],
  selectedStaticFolder: "library",
  sort: cookie.load("PARTS-SORT") ?? librarySortDescriptors[0],
  sourceFileRequestErrorMessage: "",
  sourceFileRequestLoading: "idle",
  sourceFilesRequestErrorMessage: "",
  sourceFilesRequestLoading: "idle",
  totalParts: 0,
  updatePartErrorMessage: "",
  updatePartLoading: "idle",
  updatePartResponse: new LibraryPart(),
};

export type FetchPartsParam = {
  currentPage: number;
  itemsPerPage: number;
};

export const fetchPartFolders = createAsyncThunk(
  "libraryPartListSlice/fetchPartFolders",

  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(new GetPartsFolderHierarchy())
          .then((folders) => {
            thunkAPI.dispatch(setPartFoldersLastPulled(new Date().toString()));

            // Deep clone folders to avoid mutating state
            thunkAPI.dispatch(
              setPartFoldersFlattened(JSON.parse(JSON.stringify(folders)))
            );

            return folders;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const createPartsFolder = createAsyncThunk(
  "libraryPartListSlice/createPartsFolder",

  async (
    { folderName, parentFolderId }: Partial<AddFolderRequest>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(
            new AddFolderRequest({
              folderName,
              parentFolderId,
            })
          )
          .then((folders) => {
            return folders;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const deletePartsFolder = createAsyncThunk(
  "libraryPartListSlice/deletePartsFolder",

  async (
    { id, deletePartsInFolder }: Partial<DeleteFolderRequest>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .delete(new DeleteFolderRequest({ id, deletePartsInFolder }))
          .then((folders) => {
            return folders;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const movePartsToFolder = createAsyncThunk(
  "libraryPartListSlice/movePartsToFolder",

  async ({ moveToFolderId, partIds }: Partial<MoveFolderRequest>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(
            new MoveFolderRequest({
              moveToFolderId,
              partIds,
            })
          )
          .then((folders) => {
            return folders;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const renamePartsFolder = createAsyncThunk(
  "libraryPartListSlice/renamePartsFolder",

  async ({ id, folderName }: Partial<RenameFolderRequest>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(
            new RenameFolderRequest({
              id,
              folderName,
            })
          )
          .then((folder) => {
            return folder;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchParts = createAsyncThunk(
  "libraryPartListSlice/fetchParts",
  async (
    {
      currentPage,
      itemsPerPage,
      folderId,
      searchType,
    }: Partial<FetchPartsParam & SearchPart> = {},
    thunkAPI
  ) => {
    const tokenId = guid();
    thunkAPI.dispatch(setIdempotencyToken(tokenId));
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        const {
          sort,
          currentPage: currentPageFromState,
          itemsPerPage: itemsPerPageFromState,
        } = (thunkAPI.getState() as RootState).libraryPartList;

        const { searchTerms } = (thunkAPI.getState() as RootState)
          .libraryPartSearch;

        const currPage = currentPage ?? currentPageFromState;
        const partsPerPage = itemsPerPage ?? itemsPerPageFromState;

        const skip = (currPage - 1) * partsPerPage;
        const take = partsPerPage;

        return await client
          .post(
            new SearchPart({
              searchType,
              folderId,
              searchParams: searchTerms,
              direction: sort.order,
              skip,
              take,
              orderBy: sort.property,
              idempotencyToken: tokenId,
            })
          )
          .then((PartSearchResults) => {
            thunkAPI.dispatch(setTotalParts(PartSearchResults.total));
            thunkAPI.dispatch(setCurrentPage(currPage));

            const { idempotencyToken } = (thunkAPI.getState() as RootState)
              .libraryPartList;

            if (PartSearchResults.idempotencyToken !== idempotencyToken) {
              return thunkAPI.abort("Aborted stale request");
            }

            // Set Id
            const partsModified = PartSearchResults.parts.map((part) => {
              return {
                ...part,
                id: part.libraryId ?? part.nonLibraryPartId ?? "",
              };
            });
            PartSearchResults.parts = partsModified ?? [];
            return PartSearchResults;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const postClonePart = createAsyncThunk(
  "libraryPartListSlice/clonePartRequest",

  async (newClonePartRequest: ClonePartRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(newClonePartRequest)
          .then((clonedPart) => {
            return clonedPart;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const deletePart = createAsyncThunk(
  "libraryPartListSlice/deletePart",

  async (newDeletePartsRequest: DeleteParts, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .delete(newDeletePartsRequest)
          .then((deletedPartsResult) => {
            return deletedPartsResult;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// Single File
export const sourceFileRequest = createAsyncThunk(
  "libraryPartListSlice/sourceFileRequest",

  async (newSourceFileRequest: SourceFileRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        let contentDispositionHeader: string | null;
        client.responseFilter = function (r) {
          contentDispositionHeader = r.headers.get("Content-Disposition");
        };
        return await client
          .get(newSourceFileRequest)
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = contentDispositionHeader!
              .toString()
              .match(/filename=(.+);/)!
              .slice(-1)[0];
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

//Multiple Files (zip)
export const sourceFilesRequest = createAsyncThunk(
  "libraryPartListSlice/sourceFilesRequest",
  async (newSourceFilesRequest: SourceFilesRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(newSourceFilesRequest)
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);

            window.open(url, "_blank");
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const rotatePartRequest = createAsyncThunk(
  "libraryPartListSlice/rotatePartRequest",

  async (newRotatePartRequest: RotatePartRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(newRotatePartRequest)
          .then((rotatePartResponse) => {
            return rotatePartResponse;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const mirrorPartRequest = createAsyncThunk(
  "libraryPartListSlice/mirrorPartRequest",

  async (newMirrorPartRequest: MirrorPartRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(newMirrorPartRequest)
          .then((mirrorPartResponse) => {
            return mirrorPartResponse;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchImagesForPart = createAsyncThunk(
  "libraryPartListSlice/fetchImagesForPart",

  async (newImagesForPartRequest: GetImagesForPart, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(newImagesForPartRequest)
          .then((imagesForPartResponse) => {
            return imagesForPartResponse;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const updatePart = createAsyncThunk(
  "libraryPartListSlice/updatePart",
  async (newUpdatePart: UpdatePartBasics, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(newUpdatePart)
          .then((updateDatePartResponse) => {
            return updateDatePartResponse;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const libraryPartListSlice = createSlice({
  initialState: initialState,
  name: "libraryPartListSlice",
  reducers: {
    setProgressBarVisibility(state, action: PayloadAction<boolean>) {
      state.progressBarVisibility = action.payload;
    },

    setFetchedPartCount(state, action: PayloadAction<number>) {
      state.fetchedPartCount = action.payload;
    },

    setIdempotencyToken(state, action: PayloadAction<string>) {
      state.idempotencyToken = action.payload;
    },

    setSelectedPartFolderMoveId(state, action: PayloadAction<string>) {
      state.selectedPartFolderMoveId = action.payload;
    },

    setSelectedStaticPartFolder(state, action: PayloadAction<StaticFolder>) {
      state.selectedStaticFolder = action.payload;
    },

    setMovePartsToFolderModalVisibility(state, action: PayloadAction<boolean>) {
      state.movePartsToFolderModalVisibility = action.payload;
    },

    setSelectedPartFolderId(state, action: PayloadAction<string>) {
      state.selectedPartFolderId = action.payload;

      const folder = findFolderTreeById(action.payload, state.partFolders);

      const treeIds = getTreeIds(folder.childFolders, [action.payload]);

      state.selectedPartFolderTreeIds = treeIds;

      //Set Selected Folder Parent Tree Ids
      state.selectedPartFolderParentTreeIds = getParentsById(
        action.payload,
        state.partFolders
      );

      //Set Selected Folder
      state.selectedPartFolder = state.partFoldersFlattened.find(
        (partFolder) => partFolder.id === action.payload
      );
    },

    setSelectedPartFolderParentTreeIds(state, action: PayloadAction<string[]>) {
      state.selectedPartFolderParentTreeIds = action.payload;
    },

    setPartFoldersLastPulled(state, action: PayloadAction<string>) {
      state.partFoldersLastPulled = action.payload;
    },

    setTemporaryPartFolder(state, action: PayloadAction<FolderHierarchy>) {
      // find parentId in state.partFolders and add new folder to it

      function check(
        arr: FolderHierarchy[],
        parentId: string
      ): FolderHierarchy[] {
        // use "current()" to log the current value of the state array
        arr.forEach((arrItem) => {
          //remove nameless folders

          if (arrItem.name === "") {
            arr.splice(arr.indexOf(arrItem), 1);
          }

          if (arrItem.id === parentId) {
            state.selectedPartFolderParentTreeIds.push(parentId);

            arrItem.childFolders?.push(action.payload);
          } else if (Array.isArray(arrItem.childFolders)) {
            check(arrItem.childFolders, parentId);
          }
        });

        return arr;
      }

      if (!action.payload.parentId) {
        state.partFolders = [action.payload, ...state.partFolders];
      } else {
        state.partFolders = [
          ...check(state.partFolders, action.payload.parentId!),
        ];
      }
    },

    setPartFoldersFlattened(state, action: PayloadAction<FolderHierarchy[]>) {
      state.partFoldersFlattened = flattenFolders(action.payload);
    },

    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },

    setItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload;

      cookie.save("PARTS-PER-PAGE", action.payload, {
        secure: false,
        sameSite: "strict",
        path: "/",
      });
    },

    setPartsListBusy(state, action: PayloadAction<boolean>) {
      state.partsListBusy = action.payload;
    },

    setOrUpdateParts(state, action: PayloadAction<Partial<NestablePart>[]>) {
      const extantPartIds = state.libraryParts.map(
        (extantPart) => extantPart.id
      );

      const updatedParts = state.libraryParts.map((extantPart) => ({
        ...extantPart,
        ...action.payload.find((newPart) => newPart.id === extantPart.id),
      }));

      state.libraryParts = [
        ...updatedParts,
        ...action.payload
          .filter(
            (newPart) =>
              typeof newPart.id === "undefined" ||
              !extantPartIds.includes(newPart.id)
          )
          .map(
            (newPart) =>
              ({
                ...newPart,
              } as NestablePart)
          ),
      ];
    },

    setOrUpdatePartsLibraryId(
      state,
      action: PayloadAction<Partial<NestablePart>[]>
    ) {
      const extantPartIds = state.libraryParts.map(
        (extantPart) => extantPart.libraryId
      );

      const updatedParts = state.libraryParts.map((extantPart) => ({
        ...extantPart,
        ...action.payload.find(
          (newPart) => newPart.libraryId === extantPart.libraryId
        ),
      }));

      const parts = [
        ...updatedParts,
        ...action.payload
          .filter(
            (newPart) =>
              typeof newPart.libraryId === "undefined" ||
              !extantPartIds.includes(newPart.libraryId)
          )
          .map(
            (newPart) =>
              ({
                ...newPart,
              } as NestablePart)
          ),
      ];

      state.libraryParts = parts;
    },

    setTotalParts(state, action: PayloadAction<number>) {
      state.totalParts = action.payload;
    },

    setPartsSort(state, action: PayloadAction<SortDescriptor>) {
      state.sort = action.payload;

      cookie.save("PARTS-SORT", action.payload, {
        secure: false,
        sameSite: "strict",
        path: "/",
      });
    },

    deselectAllParts(state) {
      state.libraryParts = state.libraryParts.map((part) => ({
        ...part,
        active: false,
        isSelected: false,
      }));
    },

    setLibraryScreenModalOpen(state, action: PayloadAction<boolean>) {
      state.libraryScreenModalOpen = action.payload;
    },

    setDeleteFolderModalVisibility(state, action: PayloadAction<boolean>) {
      state.deleteFolderModalVisibility = action.payload;
    },

    setLibraryView(state, action: PayloadAction<LibraryView>) {
      state.libraryView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      //GET: Fetch Parts Folders
      .addCase(fetchPartFolders.pending, (state) => {
        state.partFoldersLoading = "pending";
      })
      .addCase(fetchPartFolders.fulfilled, (state, action) => {
        state.partFolders = action.payload as FolderHierarchy[];
        state.partFoldersLoading = "succeeded";
      })
      .addCase(fetchPartFolders.rejected, (state) => {
        state.partFoldersLoading = "failed";
      })

      // =======================================================================
      //POST: Fetch Parts
      .addCase(fetchParts.pending, (state) => {
        state.partsLoading = "pending";
        state.partsErrorMessage = "";
      })
      .addCase(fetchParts.fulfilled, (state, action) => {
        const { parts } = action.payload as PartSearchResults;
        state.libraryParts = parts as NestablePart[] & SearchedLibraryPart[];
        state.partsLoading = "succeeded";
        state.partsErrorMessage = "";
      })
      .addCase(fetchParts.rejected, (state, rejectedAction) => {
        state.partsLoading = "failed";
        state.partsErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //POST: Post Clone Part
      .addCase(postClonePart.pending, (state) => {
        state.postClonePartLoading = "pending";
        state.postClonePartErrorMessage = "";
      })
      .addCase(postClonePart.fulfilled, (state, action) => {
        state.postClonePartResponse.push(action.payload as SearchedLibraryPart);
        state.postClonePartLoading = "succeeded";
        state.postClonePartErrorMessage = "";
      })
      .addCase(postClonePart.rejected, (state, rejectedAction) => {
        state.postClonePartLoading = "failed";
        state.postClonePartErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //DELETE: Delete Part
      .addCase(deletePart.pending, (state) => {
        state.deletedPartsLoading = "pending";
        state.deletedPartsErrorMessage = "";
        state.deletedPartsResult = initialState.deletedPartsResult;
      })
      .addCase(deletePart.fulfilled, (state, action) => {
        state.deletedPartsResult = action.payload as DeletedPartResult;
        state.deletedPartsLoading = "succeeded";
        state.deletedPartsErrorMessage = "";
      })
      .addCase(deletePart.rejected, (state, rejectedAction) => {
        state.deletedPartsLoading = "failed";
        state.deletedPartsErrorMessage = rejectedAction.payload as string;
        state.deletedPartsResult = initialState.deletedPartsResult;
      })

      // =======================================================================
      // GET: Source File Request - Single Part
      .addCase(sourceFileRequest.pending, (state) => {
        state.sourceFileRequestLoading = "pending";
        state.sourceFileRequestErrorMessage = "";
      })
      .addCase(sourceFileRequest.fulfilled, (state) => {
        state.sourceFileRequestLoading = "succeeded";
        state.sourceFileRequestErrorMessage = "";
      })
      .addCase(sourceFileRequest.rejected, (state, rejectedAction) => {
        state.sourceFileRequestLoading = "failed";
        state.sourceFileRequestErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Source Files Request - Zip
      .addCase(sourceFilesRequest.pending, (state) => {
        state.sourceFilesRequestLoading = "pending";
        state.sourceFilesRequestErrorMessage = "";
      })
      .addCase(sourceFilesRequest.fulfilled, (state) => {
        state.sourceFilesRequestLoading = "succeeded";
        state.sourceFilesRequestErrorMessage = "";
      })
      .addCase(sourceFilesRequest.rejected, (state, rejectedAction) => {
        state.sourceFilesRequestLoading = "failed";
        state.sourceFilesRequestErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Rotate Part Request
      .addCase(rotatePartRequest.pending, (state) => {
        state.rotatePartLoading = "pending";
        state.rotatePartResponse = initialState.rotatePartResponse;
        state.rotatePartErrorMessage = "";
      })
      .addCase(rotatePartRequest.fulfilled, (state, action) => {
        state.rotatePartLoading = "succeeded";
        state.rotatePartResponse = action.payload as LibraryPart;
        state.rotatePartErrorMessage = "";
      })
      .addCase(rotatePartRequest.rejected, (state, rejectedAction) => {
        state.rotatePartLoading = "failed";
        state.rotatePartResponse = initialState.rotatePartResponse;
        state.rotatePartErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Mirror Part Request
      .addCase(mirrorPartRequest.pending, (state) => {
        state.mirrorPartLoading = "pending";
        state.mirrorPartResponse = initialState.mirrorPartResponse;
        state.mirrorPartErrorMessage = "";
      })
      .addCase(mirrorPartRequest.fulfilled, (state, action) => {
        state.mirrorPartLoading = "succeeded";
        state.mirrorPartResponse = action.payload as LibraryPart;
        state.mirrorPartErrorMessage = "";
      })
      .addCase(mirrorPartRequest.rejected, (state, rejectedAction) => {
        state.mirrorPartLoading = "failed";
        state.mirrorPartResponse = initialState.mirrorPartResponse;
        state.mirrorPartErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Fetch Images For Part
      .addCase(fetchImagesForPart.pending, (state) => {
        state.imagesForPartLoading = "pending";
        state.imagesForPartResponse = initialState.imagesForPartResponse;
        state.imagesForPartErrorMessage = "";
      })
      .addCase(fetchImagesForPart.fulfilled, (state, action) => {
        state.imagesForPartLoading = "succeeded";
        state.imagesForPartResponse = action.payload as PartImages;
        state.imagesForPartErrorMessage = "";
      })
      .addCase(fetchImagesForPart.rejected, (state, rejectedAction) => {
        state.imagesForPartLoading = "failed";
        state.imagesForPartResponse = initialState.imagesForPartResponse;
        state.imagesForPartErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Fetch Images For Part
      .addCase(updatePart.pending, (state) => {
        state.updatePartLoading = "pending";
        state.updatePartResponse = initialState.updatePartResponse;
        state.updatePartErrorMessage = "";
      })
      .addCase(updatePart.fulfilled, (state, action) => {
        state.updatePartLoading = "succeeded";
        state.updatePartResponse = action.payload as LibraryPart;
        state.updatePartErrorMessage = "";
      })
      .addCase(updatePart.rejected, (state, rejectedAction) => {
        state.updatePartLoading = "failed";
        state.updatePartResponse = initialState.updatePartResponse;
        state.updatePartErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // DELETE: Delete Folder
      .addCase(deletePartsFolder.pending, (state) => {
        state.deleteFolderLoading = "pending";
      })
      .addCase(deletePartsFolder.fulfilled, (state) => {
        state.deleteFolderLoading = "succeeded";
      })
      .addCase(deletePartsFolder.rejected, (state) => {
        state.deleteFolderLoading = "failed";
      })

      // =======================================================================
      // Put: Move Parts To Folder
      .addCase(movePartsToFolder.pending, (state) => {
        state.movePartsToFolderLoading = "pending";
      })
      .addCase(movePartsToFolder.fulfilled, (state) => {
        state.movePartsToFolderLoading = "succeeded";
      })
      .addCase(movePartsToFolder.rejected, (state) => {
        state.movePartsToFolderLoading = "failed";
      });
  },
});

export const updatePartsSort =
  (newSort: SortDescriptor): AppThunk =>
  async (dispatch) => {
    dispatch(setPartsSort(newSort));
    dispatch(updateCurrentPage(1));
  };

export const updateCurrentPage =
  (page: number): AppThunk =>
  async (dispatch, getState) => {
    const { selectedPartFolderId, selectedStaticFolder } =
      getState().libraryPartList;

    dispatch(setCurrentPage(page));

    dispatch(
      fetchParts({
        currentPage: page,
        searchType:
          selectedPartFolderId || selectedStaticFolder === "non-foldered"
            ? FolderSearchType.Subfolders
            : FolderSearchType.Global,
        folderId: selectedPartFolderId,
      })
    );
  };

export const updateItemsPerPage =
  (itemsPerPage: number): AppThunk =>
  async (dispatch, getState) => {
    const libraryState = getState().libraryPartList;
    const { selectedPartFolderId, selectedStaticFolder } =
      getState().libraryPartList;

    if (libraryState.itemsPerPage !== itemsPerPage) {
      dispatch(setCurrentPage(1));
      dispatch(
        fetchParts({
          currentPage: 1,
          itemsPerPage,
          folderId: selectedPartFolderId,
          searchType:
            selectedPartFolderId || selectedStaticFolder === "non-foldered"
              ? FolderSearchType.Subfolders
              : FolderSearchType.Global,
        })
      );
    } else {
      dispatch(
        fetchParts({
          currentPage: libraryState.currentPage,
          itemsPerPage,
          folderId: selectedPartFolderId,
          searchType:
            selectedPartFolderId || selectedStaticFolder === "non-foldered"
              ? FolderSearchType.Subfolders
              : FolderSearchType.Global,
        } as FetchPartsParam)
      );
    }

    dispatch(setItemsPerPage(itemsPerPage));
  };

export const setPartsBusy =
  (parts: NestablePart[], busy: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(setOrUpdateParts(parts.map((part) => ({ ...part, busy }))));
  };

export const {
  deselectAllParts,
  setCurrentPage,
  setDeleteFolderModalVisibility,
  setFetchedPartCount,
  setIdempotencyToken,
  setItemsPerPage,
  setLibraryScreenModalOpen,
  setLibraryView,
  setMovePartsToFolderModalVisibility,
  setOrUpdateParts,
  setOrUpdatePartsLibraryId,
  setPartFoldersFlattened,
  setPartFoldersLastPulled,
  setPartsListBusy,
  setPartsSort,
  setProgressBarVisibility,
  setSelectedPartFolderId,
  setSelectedPartFolderMoveId,
  setSelectedPartFolderParentTreeIds,
  setSelectedStaticPartFolder,
  setTemporaryPartFolder,
  setTotalParts,
} = libraryPartListSlice.actions;

export default libraryPartListSlice.reducer;
