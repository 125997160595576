import { FunctionComponent, memo, useEffect, useState } from "react";
import {
  getFetchedPartCount,
  getProgressBarVisibility,
  getTotalParts,
} from "../libraryPartList";
import {
  setFetchedPartCount,
  setProgressBarVisibility,
} from "../libraryPartListSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/hooks/reduxTypedHooks";

import styles from "./ProgressBar.module.scss";

const ProgressBar: FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();
  const parts = useAppSelector(getFetchedPartCount);
  const totalParts = useAppSelector(getTotalParts);
  const progressBarVisibility = useAppSelector(getProgressBarVisibility);

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent((parts / totalParts) * 100);

    if (parts === totalParts && totalParts !== 0) {
      setTimeout(() => {
        dispatch(setProgressBarVisibility(false));
        dispatch(setFetchedPartCount(0));
      }, 1000);
    }

    return () => {
      setPercent(100);
    };
  }, [parts, totalParts, dispatch]);

  //Note: <progress> element breaks the bootstrap styling - use div

  return (
    <div
      className={`progress ${styles.progressBar} ${
        progressBarVisibility ? "progressTransition" : "d-none"
      }  bg-body `}
    >
      <div
        className="progress-bar"
        style={{ width: percent + "%" }}
      ></div>
    </div>
  );
};

export default memo(ProgressBar);
