import React, { FunctionComponent } from "react";
import { getNoInternetConnection, getPatchInProgress } from "./common";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useAppSelector } from "../../app/hooks/reduxTypedHooks";
import { useTranslation } from "react-i18next";

const WarningBanner: FunctionComponent = () => {
  const patchInProgress = useAppSelector(getPatchInProgress);
  const noInternetConnection = useAppSelector(getNoInternetConnection);

  const { t } = useTranslation();

  const patchTitle = t("warningBanner.patchInProgress");
  const patchText = t("warningBanner.someFunctionalityMayBeAffected");

  const noInternetTitle = t("warningBanner.noInternetConnection");
  const noInternetText = t("warningBanner.pleaseTryAgain");

  return (
    <>
      {patchInProgress && (
        <div className="theme-warning-banner-container row p-1 bg-efficient mb-3 text-white">
          <div className="d-flex justify-content-center flex-wrap align-items-center small">
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth
            />
            &nbsp;
            <strong>{patchTitle}</strong>:&nbsp;<span>{patchText}</span>
          </div>
        </div>
      )}
      {noInternetConnection && (
        <div className="theme-warning-banner-container row p-1 bg-dark mb-3 text-info">
          <div className="d-flex justify-content-center flex-wrap align-items-center small">
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth
            />
            &nbsp;
            <strong>{noInternetTitle}</strong>:&nbsp;
            <span>{noInternetText}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(WarningBanner);
