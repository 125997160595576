import {
  FolderHierarchy,
  FolderSearchType,
} from "../../serviceClient/api.dtos";
import {
  FunctionComponent,
  MutableRefObject,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  createNestFolder,
  fetchNestsFolders,
  fetchSavedNests,
  renameNestFolder,
  setSelectedNestFolderId,
  setSelectedNestFolderMoveId,
} from "../nests/nestsSlice";

import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { guid } from "../../Util";
import styles from "./NestsLibraryFolderEditableText.module.scss";
import { useAppDispatch } from "../../app/hooks/reduxTypedHooks";
import { useTranslation } from "react-i18next";

// Nesting screen folder select or Nests Library move folder

export type EditableType = "Nesting" | "NestLibrary";

const EditableText: FunctionComponent<{
  folder: FolderHierarchy;
  editableType: EditableType;
}> = ({ folder, editableType }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    setText(folder.name);
  }, [folder]);

  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    // Focus the cursor in the input field
    if (isEditing || !text) {
      //Drop down button focuses on click, so we need to wait for the next frame
      window.requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [isEditing, text]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setIsEditing(true);

    setText(value);
  }

  function handleRenameAdd() {
    setIsEditing(false);

    const textTrimmed = text.trim();

    if (folder.id && folder.id !== "temp" && textTrimmed === folder.name) {
      setIsEditing(false);
      return;
    }

    if (folder.id && folder.id !== "temp" && textTrimmed === "") {
      setText(folder.name);
      return;
    }

    if ((!folder.id || folder.id === "temp") && textTrimmed === "") {
      setIsEditing(false);

      // At this point there should be a parent folder
      //  dispatch(setSelectedNestFolderId(folder.parentId ?? ""));
      dispatch(fetchNestsFolders());
      return;
    }

    if (folder.id && folder.id !== "temp") {
      dispatch(
        renameNestFolder({ id: folder.id, folderName: textTrimmed })
      ).then(() => {
        dispatch(fetchNestsFolders())
          .unwrap()
          .then(() => {
            dispatch(setSelectedNestFolderId(folder.id));
          });
      });

      // new Folder
    } else {
      dispatch(
        createNestFolder({
          folderName: textTrimmed,
          parentFolderId: folder.parentId,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(fetchNestsFolders())
            .unwrap()
            .then(() => {
              //Set move id if in modal

              if (editableType === "Nesting") {
                dispatch(setSelectedNestFolderMoveId(res.id));
              } //set selected folder when in the library
              if (editableType === "NestLibrary") {
                dispatch(setSelectedNestFolderId(res.id));

                dispatch(
                  fetchSavedNests({
                    folderId: res.id,
                    searchType: FolderSearchType.Subfolders,
                  })
                )
                  .then(() => {})
                  .catch(() =>
                    dispatch(
                      addToastMessage({
                        id: guid(),
                        severity: "danger",
                        text: t("toastMessages.fetchSavedNestError"),
                      })
                    )
                  );
              }
            });
        })
        .catch(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.fetchSavedNestError"),
            })
          );
        });
    }
  }

  function handleBlur() {
    handleRenameAdd();
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleRenameAdd();
    }
  }

  return (
    <div
      data-folder-name={folder.id}
      onDoubleClick={handleDoubleClick}
      className={`${styles.editableInputContainer} text-truncate d-flex align-items-center flex-grow-1 w-100 small`}
    >
      {isEditing || !text ? (
        <input
          ref={inputRef}
          className={`form-control w-100 py-0 px-1 border-1 border-secondary flex-grow-1 ${styles.editableInput}`}
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        <span
          id={`folderNameId-${folder.id}`}
          className="flex-grow-1 text-truncate"
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default memo(EditableText);
