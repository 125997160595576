import backend from "i18next-http-backend";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//import LanguageDetector from "i18next-browser-language detector";

//Remove the language from localStorage
// process.env.REACT_APP_BUILD_TYPE === "production" &&
//   localStorage.removeItem("i18nextLng");

// if no locale is set in local storage, use the browser language
//  i18n.use(LanguageDetector);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(backend)
  .init({
    // Set  the language from localStorage if it exists otherwise use the ***DISABLED:browser language***
    lng:
      //custom language property in localStorage - language detector sets i18nextLng - not used
      localStorage.getItem("lng") ?? "en", // ***?? navigator.language***
    detection: {
      order: ["localStorage"],
    },
    // ns: ["translation"],
    debug: process.env.REACT_APP_BUILD_TYPE === "development",
    //debug: true,
    // use en if detected lng is not available
    fallbackLng: () => {
      // to test delete the localStorage item set senor language to "ja-JP" and refresh page from route without any paths or this will take precedence (/en/path)
      const fallbacks = {
        // needed for the language detector to work with hyphens to underscore (folder names in public/locales)
        // e.g. "zh-Hans-CN": ["zh_Hans"],

        //it seems the language detector is able to handle the hyphened localise extensions  to language code conversion
        // e.g. "zh-Hans-CN" will resolve to "zh",
        default: ["en"],
      };

      return fallbacks;
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: [
      "en",
      // "cy",
      // "de",
      // "fr",
      // "es",
      // "zh",
      // "pt",
      // "ja",
      // "ko",
      // "hi",
      "nl",
      // "it",
    ],
  });

export default i18n;

export const lngsList = [
  // { id: "1", value: "cy", text: "Cymraeg" },
  // { id: "2", value: "de", text: "Deutsch" },
  { id: "3", value: "en", text: "English" },
  // { id: "4", value: "es", text: "Español" },
  // { id: "5", value: "fr", text: "Français" },
  // { id: "6", value: "hi", text: "हिन्दी" },
  // { id: "7", value: "it", text: "Italiano" },
  // { id: "8", value: "ja", text: "日本語" },
  // { id: "9", value: "ko", text: "한국어" },
  { id: "10", value: "nl", text: "Nederlands" },
  // { id: "11", value: "pt", text: "Português" },
  // { id: "12", value: "zh", text: "简体中文" },
];
