/**
 * This code is for setting the efficient routing state.
 * It is used in the efficient routing hook.
 *
 * @param {Partial<AllowList>} action.payload
 * @returns {AllowList}
 */

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type AllowList = {
  accountLocked: boolean;
  accountScreen: boolean;
  downForMaintenance: boolean;
  loading: boolean;
  mainAccessScreens: boolean;
  productScreen: boolean;
  termsScreen: boolean;
  wizardScreen: boolean;
};

const initialRouting: AllowList = {
  accountLocked: false,
  accountScreen: false,
  downForMaintenance: false,
  loading: true, /// check false
  mainAccessScreens: false,
  productScreen: false,
  termsScreen: false,
  wizardScreen: false,
};

const initialState = {
  efficientRouting: initialRouting,
};

const efficientRoutingSlice = createSlice({
  name: "efficientRouting",
  initialState,
  reducers: {
    setEfficientRouting: (state, action: PayloadAction<Partial<AllowList>>) => {
      state.efficientRouting = { ...initialRouting, ...action.payload };
    },
  },
});

export const { setEfficientRouting } = efficientRoutingSlice.actions;

export default efficientRoutingSlice.reducer;
