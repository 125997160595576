import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { faFolderPlus, faFolderTree } from "@fortawesome/pro-solid-svg-icons";
import {
  getNestFoldersFlattened,
  getSelectedNestFolderId,
  getSelectedNestFolderMoveId,
} from "../nests/nests";
import {
  setNestingFolderModalVisibility,
  setSessionDirty,
} from "./nestingSlice";
import {
  setSelectedNestFolderId,
  setSelectedNestFolderMoveId,
  setTemporaryNestFolder,
} from "../nests/nestsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import MoveNestToFolder from "../nests/moveNestToFolder/MoveNestToFolder";
import { getNestingFolderModalVisibility } from "./nesting";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ChooseNestingFolderDynamicModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const nestingFolderModalVisibility = useAppSelector(
    getNestingFolderModalVisibility
  );
  const selectedNestsMoveFolderId = useAppSelector(getSelectedNestFolderMoveId);
  const NestFoldersFlattened = useAppSelector(getNestFoldersFlattened);
  const selectedNestFolderId = useAppSelector(getSelectedNestFolderId);

  const [folderName, setFolderName] = useState<string>("");
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const showModal = useCallback(() => {
    navigate(t("paths.chooseNestingFolderHash") ?? "");
    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {});

    bsModal.show();
  }, [navigate, t]);

  const hiddenModal = useCallback(() => {
    dispatch(setNestingFolderModalVisibility(false));
    dispatch(setSelectedNestFolderMoveId(""));
    setFolderName("");
    if (window.location.hash) {
      navigate(window.location.pathname);
    }
  }, [dispatch, navigate]);

  const hideModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);

    bsModal?.hide();
  }, []);

  useEffect(() => {
    const modalEle = modalRef.current;

    if (modalEle) {
      modalEle.addEventListener("hidden.bs.modal", hiddenModal);
      window.addEventListener("popstate", hideModal);
    }

    if (nestingFolderModalVisibility) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      modalEle && modalEle.removeEventListener("hidden.bs.modal", hiddenModal);
      window.removeEventListener("popstate", hideModal);
    };
  }, [hiddenModal, hideModal, nestingFolderModalVisibility, showModal]);

  useEffect(() => {
    if (selectedNestsMoveFolderId) {
      setFolderName(
        NestFoldersFlattened.find((f) => f.id === selectedNestsMoveFolderId)
          ?.name ?? ""
      );
    } else {
      setFolderName("");
    }
  }, [selectedNestsMoveFolderId, NestFoldersFlattened]);

  function mouseOver(name: string) {
    if (!selectedNestsMoveFolderId) {
      setFolderName(name);
    }
  }

  function mouseOut() {
    if (!selectedNestsMoveFolderId) {
      setFolderName("");
    }
  }

  const handleConfirmSetNestingFolder = useCallback(() => {
    dispatch(setSelectedNestFolderId(selectedNestsMoveFolderId ?? ""));
    hideModal();

    if (selectedNestsMoveFolderId !== selectedNestFolderId) {
      dispatch(setSessionDirty(true));
    }
  }, [selectedNestsMoveFolderId, dispatch, selectedNestFolderId, hideModal]);

  function addFolderHandler() {
    dispatch(
      setTemporaryNestFolder({ id: "temp", name: "", childFolders: [] })
    );
  }

  function addSubFolderHandler() {
    if (selectedNestsMoveFolderId) {
      dispatch(
        setTemporaryNestFolder({
          id: "temp",
          name: "",
          childFolders: [],
          parentId: selectedNestsMoveFolderId,
        })
      );
    }
  }

  return nestingFolderModalVisibility ? (
    <div
      ref={modalRef}
      className="modal fade"
      id="chooseNestingFolderDynamicModal"
      tabIndex={-1}
      aria-labelledby="chooseNestingFolderModalDynamicLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h6
              className="modal-title  text-nowrap overflow-hidden"
              id="chooseNestingFolderModalDynamicLabel"
            >
              {t("nesting.nestingFolder")}:{" "}
              {folderName && (
                <span
                  className={`${
                    selectedNestsMoveFolderId ? "text-primary-emphasis" : ""
                  }`}
                  style={{ animation: "fadeIn 1s" }}
                >
                  {folderName}
                </span>
              )}
            </h6>
          </div>

          {/* Body */}
          <div className="modal-body overflow-x-auto">
            <MoveNestToFolder
              mouseOver={mouseOver}
              mouseOut={mouseOut}
            />
          </div>
          {/* Footer */}

          <div className="modal-footer justify-content-between">
            {/* New Folder */}
            <button
              id="button-addFolderNesting"
              className="btn btn-sm btn-primary theme-min-160"
              onClick={addFolderHandler}
            >
              <FontAwesomeIcon
                icon={faFolderPlus}
                className="me-2"
                fixedWidth
              />
              {t("button.newFolder")}
            </button>

            {/* Sub Folder */}
            <button
              id="button-addFolderNesting"
              className="btn btn-sm btn-primary theme-min-160"
              onClick={addSubFolderHandler}
              disabled={!selectedNestsMoveFolderId}
            >
              <FontAwesomeIcon
                icon={faFolderTree}
                className="me-2"
                fixedWidth
              />
              {t("button.newSubFolder")}
            </button>

            {/* Cancel */}
            <div className="ms-auto ">
              <button
                id="button-cancelMoveNestsToFolder"
                type="button"
                className="btn btn-secondary btn-sm me-2 "
                onClick={hideModal}
              >
                {t("button.cancel")}
              </button>

              {/* Select */}
              <button
                id="button-confirmSetNestingFolder"
                type="button"
                className="btn btn-primary btn-sm theme-min-160"
                disabled={!selectedNestsMoveFolderId}
                onClick={handleConfirmSetNestingFolder}
              >
                <span>{t("button.select")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChooseNestingFolderDynamicModal;
