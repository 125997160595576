import {
  getAccount,
  getAccountLoading,
  getProfileData,
} from "../../features/payment/payment";
import { useEffect, useState } from "react";

import { AccountStates } from "../../serviceClient/api.dtos";
import { getUserInfo } from "../../features/common/common";
import { getUserSettings } from "../../features/settings/settings";
import { useAppSelector } from "./reduxTypedHooks";

export function useAccountWarning() {
  const accountLoading = useAppSelector(getAccountLoading);
  const user = useAppSelector(getUserInfo);
  const account = useAppSelector(getAccount);
  const profile = useAppSelector(getProfileData);
  const userSettings = useAppSelector(getUserSettings);

  const [nameInvalid, setNameInvalid] = useState<boolean>(false);
  const [cardInvalid, setCardInvalid] = useState<boolean>(false);
  const [lastPaymentFailed, setLastPaymentFailed] = useState<boolean>(false);
  const [pastPaymentDue, setPastPaymentDue] = useState<boolean>(false);
  const [delinquent, setDelinquent] = useState<boolean>(false);
  const [cancelledWarning, setCancelledWarning] = useState<boolean>(false);
  const [cancelledDanger, setCancelledDanger] = useState<boolean>(false);

  useEffect(() => {
    //Missing Name

    if (
      accountLoading === "succeeded" &&
      userSettings.initialSetupComplete &&
      (!user.firstName || !user.lastName)
    ) {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    //Card Invalid
    if (
      profile.isFreeAccount === false &&
      accountLoading === "succeeded" &&
      userSettings.initialSetupComplete
    ) {
      if (account.paymentDetails) {
        setCardInvalid(!account.paymentDetails?.cardIsValid);
        setLastPaymentFailed(account.paymentDetails?.lastPaymentFailed);
      }
    } else {
      setCardInvalid(false);
      setLastPaymentFailed(false);
    }

    // PastDue
    if (
      accountLoading === "succeeded" &&
      account.accountState.toString().toLowerCase() ===
        AccountStates[AccountStates.PastDue].toString().toLowerCase()
    ) {
      setPastPaymentDue(true);
    } else {
      setPastPaymentDue(false);
    }

    // Delinquent
    if (
      accountLoading === "succeeded" &&
      account.accountState.toString().toLowerCase() ===
        AccountStates[AccountStates.Delinquent].toString().toLowerCase()
    ) {
      setDelinquent(true);
    } else {
      setDelinquent(false);
    }

    // Cancelled
    if (
      accountLoading === "succeeded" &&
      account.accountState.toString().toLowerCase() ===
        AccountStates[AccountStates.Cancelled].toString().toLowerCase()
    ) {
      if (account.isActive) {
        setCancelledWarning(true);
      } else {
        setCancelledDanger(true);
      }
    } else {
      setCancelledWarning(false);
      setCancelledDanger(false);
    }
  }, [
    account.accountState,
    account.isActive,
    account.paymentDetails,
    accountLoading,
    profile.isFreeAccount,
    user.firstName,
    user.lastName,
    userSettings.initialSetupComplete,
  ]);

  return {
    accountWarning:
      cancelledDanger ||
      cardInvalid ||
      delinquent ||
      lastPaymentFailed ||
      nameInvalid ||
      pastPaymentDue,
    delinquent,
    cancelledWarning,
    cancelledDanger,
    cardInvalid,
    nameInvalid,
    lastPaymentFailed,
    pastPaymentDue,
  };
}
