import {
  FunctionComponent,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  faChevronRight,
  faFolder,
  faFolderCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  getSelectedNestFolderId,
  getSelectedNestFolderMoveId,
  getSelectedNestFolderParentTreeIds,
} from "../nests";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/hooks/reduxTypedHooks";

import { Collapse } from "bootstrap";
import EditableText from "../../nests/NestsLibraryFolderEditableText";
import { FolderHierarchy } from "../../../serviceClient/api.dtos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoveNestToFolderList from "./MoveNestToFolderList";
import { getNestingFolderModalVisibility } from "../../nesting/nesting";
import { setSelectedNestFolderMoveId } from "../nestsSlice";
import styles from "./MoveNestToFolderRow.module.scss";

const MoveNestToFolderRow: FunctionComponent<{
  folder: FolderHierarchy;
  propCollapse?: boolean;
  onMouseOver: Function;
  onMouseOut: () => void;
}> = ({ folder, propCollapse, onMouseOver, onMouseOut }) => {
  const dispatch = useAppDispatch();
  const nestingFolderModalVisibility = useAppSelector(
    getNestingFolderModalVisibility
  );
  const selectedNestFolderMoveId = useAppSelector(getSelectedNestFolderMoveId);
  const selectedNestFolderParentTreeIds = useAppSelector(
    getSelectedNestFolderParentTreeIds
  );

  const collapseRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [collapse, setCollapse] = useState(false);
  const [dropDisable, setDropDisable] = useState(false);
  const selectedNestFolderId = useAppSelector(getSelectedNestFolderId);

  const openCollapse = useCallback(() => {
    const element = collapseRef.current;
    const bsCollapse = Collapse.getOrCreateInstance(element, {});
    bsCollapse.show();
  }, []);

  const closeCollapse = useCallback(() => {
    const navElement = collapseRef.current;
    const bsCollapse = Collapse.getInstance(navElement);

    bsCollapse?.hide();
  }, []);

  useEffect(() => {
    if (collapseRef.current) {
      if (collapse) {
        openCollapse();
      } else {
        closeCollapse();
      }
    }
  }, [collapse, closeCollapse, openCollapse]);

  // useEffect(() => {
  //   if (folder.id === selectedNestFolderId) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [folder.id, selectedNestFolderId]);

  //Close child folders when parent is closed
  useEffect(() => {
    if (propCollapse === false) {
      setCollapse(false);
    }
  }, [propCollapse]);

  const handleCollapse = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (folder.childFolders?.length > 0 && !dropDisable) {
        setCollapse((collapse) => !collapse);

        //unselect folder if parent is closed - stop adding new sub folder to hidden folder
        if (collapse && selectedNestFolderMoveId !== folder.id) {
          dispatch(setSelectedNestFolderMoveId(""));
        }
      }
    },
    [
      collapse,
      dispatch,
      dropDisable,
      folder.childFolders?.length,
      folder.id,
      selectedNestFolderMoveId,
    ]
  );

  useEffect(() => {
    const collapseElement = collapseRef.current;

    const eventDisableRef = () => {
      setDropDisable(true);
    };
    const eventEnableRef = () => {
      setDropDisable(false);
    };
    if (collapseElement) {
      //Prevent double click on collapse - causes icon to go out of sync
      collapseElement.addEventListener("show.bs.collapse", eventDisableRef);
      collapseElement.addEventListener("shown.bs.collapse", eventEnableRef);
      collapseElement.addEventListener("hide.bs.collapse", eventDisableRef);
      collapseElement.addEventListener("hidden.bs.collapse", eventEnableRef);

      return () => {
        collapseElement.removeEventListener(
          "show.bs.collapse",
          eventDisableRef
        );
        collapseElement.removeEventListener(
          "shown.bs.collapse",
          eventEnableRef
        );
        collapseElement.removeEventListener(
          "hide.bs.collapse",
          eventDisableRef
        );
        collapseElement.removeEventListener(
          "hidden.bs.collapse",
          eventEnableRef
        );
      };
    }
  }, []);

  function handleFolderClick(
    e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.stopPropagation();

    if (selectedNestFolderMoveId === folder.id) {
      dispatch(setSelectedNestFolderMoveId(""));
      return;
    }
    dispatch(setSelectedNestFolderMoveId(folder.id));
  }

  // Add Sub Folder - Open Collapse
  useEffect(() => {
    dispatch(setSelectedNestFolderMoveId(selectedNestFolderId));
    if (selectedNestFolderParentTreeIds.includes(folder.id)) {
      //if a new folder is added, select it
      if (selectedNestFolderId !== "temp") {
        dispatch(setSelectedNestFolderMoveId(selectedNestFolderId));
      }
      if (collapseRef.current) {
        openCollapse();
      }
      setCollapse(true);
    }
  }, [
    selectedNestFolderParentTreeIds,
    nestingFolderModalVisibility,
    folder.id,
    selectedNestFolderId,
    dispatch,
    openCollapse,
  ]);
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <li
      className={` theme-no-select ps-4 pt-1
          border-0 ${styles.movePartToFolderRowList}     
       `}
      key={folder.id}
      style={{ borderColor: "transparent" }}
    >
      <>
        <div className="text-body-emphasis d-flex align-items-center">
          {folder.childFolders?.length > 0 ? (
            <div className="d-flex align-items-center">
              {/* Chevron */}
              <div
                className="me-1 d-flex align-items-center"
                style={{ width: "22px", height: "22px" }}
              >
                <button
                  className="text-body-secondary btn btn-dark p-0 border-0"
                  style={{ width: "22px", height: "22px" }}
                  onClick={handleCollapse}
                >
                  <FontAwesomeIcon
                    style={{ transition: ".3s" }}
                    icon={faChevronRight}
                    fixedWidth
                    rotation={collapse ? 90 : undefined}
                  />
                </button>
              </div>

              <button
                className={`btn py-0 px-1 text-start text-nowrap d-flex align-items-center theme-pointer  ${
                  selectedNestFolderMoveId === folder.id
                    ? // Selected state
                      "active"
                    : "btn-dark"
                }`}
                onClick={handleFolderClick}
                // disabled={disable}
                onMouseOver={() => onMouseOver(folder.name)}
                onMouseOut={onMouseOut}
              >
                <div
                  className="me-1"
                  style={{ width: "22px" }}
                >
                  {/* Folder Icon */}

                  {selectedNestFolderMoveId === folder.id ? (
                    <FontAwesomeIcon
                      icon={faFolderCheck}
                      fixedWidth
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFolder}
                      fixedWidth
                    />
                  )}
                </div>
                <EditableText
                  folder={folder}
                  editableType="Nesting"
                />
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-center ">
              {/* Spacer */}
              <div
                className="me-1"
                style={{ width: "22px", minWidth: "22px", height: "22px" }}
              />
              <button
                className={`btn
                py-0 px-1 text-start text-nowrap  
                d-flex align-items-center theme-pointer   ${
                  selectedNestFolderMoveId === folder.id
                    ? // Selected state
                      "active"
                    : "btn-dark"
                }`}
                // disabled={disable}
                onClick={handleFolderClick}
                onMouseOver={() => onMouseOver(folder.name)}
                onMouseOut={onMouseOut}
              >
                <div
                  className="me-1"
                  style={{ width: "22px" }}
                >
                  {/* Folder Icon */}
                  {selectedNestFolderMoveId === folder.id ? (
                    <FontAwesomeIcon
                      icon={faFolderCheck}
                      fixedWidth
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFolder}
                      fixedWidth
                    />
                  )}
                </div>

                <EditableText
                  folder={folder}
                  editableType="Nesting"
                />
              </button>
            </div>
          )}
        </div>
        {folder.childFolders?.length > 0 && (
          <div
            ref={collapseRef}
            className="collapse"
            id={folder.id}
          >
            <div className=" ">
              <MoveNestToFolderList
                folders={folder.childFolders}
                propCollapse={collapse}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
              />
            </div>
          </div>
        )}
      </>
    </li>
  );
};

export default memo(MoveNestToFolderRow);
