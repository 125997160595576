import React, { FunctionComponent } from "react";
import {
  acceptTerms,
  fetchTermsStatus,
  setTermsDeclineModalOpen,
} from "./landingSlice";
import {
  getAcceptTermsLoading,
  getTermsAndConditions,
  getTermsAndConditionsStatus,
} from "./landing";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { AccountStates } from "../../serviceClient/api.dtos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsDeclineDynamicModal from "./TermsDeclineDynamicModal";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { fetchAccountDetails } from "../payment/paymentSlice";
import { fetchPendingInvite } from "../teams/teamsSlice";
import { fetchUserSettings } from "../settings/settingsSlice";
import { initialApiCalls } from "../../app/store";
import { setEfficientRouting } from "../efficientRouting/efficientRoutingSlice";
import { useTranslation } from "react-i18next";

const TermsScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const termsAndConditions = useAppSelector(getTermsAndConditions);
  const acceptTermsLoading = useAppSelector(getAcceptTermsLoading);
  const userTermsStatus = useAppSelector(getTermsAndConditionsStatus);

  function acceptTermsHandler() {
    dispatch(acceptTerms())
      .unwrap()
      .then(() => {
        ///
        dispatch(fetchTermsStatus());

        dispatch(fetchAccountDetails())
          .unwrap()
          .then((accountDetails) => {
            //

            if (
              accountDetails.accountState.toString().toLowerCase() !==
              AccountStates[AccountStates.None].toString().toLowerCase()
            ) {
              dispatch(fetchUserSettings());
              // show Initial products to purchase

              initialApiCalls();
            } else {
              //new user
              //restart app
              dispatch(fetchPendingInvite());
              dispatch(
                setEfficientRouting({
                  loading: false,
                  productScreen: true,
                })
              );
            }
          });
      });
  }

  function handlePrint() {
    const win = window.open(
      "",
      "MyNesting Service Terms and Conditions",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
    );

    win!.document.write(termsAndConditions?.content || "");
    win!.document.close();
    win!.focus();
    win!.print();
    win!.close();
  }

  return (
    <>
      <div className="col d-flex flex-column">
        {typeof userTermsStatus.currentlyAcceptedTerms !== "undefined"
          ? userTermsStatus.currentlyAcceptedTerms > 0 && (
              <div
                className="p-3 mb-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 text-center"
                role="alert"
              >
                <strong>{t("terms.updateTitle")}</strong>
              </div>
            )
          : null}

        <div className="card rounded-0 flex-grow-1 align-items-center justify-content-center py-4">
          <div className="theme-terms-card card rounded h-100 border-primary mx-auto">
            <h5 className="card-header text-center py-3">{t("terms.title")}</h5>

            <div className="card-body position-relative bg-white">
              <iframe
                id={termsAndConditions?.id.toString()}
                name="Terms and Conditions"
                frameBorder="0"
                width="100%"
                height="100%"
                title="terms"
                srcDoc={
                  termsAndConditions?.content ||
                  "<p>There seems to be an error. Please refresh the screen.<p>"
                }
                sandbox="allow-same-origin"
              />
            </div>

            <div className="card-footer ">
              <div className="px-3 pt-3">
                <p className="small">{t("terms.p1")}</p>
                <p className="small mb-2">{t("terms.p2")}</p>
              </div>

              <div className="d-flex justify-content-between py-3">
                {/* Decline */}
                <div className="d-flex align-items-center">
                  <button
                    onClick={() => {
                      dispatch(setTermsDeclineModalOpen(true));
                    }}
                    className="btn btn-secondary me-3"
                  >
                    {t("button.declineTerms")}
                  </button>
                  {/* Print */}
                  <div className="text-center">
                    <button
                      className="btn btn-link btn-sm"
                      onClick={handlePrint}
                    >
                      Print
                    </button>
                  </div>
                </div>

                {/* Accept Terms */}
                <button
                  className="btn btn-primary theme-spin-button"
                  onClick={acceptTermsHandler}
                  disabled={
                    acceptTermsLoading === "pending" ||
                    !termsAndConditions?.content
                  }
                >
                  {acceptTermsLoading === "pending" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        spin
                        fixedWidth
                        className="me-2"
                      />
                    </>
                  ) : (
                    <>{t("button.acceptTerms")}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TermsDeclineDynamicModal />
    </>
  );
};

export default TermsScreen;
