import React, { FunctionComponent } from "react";

import { getDownForMaintenanceMessage } from "../common/common";
import { useAppSelector } from "../../app/hooks/reduxTypedHooks";
import { useTranslation } from "react-i18next";

const DownForMaintenance: FunctionComponent = () => {
  const { t } = useTranslation();

  const downForMaintenanceMessage = useAppSelector(
    getDownForMaintenanceMessage
  );

  return (
    <div className="container h-100">
      <div className="d-flex align-items-center justify-content-center h-100 ">
        <div className="card p-4 text-center">
          <h3>{t("titles.wereUpdatingTheService")}</h3>
          {downForMaintenanceMessage ? (
            <p>{downForMaintenanceMessage}</p>
          ) : (
            <p>{t("titles.checkBackSoonToSeeTheImprovements")}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default DownForMaintenance;
