import { FunctionComponent, ReactNode } from "react";

export const LandingScreenContainer: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <div className="card rounded-0 theme-card-lighter flex-grow-1 align-items-center justify-content-center">
      {children}
    </div>
  );
};
