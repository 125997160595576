import * as signalR from "@microsoft/signalr";

import {
  NestingProgressState,
  setNestingProgress,
} from "../../features/nesting/nestingSlice";

import { IServerEvents } from "./IServerEvents";
import { PublicClientApplication } from "@azure/msal-browser";
import { RootState } from "../../app/store";
import { getServerEventHandlers } from "./ServerEventHandlers";
import { getTokenRedirect } from "../../Util";

const nestingEvents = ["NestingSessionUpdate"];
const serverEventURI = "/nestinghub";

export default class ServerEventsNesting implements IServerEvents {
  protected _sessionId: string;

  // Setter for _sessionId
  public set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  // Setter for getState
  public set getState(getState: any) {
    this._getState = getState;
  }

  // Setter for getState
  public set dispatch(dispatch: any) {
    this._dispatch = dispatch;
  }

  private _dispatch: (action: any) => void;
  private _getState: () => RootState;

  private _hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(
      process.env.REACT_APP_BUILD_TYPE === "production"
        ? signalR.LogLevel.Warning
        : signalR.LogLevel.Debug
    )
    .withUrl(process.env.REACT_APP_API_BASE_URL + serverEventURI, {
      accessTokenFactory: async () => {
        let token: string = "";
        await getTokenRedirect(window.MsalInstance as PublicClientApplication)
          .then((response) => {
            if (response) {
              token = response.token ?? "";
            }
          })
          .catch(() => "");
        return token;
      },
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();

  private _startSignalRConnection = async () => {
    const dispatch = this._dispatch;
    let hub = this._hubConnection;
    await hub
      .start()
      .then(async () => {
        console.debug("Nesting SignalR Connection Started  ", this._sessionId);

        await hub
          .invoke("ListenForUpdates", this._sessionId)
          .catch(function (err) {
            dispatch(setNestingProgress(NestingProgressState.Error));
            return console.debug("SignalR ", err.toString());
          });
      })
      .catch((err) => {
        console.error("Nesting SignalR Connection Error: ", err);
        if (hub.state !== signalR.HubConnectionState.Disconnected) {
          setTimeout(() => {
            hub.invoke("ListenForUpdates", this._sessionId);
          }, 3000);
        }
      });

    return hub;
    //
  };

  public constructor() {
    this.initialiseConnection();
  }

  public connectionIsLive(): boolean {
    return this._hubConnection.state === "Connected";
  }

  public dispose() {
    return this._hubConnection.stop();
  }

  public setDispatch(dispatch: (action: any) => void) {
    this._dispatch = dispatch;
  }

  public setGetState(getState: any) {
    this._getState = getState;
  }

  public async setSessionId(sessionId: string) {
    this._sessionId = sessionId;

    await this.resetChannels();
  }

  public triggerEvent(event: { eventName: string; params: object }) {
    getServerEventHandlers(this._dispatch, this._getState).NestingSessionUpdate(
      event.params
    );
  }

  protected _onConnection() {
    nestingEvents.map((event) =>
      this._hubConnection.on(event, (model: any) => {
        this.triggerEvent({
          eventName: event,
          params: model,
        });
      })
    );
  }
  protected _onCloseConnection() {
    // re-establish the connection if connection dropped

    this._hubConnection.onclose(() => setTimeout(this._onConnection, 5000));
  }

  protected async initialiseConnection(): Promise<void> {
    this._onConnection();
    if (this._sessionId) {
      this._onCloseConnection();
    }
  }

  private async resetChannels(): Promise<void> {
    if (this._hubConnection.state !== "Disconnected") {
      await this._hubConnection.stop();
    }

    if (this._sessionId) {
      this._startSignalRConnection();
    }
  }
}
