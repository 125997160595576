import { SavedNestsSearch, SortDescriptor } from "./nestsSlice";

import { Loading } from "../common/commonTypes";
import { RootState } from "../../app/store";

export const getLoadingRecentNests = (state: RootState): Loading =>
  state.nests.recentNestsLoading;

export const getSavedNestsLoading = (state: RootState): Loading =>
  state.nests.savedNestsLoading;

export const getRecentNests = (state: RootState) => state.nests.recentNests;

export const getRecentNestsLastPulled = (state: RootState): string =>
  state.nests.recentNestsLastPulled;

export const getSavedNests = (state: RootState) => state.nests.savedNests;

export const getSavedNestsLastPulled = (state: RootState): string =>
  state.nests.nestFoldersLastPulled;

export const getSavedNestsPage = (state: RootState): number =>
  state.nests.savedNestsPage;

export const getSavedNestsPerPage = (state: RootState): number =>
  state.nests.savedNestsPerPage;

export const getSavedNestsSearch = (state: RootState): SavedNestsSearch =>
  state.nests.savedNestsSearch;

export const getSavedNestsSortBy = (state: RootState): SortDescriptor =>
  state.nests.savedNestsSortBy;

export const getSavedNestsTotal = (state: RootState): number =>
  state.nests.savedNestsTotal;

export const getRehydrateNestLoading = (state: RootState): Loading =>
  state.nests.rehydrateNestingSessionLoading;

export const getNestDeleteModalOpen = (state: RootState): boolean =>
  state.nests.nestDeleteModalOpen;

export const getNestIdToDelete = (state: RootState): string =>
  state.nests.nestIdToDelete;

export const getDeleteNestLoading = (state: RootState): Loading =>
  state.nests.deleteNestLoading;

export const getNestSearchActive = (state: RootState): boolean =>
  state.nests.nestSearchActive;

export const getNestFolders = (state: RootState) => state.nests.nestFolders;

export const getSelectedNestFolderId = (state: RootState) =>
  state.nests.selectedNestFolderId;

export const getSelectedNestFolder = (state: RootState) =>
  state.nests.selectedNestFolder;

export const getSelectedNestFolderParentTreeIds = (state: RootState) =>
  state.nests.selectedNestFolderParentTreeIds;

export const getSelectedStaticNestFolder = (state: RootState) =>
  state.nests.selectedStaticNestFolder;

export const getSelectedNestFolderMoveId = (state: RootState) =>
  state.nests.selectedNestFolderMoveId;

export const getNestFoldersFlattened = (state: RootState) =>
  state.nests.nestFoldersFlattened;

export const getMoveNestsToFolderModalVisibility = (state: RootState) =>
  state.nests.moveNestsToFolderModalVisibility;

export const getMoveNestsToFolderLoading = (state: RootState) =>
  state.nests.moveNestsToFolderLoading;

export const getNestSearchCollapse = (state: RootState): boolean =>
  state.nests.nestSearchCollapse;

export const getDeleteNestFolderModalVisibility = (state: RootState) =>
  state.nests.deleteNestFolderModalVisibility;

export const getDeleteNestFolderLoading = (state: RootState) =>
  state.nests.deleteNestFolderLoading;

export const getNestFoldersLoading = (state: RootState) =>
  state.nests.nestFoldersLoading;

export const getNestFoldersLastPulled = (state: RootState) =>
  state.nests.nestFoldersLastPulled;

export const getSelectedNestFolderTreeIds = (state: RootState) =>
  state.nests.selectedNestFolderTreeIds;

export const getExampleProjectModalVisibility = (state: RootState) =>
  state.nests.exampleProjectModalVisibility;
