import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "../../app/store";

export type ToastUndo = {
  text: string;
  undoId: string;
  folderClick: () => void;
  onUndo: () => void;
};

export type Color =
  | "primary"
  | "secondary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "light"
  | "dark";

export type ToastMessage = {
  id: string;
  severity: Color;
  text: string;
  timeout?: number;
  undo?: ToastUndo;
};
type ToastMessagesState = {
  messages: ToastMessage[];
};

const toastMessagesSlice = createSlice({
  initialState: {
    messages: [],
  } as ToastMessagesState,
  name: "toastMessagesSlice",
  reducers: {
    setToastMessages(state, action: PayloadAction<ToastMessage[]>) {
      state.messages = action.payload;
    },
    removeToastMessage(state, action: PayloadAction<Partial<ToastMessage>>) {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload.id
      );
    },
  },
});
/**
 * @param {ToastMessage}  newToast
 * @returns {AppThunk}
 */
export const addToastMessage =
  (newToast: ToastMessage): AppThunk =>
  (dispatch, getState) => {
    dispatch(
      setToastMessages([newToast, ...getState().toastMessages.messages])
    );
  };

export const { setToastMessages, removeToastMessage } =
  toastMessagesSlice.actions;

export default toastMessagesSlice.reducer;
