import { useAppDispatch, useAppSelector } from "./reduxTypedHooks";

import { SessionType } from "../../features/common/commonTypes";
import { getImportSessionDirty } from "../../features/importer/importer";
import { getNestingSessionDirty } from "../../features/nesting/nesting";
import { setNavigationWarning } from "../../features/common/commonSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useIntercept() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const nestingSessionDirty = useAppSelector(getNestingSessionDirty);
  const importSessionDirty = useAppSelector(getImportSessionDirty);

  const click = ({
    event,
    link,
    callback,
    sessionType,
  }: {
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>;
    sessionType: SessionType[];
    link?: string;
    callback?: Function;
  }) => {
    const inImporterModal = location.hash === t("paths.importerHash");

    if ((nestingSessionDirty && !inImporterModal) || importSessionDirty) {
      const message =
        nestingSessionDirty && !inImporterModal
          ? t("abandonModal.nestingSession") ?? ""
          : t("abandonModal.importSession") ?? "";

      dispatch(
        setNavigationWarning({
          destination: link,
          message: message,
          open: true,
          sessionType: sessionType,
          callback: callback,
        })
      );

      event.preventDefault();

      return false;
    } else {
      callback && callback();
    }
  };

  return click;
}

export default useIntercept;
