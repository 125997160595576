import {
  FolderSearchType,
  PartSearchParameter,
} from "../../serviceClient/api.dtos";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchParts,
  setCurrentPage,
} from "../libraryPartList/libraryPartListSlice";

import { AppThunk } from "../../app/store";
import { SearchFilter } from "../libraryPartList/PartsLibrary.dtos";

type LibraryPartSearchState = {
  activeFilters: SearchFilter[];
  searchTerms: PartSearchParameter[];
};

const libraryPartSearchSlice = createSlice({
  initialState: {
    activeFilters: [],
    searchTerms: [],
  } as LibraryPartSearchState,
  name: "libraryPartSearchSlice",
  reducers: {
    setActiveFilters(state, action: PayloadAction<SearchFilter[]>) {
      state.activeFilters = action.payload;
    },
    setSearchTerms(state, action: PayloadAction<PartSearchParameter[]>) {
      state.searchTerms = action.payload;
    },
  },
});

export const applyActiveFilters =
  (): AppThunk => async (dispatch, getState) => {
    const activeFilters = getState().libraryPartSearch.activeFilters;

    const selectedPartFolderId =
      getState().libraryPartList.selectedPartFolderId;

    let SearchParams = [] as PartSearchParameter[];

    activeFilters.forEach((filter) => {
      //Ignore empty filters
      if (!filter.value && !filter.value2) {
        return;
      }
      let searchParam = {} as PartSearchParameter;

      searchParam.field = filter.filterName;
      searchParam.valueOne = filter.value;
      if (filter.value2) {
        searchParam.valueTwo = filter.value2;
      }
      if (filter.filterType) {
        searchParam.operation = filter.filterType;
      }

      SearchParams.push(searchParam);
    });

    dispatch(setSearchTerms(SearchParams));
    dispatch(setCurrentPage(1));

    if (selectedPartFolderId) {
      dispatch(
        fetchParts({
          folderId: selectedPartFolderId,
          searchType: FolderSearchType.Subfolders,
        })
      );
    } else {
      dispatch(
        fetchParts({
          searchType: FolderSearchType.Global,
        })
      );
    }
  };

export const { setActiveFilters, setSearchTerms } =
  libraryPartSearchSlice.actions;

export default libraryPartSearchSlice.reducer;
