import { ChangeEvent, FunctionComponent, useState } from "react";
import { fetchSavedNests, rehydrateNestingSession } from "../nests/nestsSlice";
import { getBase64, guid } from "../../Util";
import { getRehydrateNestLoading, getSavedNestsLoading } from "../nests/nests";
import { restoreProject, setSessionId } from "./nestingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RestoreExistingProject } from "../../serviceClient/api.dtos";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { fetchUserSettings } from "../settings/settingsSlice";
import { getRestoreProjectLoading } from "./nesting";
import { getSettingsLoading } from "../settings/settings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NestProjectImportScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const [file64, setFile64] = useState<string | ArrayBuffer | null>("");
  const [fileName, setFileName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [override, setOverride] = useState<boolean>(false);
  const [keepOriginalName, setKeepOriginalName] = useState<boolean>(true);
  const [invalidName, setInvalidName] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const restoreProjectLoading = useAppSelector(getRestoreProjectLoading);
  const settingsLoading = useAppSelector(getSettingsLoading);
  const savedNestLoading = useAppSelector(getSavedNestsLoading);
  const rehydrateNestsLoading = useAppSelector(getRehydrateNestLoading);

  function fileHandler(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;

    if (files) {
      setFileName(files[0]?.name ?? "");
      getBase64(files[0]).then((res) => {
        setFile64(res as string | ArrayBuffer | null);
      });
    }
  }

  function projectNameHandler(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    setProjectName(value);
    setInvalidName(false);
    if (value) {
      setKeepOriginalName(false);
    } else if (!value) {
      setKeepOriginalName(true);
    } else if (!keepOriginalName && !value) {
      setInvalidName(true);
    }
  }

  function keepOriginalNameHandler(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.currentTarget;
    setKeepOriginalName(checked);
    setInvalidName(false);
    if (checked) {
      setProjectName("");
    } else if (!checked && !projectName) {
      setInvalidName(true);
    }
  }

  function submitHandler() {
    let restoreProjectArgs = new RestoreExistingProject({
      file: file64 as string,
      fileName: fileName,
      projectName: projectName,
      overwriteSettings: override,
      keepOriginalName: keepOriginalName,
    });

    dispatch(restoreProject(restoreProjectArgs))
      .unwrap()
      .then((data) => {
        dispatch(setSessionId(data.id));
        setFile64(null);
        setFileName("");
        setProjectName("");
        setOverride(false);
        dispatch(fetchSavedNests({}));
        dispatch(fetchUserSettings()).then(() => {
          dispatch(rehydrateNestingSession(data.id)).then(() => {
            navigate("/");
          });
        });
      })
      .catch((e) => {
        dispatch(
          addToastMessage({
            id: guid(),
            severity: "danger",
            text: t("toastMessages.updatingPartError", {
              error: e?.responseStatus?.message,
            }),
          })
        );
      });
  }
  return (
    <div className="row h-100">
      <div className="col d-flex flex-column">
        <div className="card mb-3 flex-grow-1 p-3">
          <div className="row h-100">
            <div className="col-12 col-xl-6 mx-auto d-flex align-items-center ">
              <div className="w-100">
                {/* File Import Input */}
                <div className="row  align-items-center mb-3">
                  <div className="col-12 col-md-3">
                    <label
                      htmlFor="input-fileImport"
                      className="col-form-label"
                    >
                      Import File
                    </label>
                  </div>
                  <div className="col-12 col-md-9">
                    <input
                      className="form-control form-control-sm"
                      id="input-fileImport"
                      type="file"
                      onChange={fileHandler}
                      accept=".zip"
                    />
                  </div>
                </div>
                {/* Project Name */}
                <div className="row mb-3 align-items-center">
                  <div className="col-12 col-md-3">
                    <label
                      htmlFor="input-projectName"
                      className="col-form-label"
                    >
                      Project Name
                    </label>
                  </div>
                  <div className="col-12 col-md-9">
                    <input
                      value={projectName}
                      onChange={projectNameHandler}
                      className={`form-control form-control-sm ${
                        invalidName ? "is-invalid" : ""
                      }`}
                      id="input-projectName"
                      type="text"
                    />
                  </div>
                </div>
                {/*  */}

                {/* Keep original Name */}
                <div className="row mb-3 align-items-center">
                  <div className="col-12 col-md-3">
                    <label
                      htmlFor="input-keepOriginalName"
                      className="col-form-label"
                    >
                      Keep Original Name
                    </label>
                  </div>
                  <div className="col-12 col-md-9">
                    <input
                      className="form-check-input"
                      id="input-keepOriginalName"
                      type="checkbox"
                      checked={keepOriginalName}
                      onChange={keepOriginalNameHandler}
                    />
                  </div>
                </div>

                {/* Override Global settings */}
                <div className="row mb-3 align-items-center">
                  <div className="col-12 col-md-3">
                    <label
                      htmlFor="input-overrideGlobalSettings"
                      className="col-form-label"
                    >
                      Override Global settings
                    </label>
                  </div>
                  <div className="col-12 col-md-9">
                    <input
                      className="form-check-input"
                      id="input-overrideGlobalSettings"
                      type="checkbox"
                      checked={override}
                      onChange={(e) => setOverride(e.currentTarget.checked)}
                    />
                  </div>
                </div>
                {/* Submit */}
                <div className="row  align-items-center">
                  <div className="col-9 offset-md-3">
                    <button
                      id="button-nestingProjectImportSubmit"
                      disabled={!keepOriginalName && !projectName}
                      className="btn btn-efficient theme-spin-button "
                      onClick={submitHandler}
                    >
                      {restoreProjectLoading === "pending" ||
                      settingsLoading === "pending" ||
                      savedNestLoading === "pending" ||
                      rehydrateNestsLoading === "pending" ? (
                        <FontAwesomeIcon
                          icon={faCircleNotch}
                          spin
                          fixedWidth
                        />
                      ) : (
                        <>Submit</>
                      )}
                    </button>
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NestProjectImportScreen;
