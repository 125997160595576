import {
  AddNotesToProject,
  DeleteNoteFromProject,
  NoteDetails,
  NotesForProject,
} from "../../serviceClient/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";

type NotesState = {
  addNoteLoading: Loading;
  deleteNoteLoading: Loading;
  notes: NoteDetails[];
  notesLoading: Loading;
  deleteModalVisibility: boolean;
  selectedNote: NoteDetails | null;
};

const initialState: NotesState = {
  addNoteLoading: "idle",
  deleteNoteLoading: "idle",
  notes: [],
  notesLoading: "idle",
  deleteModalVisibility: false,
  selectedNote: null,
};

export const fetchNotesForProject = createAsyncThunk(
  "notesSlice/fetchNotesForProject",

  async (projectId: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient()
      .then(async (client) => {
        return await client
          .get(new NotesForProject({ projectId }))

          .then((notes) => {
            return notes;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const addNote = createAsyncThunk(
  "notesSlice/addNote",

  async ({ projectId, content }: Partial<AddNotesToProject>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient()
      .then(async (client) => {
        return await client
          .post(new AddNotesToProject({ projectId, content }))

          .then((notes) => {
            return notes;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const deleteNote = createAsyncThunk(
  "notesSlice/deleteNote",

  async ({ noteId, projectId }: Partial<DeleteNoteFromProject>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient()
      .then(async (client) => {
        return await client
          .delete(new DeleteNoteFromProject({ noteId, projectId }))

          .then((notes) => {
            return notes;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setSelectedNote(state, action) {
      state.selectedNote = action.payload;
    },
    setDeleteModalVisibility(state, action) {
      state.deleteModalVisibility = action.payload;
    },
    deleteNoteFromState(state, action) {
      state.notes = state.notes.filter((note) => note.id !== action.payload.id);
    },
    clearNotes(state) {
      state.notes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      // GET: Notes
      .addCase(fetchNotesForProject.pending, (state) => {
        state.notesLoading = "pending";
      })
      .addCase(fetchNotesForProject.fulfilled, (state, action) => {
        state.notesLoading = "idle";
        state.notes = action.payload;
      })
      .addCase(fetchNotesForProject.rejected, (state) => {
        state.notesLoading = "failed";
      })

      // =======================================================================
      // POST: Notes
      .addCase(addNote.pending, (state) => {
        state.addNoteLoading = "pending";
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.addNoteLoading = "idle";
        state.notes = action.payload;
      })
      .addCase(addNote.rejected, (state) => {
        state.addNoteLoading = "failed";
      })

      // =======================================================================
      // DELETE: Notes
      .addCase(deleteNote.pending, (state) => {
        state.deleteNoteLoading = "pending";
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.deleteNoteLoading = "idle";
        state.notes = action.payload;
      })
      .addCase(deleteNote.rejected, (state) => {
        state.deleteNoteLoading = "failed";
      });
  },
});

export const {
  clearNotes,
  deleteNoteFromState,
  setDeleteModalVisibility,
  setSelectedNote,
} = notesSlice.actions;

export default notesSlice.reducer;
