import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { Modal } from "bootstrap";
import { getTermsDeclineModalOpen } from "./landing";
import { logOutThunk } from "../../Util";
import { setTermsDeclineModalOpen } from "./landingSlice";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const TermsDeclineDynamicModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const termsDeclineOpen = useAppSelector(getTermsDeclineModalOpen);

  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;

  const showModal = useCallback(() => {
    navigate(t("paths.termsHash") ?? "");
    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {});

    bsModal.show();
  }, [navigate, t]);

  const hiddenModal = useCallback(() => {
    dispatch(setTermsDeclineModalOpen(false));
    if (window.location.hash) {
      navigate(window.location.pathname);
    }
  }, [dispatch, navigate]);

  const hideModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);
    bsModal?.hide();
  }, []);

  function confirmDeclineHandler() {
    //API call here

    dispatch(logOutThunk());
    if (termsDeclineOpen) {
      hideModal();
    }
  }

  useEffect(() => {
    const modalEle = modalRef.current;
    if (modalEle) {
      modalEle.addEventListener("hidden.bs.modal", hiddenModal);
      window.addEventListener("popstate", hideModal);
    }

    if (termsDeclineOpen) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      modalEle && modalEle.removeEventListener("hidden.bs.modal", hiddenModal);
      window.removeEventListener("popstate", hideModal);
    };
  }, [termsDeclineOpen, showModal, hideModal, hiddenModal]);

  return termsDeclineOpen ? (
    <div
      ref={modalRef}
      className="modal fade"
      id="TermsDeclineModal"
      tabIndex={-1}
      aria-labelledby="TermsDeclineModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Decline</h5>
          </div>

          <div className="modal-body">
            <p>
              You must accept the terms of use to continue with MyNesting
              Online. Please contact us at{" "}
              <a href="mailto:support@mynesting.com">support@mynesting.com</a>{" "}
              if you have any questions or issues with our terms and conditions.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={hideModal}
              className="btn btn-secondary btn-sm  "
              id="button-modalClonePart"
            >
              Close
            </button>
            <button
              className="btn btn-primary btn-sm theme-min-160"
              onClick={confirmDeclineHandler}
              autoFocus
              id="button-modalClonePartDecline"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default TermsDeclineDynamicModal;
