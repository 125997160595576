import {
  faCog,
  faComment,
  faCreditCard,
  faKey,
  faSignOutAlt,
  faTriangleExclamation,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import EfficientTooltip from "../common/EfficientTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import InstallPWA from "../InstallPWA";
import LanguageSwitcher from "./LanguageSwitcher";
import { NavLink } from "react-router-dom";
import { ReactComponent as Profile } from "../../assets/icons/Profile.svg";
import { frontEndFeatures } from "../featuresConfig";
import { getAuthUrls } from "../landing/landing";
import { getEfficientRouting } from "../efficientRouting/efficientRouting";
import { getUser } from "../payment/payment";
import { getUserInfo } from "../common/common";
import { logOutThunk } from "../../Util";
import { setFeedbackModalVisibility } from "../feedback/feedbackSlice";
import styles from "./DropdownNav.module.scss";
import { useAccountWarning } from "../../app/hooks/useAccountWarning";
import useIntercept from "../../app/hooks/useIntercept";
import { useTranslation } from "react-i18next";
import useUpgrade from "../../app/hooks/useUpgrade";

const DropdownNav: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const interceptClick = useIntercept();
  const authUris = useAppSelector(getAuthUrls);

  const userFromToken = useAppSelector(getUserInfo);
  const efficientRoutingState = useAppSelector(getEfficientRouting);
  const { accountWarning } = useAccountWarning();

  const user = useAppSelector(getUser);
  const { i18n } = useTranslation();

  const { upgradeAccount } = useUpgrade();

  return (
    <div className="col text-end d-none d-md-flex align-items-center justify-content-end">
      {process.env.REACT_APP_BUILD_TYPE !== "production" && (
        <div className="d-flex">
          <InstallPWA />
        </div>
      )}

      <div
        className={`dropdown  ${
          frontEndFeatures.UserDropDown ? "" : "d-xl-none"
        } `}
      >
        <button
          className="btn btn-link btn-lg d-flex align-items-center p-2 text-decoration-none text-reset theme-dropdown-button position-relative"
          aria-label="account of current user"
          type="button"
          id="dropdownMenuProfile"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="d-none d-lg-inline text-capitalize small me-3 ">
            {userFromToken.fullName ?? ""}
          </span>
          <div className="position-relative">
            <div
              className="position-absolute "
              style={{ top: "-6px", left: "8px" }}
            >
              {/* Warning Triangle For Invalid Card && Missed Payment */}
              {accountWarning && (
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  fixedWidth
                  className={`text-danger me-2 ${styles.dangerIcon}`}
                  size="xs"
                />
              )}
            </div>

            <Profile
              className="theme-nav-icon theme-nav-icon-color"
              width={22}
            />
          </div>
        </button>
        <ul
          aria-labelledby="dropdownMenuProfile"
          className="dropdown-menu shadow"
        >
          {typeof authUris !== "undefined" && frontEndFeatures.UserDropDown
            ? [
                efficientRoutingState.mainAccessScreens && (
                  //Account
                  <li
                    key={2}
                    id="dropdown-accountLink"
                  >
                    <NavLink
                      to={"/" + i18n.language + "/" + t("paths.account")!}
                      className="text-capitalize dropdown-item small"
                      id="account_Link"
                      onClick={(event) => {
                        interceptClick({
                          event,
                          link: "/" + i18n.language + "/" + t("paths.account")!,
                          sessionType: ["nestingSession", "importSession"],
                        });
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <span>
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faCreditCard}
                            className="me-2"
                          />
                          {t("nav.account")}
                        </span>
                        {/* Warning Triangle For Invalid Card && Missed Payment */}
                        {accountWarning && (
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            fixedWidth
                            className={`text-danger  `}
                          />
                        )}
                      </div>
                    </NavLink>
                  </li>
                ),
                // Change Password
                <li
                  key={authUris?.resetPasswordUri}
                  id="dropdown-changePasswordLink"
                >
                  <a
                    className="dropdown-item text-capitalize small"
                    href={authUris?.resetPasswordUri}
                  >
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faKey}
                      className="me-2"
                    />
                    {t("nav.changePassword")}
                  </a>
                </li>,

                //  {/* ================== Teams Management =================== */}
                efficientRoutingState.mainAccessScreens && (
                  <li
                    key="teams"
                    id="dropdown-teamsLink"
                  >
                    <EfficientTooltip
                      upgradable={!user.teamPlan.teamsEnabled}
                      content={
                        user.teamPlan.teamsEnabled
                          ? []
                          : [t("tooltip.clickToUpgrade")]
                      }
                      delayShow={500}
                      placement="left"
                    >
                      <NavLink
                        to={"/" + i18n.language + "/" + t("paths.teams")!}
                        className={`text-capitalize dropdown-item small ${
                          user.teamPlan.teamsEnabled ? "" : " faux-disabled"
                        }`}
                        onClick={(event) => {
                          if (!user.teamPlan.teamsEnabled) {
                            upgradeAccount();
                            event.preventDefault();
                            return;
                          }
                          interceptClick({
                            event,
                            link: "/" + i18n.language + "/" + t("paths.teams")!,
                            sessionType: ["nestingSession", "importSession"],
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faUserGroup}
                          className="me-2"
                        />
                        {t("nav.teams")}
                      </NavLink>
                    </EfficientTooltip>
                  </li>
                ),

                //  {/* ================== Feedback =================== */}
                frontEndFeatures.FeedBackLink &&
                  efficientRoutingState.mainAccessScreens && (
                    <li
                      className="nav-item d-xxxl-none"
                      key="feedback"
                      id="dropdown-feedbackLink"
                    >
                      <button
                        className="text-capitalize btn btn-link btn-sm theme-mobile-nav text-decoration-none dropdown-item py-1"
                        onClick={() =>
                          window.requestAnimationFrame(() => {
                            dispatch(setFeedbackModalVisibility(true));
                          })
                        }
                      >
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faComment}
                          className="me-2"
                        />
                        {t("nav.feedback")}
                      </button>
                    </li>
                  ),

                //  {/* ================== Language =================== */}
                process.env.REACT_APP_BUILD_TYPE !== "production" && (
                  <li
                    className="nav-item "
                    key="language"
                  >
                    <LanguageSwitcher />
                  </li>
                ),

                //  {/* ================== Settings =================== */}
                frontEndFeatures.UserDropDown &&
                  efficientRoutingState.mainAccessScreens && (
                    <li
                      className="nav-item d-xxxl-none"
                      key="settings"
                      id="dropdown-settingsLink"
                    >
                      <NavLink
                        to={"/" + i18n.language + "/" + t("paths.settings")!}
                        className={({ isActive }) =>
                          "text-capitalize theme-mobile-nav small text-decoration-none dropdown-item " +
                          (isActive ? " theme-nav-active" : "")
                        }
                        onClick={(event) => {
                          interceptClick({
                            event,
                            link:
                              "/" + i18n.language + "/" + t("paths.settings")!,
                            sessionType: ["nestingSession", "importSession"],
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faCog}
                          className="me-2"
                        />
                        {t("nav.settings")}
                      </NavLink>
                    </li>
                  ),

                <li
                  key="divider"
                  className="dropdown-divider"
                ></li>,

                // Logout
                <li
                  key={authUris?.logoutUri}
                  id="dropdown-logoutLink"
                >
                  <button
                    className="text-capitalize dropdown-item small"
                    onClick={(event) =>
                      interceptClick({
                        event,
                        callback: () => dispatch(logOutThunk()),
                        sessionType: ["nestingSession", "importSession"],
                      })
                    }
                  >
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faSignOutAlt}
                      className="me-2"
                    />
                    {t("nav.logout")}
                  </button>
                </li>,
              ]
            : []}
        </ul>
      </div>
    </div>
  );
};

export default DropdownNav;
