import {
  AreaMeasurementUnits,
  Assignment,
  UserSettings,
} from "../../serviceClient/api.dtos";

export type ImporterFile = {
  activeRequests: number;
  awaitingUpdate?: boolean;
  display: boolean;
  fileId: string;
  fileName: string;
  fileStatus: FileStatus;
  reference: string;
  selected: boolean;
  status: ImporterFileStatus;
  message: string;
  groupPartAsDrawn: boolean;
  uniqueRequestId: string;
};

export type ImporterFileSettings = {
  drawingScale: Ratio;
  fileId: string;
  measurementUnits: MeasurementUnits;
};

export type ImporterFileSettingsArgs = {
  drawingScale?: Ratio;
  fileId: string;
  measurementUnits?: MeasurementUnits;
};

export type ImporterFileModel = {
  base64SVG: string;
  fileId: string;
  sessionId: string;
  uiIdentifier: string;
};

export type ImporterFileLayer = {
  fileId: string;
  colourHex: string;
  instruction: Assignment;
  layerName: string;
  lineType: string;
  modelId: string;
  uiIdentifier: string;
  visible: boolean;
};

export type ImporterFileResult = {
  base64Svg: string;
  fileId: string;
  status: FileStatus;
  uiIdentifier: string;
};

export type ImporterFilePart = {
  base64EncodedPng: string;
  isActive: boolean;
  isSelected: boolean;
  labels: string[];
  name: string;
  partId: string;
  resultId: string;
  x: number;
  y: number;
  area: number;
  areaMeasurementUnits: AreaMeasurementUnits;
  quantity: number;
  priority: number;
};

export type ImporterSettings = {
  drawingScale: Ratio;
  measurementUnits: MeasurementUnits;
  panZoomMode: CameraBehaviour;
};

export enum ImporterFileStatus {
  FILE_STATUS_PROCESSING = "FILE_STATUS_PROCESSING",
  FILE_STATUS_UPLOADING = "FILE_STATUS_UPLOADING",
  FILE_STATUS_DOWNLOADING = "FILE_STATUS_DOWNLOADING",
  FILE_STATUS_ERROR = "FILE_STATUS_ERROR",
  FILE_STATUS_WARNING = "FILE_STATUS_WARNING",
  FILE_STATUS_COMPLETE = "FILE_STATUS_COMPLETE",
  FILE_STATUS_TOO_LARGE = "FILE_STATUS_TOO_LARGE",
}

export enum LayerAssignment {
  NotAssigned = "NotAssigned",
  Cut = "Cut",
  Attach = "Attach",
  Ignore = "Ignore",
}

export enum FileStatus {
  ErrorNoPartsFound = "ErrorNoPartsFound",
  WarningNotAllGeometryUsed = "WarningNotAllGeometryUsed",
  SuccessAllGeometryUsed = "SuccessAllGeometryUsed",
}

export class Session {
  public maxFileSizeInMb: number;
  public settings: UserSettings;
  public sessionId: string;
}

export type Ratio = {
  from: number;
  to: number;
};

export enum MeasurementUnits {
  Conflicting = 0,
  Millimetres = 1,
  Inches = 2,
}

export enum CameraBehaviour {
  MoveWork = 1,
  MoveCamera = 2,
}

export enum NamingScheme {
  Filename = 1,
  TextLabel = 2,
  Block = 3,
}

export const measurementUnitShortString = (measurementUnit: string) => {
  switch (MeasurementUnits[measurementUnit]) {
    case "Inches":
      return " in";
    case "Millimetres":
      return " mm";
    default:
      return "--";
  }
};
export type ImporterType = "parts" | "sheets";
