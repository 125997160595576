import { FunctionComponent } from "react";
import MoveNestToFolderList from "./MoveNestToFolderList";
import { getNestFolders } from "../nests";
import { useAppSelector } from "../../../app/hooks/reduxTypedHooks";
import { useTranslation } from "react-i18next";

const MoveNestToFolder: FunctionComponent<{
  mouseOver: (name: string) => void;
  mouseOut: () => void;
}> = ({ mouseOver, mouseOut }) => {
  const { t } = useTranslation();
  const nestFolders = useAppSelector(getNestFolders);

  return (
    <div className="justify-content-between d-flex flex-column">
      {/* Folder Start */}
      <div className="position-relative h-100 w-100">
        <div className="card">
          <div className="card-body px-1">
            {nestFolders.length > 0 && (
              <>
                <span className="ms-2 small fw-bold text-secondary-emphasis">
                  {t("library.selectAFolder")}
                </span>

                {/*   Folder List   */}
                <MoveNestToFolderList
                  folders={nestFolders}
                  onMouseOut={mouseOut}
                  onMouseOver={mouseOver}
                />
              </>
            )}

            {nestFolders.length === 0 && (
              <div className="text-center w-100 small fw-bold text-secondary-emphasis">
                {t("savedNests.noNestFolders")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveNestToFolder;
