import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  FolderSearchType,
  ModelRetrievedResult,
  RetrieveResultIdentity,
} from "./../api.dtos";
import {
  NestingProgressState,
  ReportProgressState,
  fetchNestingResult,
  getCompletedExport,
  setCurrentSettingsHasBeenNested,
  setNestingProgress,
  setReportProgress,
  setWebSocketsError,
} from "../../features/nesting/nestingSlice";
import {
  retrieveModelIdentity,
  retrieveResultIdentity,
  setFiles,
} from "../../features/importer/importerSlice";
import {
  updateFileStatus,
  updateModelLayersMatch,
} from "../../features/importer/importerSliceThunks";

import { ImporterFileStatus } from "../../features/importer/importerTypes.dtos";
import { RetrieveModelIdentity } from "../api.dtos";
import { RootState } from "../../app/store";
import { addToastMessage } from "../../features/toastMessages/toastMessagesSlice";
import { fetchNotesForProject } from "../../features/notes/notesSlice";
import { fetchRecentNests } from "../../features/nests/nestsSlice";
import { fetchSavedNests } from "./../../features/nests/nestsSlice";
import { guid } from "../../Util";
import { t } from "i18next";

export const getServerEventHandlers = (
  dispatch: ThunkDispatch<unknown, Action, any>,
  getState?: () => RootState
) => ({
  FileProcessingErrorResult: (error: {
    fileId: string;
    errorMessage: string;
  }): void => {
    dispatch(updateFileStatus(error.fileId, false));

    dispatch(
      addToastMessage({
        id: error.fileId,
        severity: "danger",
        text: error.errorMessage,
      })
    );

    dispatch(
      setFiles([
        {
          fileId: error.fileId,
          status: ImporterFileStatus.FILE_STATUS_ERROR,
          message: error.errorMessage,
        },
      ])
    );
  },

  FileUploadComplete: (uploadNotification: {
    fileId: string;
    fileReference: string;
    sessionId: string;
  }): void => {
    dispatch(
      setFiles([
        {
          fileId: uploadNotification.fileId,
          reference: uploadNotification.fileReference,
          status: ImporterFileStatus.FILE_STATUS_PROCESSING,
        },
      ])
    );
  },
  FileTooLarge: (error: { fileIdentifier: string }): void => {
    dispatch(
      setFiles([
        {
          fileId: `error${error.fileIdentifier}`,
          reference: error.fileIdentifier,
          status: ImporterFileStatus.FILE_STATUS_TOO_LARGE,
        },
      ])
    );
  },

  FileProcessingApiErrorResult: (error: { fileId: string }): void => {
    dispatch(
      setFiles([
        {
          fileId: error.fileId,
          reference: error.fileId,
          status: ImporterFileStatus.FILE_STATUS_ERROR,
        },
      ])
    );
  },
  ModelProcessingComplete: (file: { fileId: string }): void => {
    dispatch(updateFileStatus(file.fileId, true));

    dispatch(
      retrieveModelIdentity({ fileId: file.fileId } as RetrieveModelIdentity)
    )
      .unwrap()
      .then((payload) => {
        const model = payload as ModelRetrievedResult;

        window.requestAnimationFrame(() =>
          dispatch(updateFileStatus(model.fileId, false, true))
        );
      });
  },
  NestingSessionUpdate: (updateDetails: any): void => {
    if (
      //   "ResultGenerated"
      updateDetails.status === 3 ||
      //   "NestComplete"
      updateDetails.status === 4
    ) {
      //only status 4 will have a "cancelled" prop that could be potentially be true
      if (!(updateDetails.cancelled && updateDetails.resultId === null)) {
        //

        dispatch(
          fetchNestingResult({
            id: updateDetails.resultId,
            jobStatus: updateDetails.status,
          })
        ).catch(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "warning",
              text: t("toastMessages.fetchNestingResultError"),
            })
          );
        });
      }
      if (updateDetails.status === 3) {
        //   "ResultGenerated"
        dispatch(setNestingProgress(NestingProgressState.Active));
      } else {
        //  "NestComplete" 4

        if (getState) {
          // Set Nesting folder active
          const selectedNestFolderId =
            getState().nests.selectedNestFolderId ?? "";
          const selectedStaticNestFolder =
            getState().nests.selectedStaticNestFolder;

          const projectId = getState().nesting.sessionId;

          dispatch(fetchNotesForProject(projectId));

          dispatch(
            fetchSavedNests({
              folderId: selectedNestFolderId,
              searchType:
                selectedStaticNestFolder === "non-foldered" ||
                selectedNestFolderId
                  ? FolderSearchType.Subfolders
                  : FolderSearchType.Global,
            })
          );
          dispatch(fetchRecentNests());
        }
        if (updateDetails.cancelled) {
          dispatch(setNestingProgress(NestingProgressState.Cancelled));
        }

        dispatch(setCurrentSettingsHasBeenNested(true));
        window.ServerEventInstance.dispose();
      }

      //
    } else if (updateDetails.status === 99) {
      //   "Error"

      dispatch(setWebSocketsError(updateDetails.errorMessage));
      dispatch(setNestingProgress(NestingProgressState.Error));
      window.ServerEventInstance.dispose();
    } else if (updateDetails.status === 1) {
      //  "AgentAssigned"
      dispatch(setNestingProgress(NestingProgressState.Active));
    } else if (updateDetails.status === 2) {
      // 2 is missing
    }
  },
  ResultProcessingComplete: (file: {
    fileId: string;
    uniqueReferenceRequest: string;
  }): void => {
    if (dispatch(updateModelLayersMatch(file))) {
      dispatch(updateFileStatus(file.fileId, true));
      dispatch(
        retrieveResultIdentity({
          fileId: file.fileId,
          uniqueRequestId: file.uniqueReferenceRequest,
        } as RetrieveResultIdentity)
      )
        .unwrap()
        .then((result) => {
          dispatch(updateFileStatus(result.result.fileId, false, true));
        })
        .catch(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.fetchNestingResultIdentityError"),
            })
          );
        });
    }
  },
  ExportGenerated: (reportId: string) => {
    dispatch(setReportProgress(ReportProgressState.ReportGenerated));
    dispatch(getCompletedExport(reportId))
      .unwrap()
      .then(() => {})
      .catch(() => {
        dispatch(
          addToastMessage({
            id: guid(),
            severity: "danger",
            text: t("toastMessages.fetchExportError"),
          })
        );
      });
  },
  ExportFailed: () => {
    window.ServerEventReportInstance.dispose();
    dispatch(setReportProgress(ReportProgressState.ReportFailed));
    dispatch(
      addToastMessage({
        id: guid(),
        severity: "danger",
        text: t("toastMessages.exportFailed"),
      })
    );
  },
  onMessage: (anyMessage: any) => {
    console.debug("onMessage", anyMessage);
  },
});
