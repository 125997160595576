import { FormEvent, FunctionComponent, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktopArrowDown } from "@fortawesome/pro-solid-svg-icons";

const InstallPWA: FunctionComponent = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);
  // let deferredPrompt;

  async function get() {
    if ("getInstalledRelatedApps" in window.navigator) {
      try {
        //only works over https
        const relatedApps = await (
          window.navigator as any
        ).getInstalledRelatedApps();
        // console.log(relatedApps);
        relatedApps.forEach(() => {
          // console.log("this", app.id, app.platform, app.url);
        });
      } catch (error) {
        //   console.log("getInstalledRelatedApps", error);
      }
    }
  }

  useEffect(() => {
    const handler = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      //  deferredPrompt = e;
      // console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    const afterInstalled = () => {
      // Hide the app-provided install promotion
      setSupportsPWA(false);
      // Clear the deferredPrompt so it can be garbage collected
      //  deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      //  console.log("PWA was installed");
    };
    get();

    window.addEventListener("beforeinstallprompt", handler);

    window.addEventListener("appinstalled", afterInstalled);

    return () => {
      window.removeEventListener("transitionend", handler);
      window.removeEventListener("transitionend", afterInstalled);
    };
  }, []);

  async function onClick(e: FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    // Show the install prompt
    //  deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    // const { outcome } = await deferredPrompt.userChoice;
    //  console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    //  deferredPrompt = null;
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  }

  return supportsPWA ? (
    <button
      className="btn btn-sm btn-link px-2"
      id="setup_button"
      aria-label="Install app"
      title="Install app"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faDesktopArrowDown}
        fixedWidth
      />
    </button>
  ) : null;
};

export default InstallPWA;
