import { DeletedPartResult, LibraryPart } from "../../serviceClient/api.dtos";

import { Loading } from "./../common/commonTypes";
import { NestablePart } from "./../nesting/nestingSlice";
import { RootState } from "../../app/store";
import { SortDescriptor } from "../nests/nestsSlice";

export const getCurrentPage = (state: RootState): number =>
  state.libraryPartList.currentPage;

export const getItemsPerPage = (state: RootState): number =>
  state.libraryPartList.itemsPerPage;

export const getPageParts = (state: RootState): NestablePart[] =>
  state.libraryPartList.libraryParts;

export const getTotalParts = (state: RootState): number =>
  state.libraryPartList.totalParts;

export const getPartsListLoading = (state: RootState): Loading =>
  state.libraryPartList.partsLoading;

export const getPartsSort = (state: RootState): SortDescriptor =>
  state.libraryPartList.sort;

export const getClonePartLoading = (state: RootState): Loading =>
  state.libraryPartList.postClonePartLoading;

export const getRotatePartLoading = (state: RootState): Loading =>
  state.libraryPartList.rotatePartLoading;

export const getDeletedPartsLoading = (state: RootState): Loading =>
  state.libraryPartList.deletedPartsLoading;

export const getSourceFileRequestLoading = (state: RootState): Loading =>
  state.libraryPartList.sourceFileRequestLoading;

export const getDeletedPartResult = (state: RootState): DeletedPartResult =>
  state.libraryPartList.deletedPartsResult;

export const getRotatePartResponse = (state: RootState): LibraryPart =>
  state.libraryPartList.rotatePartResponse;

export const getMirrorPartResponse = (state: RootState): LibraryPart =>
  state.libraryPartList.mirrorPartResponse;

export const getSearchTerms = (state: RootState) =>
  state.libraryPartSearch.searchTerms;

export const getLibraryScreenModalOpen = (state: RootState): boolean =>
  state.libraryPartList.libraryScreenModalOpen;

export const getPartFolders = (state: RootState) =>
  state.libraryPartList.partFolders;

export const getPartFoldersLoading = (state: RootState) =>
  state.libraryPartList.partFoldersLoading;

export const getPartFoldersLastPulled = (state: RootState) =>
  state.libraryPartList.partFoldersLastPulled;

export const getSelectedPartFolderId = (state: RootState) =>
  state.libraryPartList.selectedPartFolderId;

export const getSelectedPartFolder = (state: RootState) =>
  state.libraryPartList.selectedPartFolder;

export const getPartFoldersFlattened = (state: RootState) =>
  state.libraryPartList.partFoldersFlattened;

export const getDeleteFolderLoading = (state: RootState) =>
  state.libraryPartList.deleteFolderLoading;

export const getDeleteFolderModalVisibility = (state: RootState) =>
  state.libraryPartList.deleteFolderModalVisibility;

export const getSelectedPartFolderTreeIds = (state: RootState) =>
  state.libraryPartList.selectedPartFolderTreeIds;

export const getLibraryView = (state: RootState) =>
  state.libraryPartList.libraryView;

export const getMovePartsToFolderLoading = (state: RootState) =>
  state.libraryPartList.movePartsToFolderLoading;

export const getMovePartsToFolderModalVisibility = (state: RootState) =>
  state.libraryPartList.movePartsToFolderModalVisibility;

export const getSelectedPartFolderParentTreeIds = (state: RootState) =>
  state.libraryPartList.selectedPartFolderParentTreeIds;

export const getSelectedStaticFolder = (state: RootState) =>
  state.libraryPartList.selectedStaticFolder;

export const getSelectedPartFolderMoveId = (state: RootState) =>
  state.libraryPartList.selectedPartFolderMoveId;

export const getFetchedPartCount = (state: RootState) =>
  state.libraryPartList.fetchedPartCount;

export const getProgressBarVisibility = (state: RootState) =>
  state.libraryPartList.progressBarVisibility;
