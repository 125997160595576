import { DefaultTeamSheet, Loading } from "../common/commonTypes";

import { RootState } from "../../app/store";
import { UserSettingsResponse } from "../../serviceClient/api.dtos";

export const getUserSettings = (state: RootState): UserSettingsResponse =>
  state.settings.userSettings;

export const getDefaultTeamSheets = (state: RootState): DefaultTeamSheet[] =>
  state.settings.userSettings.defaultTeamSheets as DefaultTeamSheet[];

export const getBusyFetchingSettings = (state: RootState): boolean =>
  state.settings.busyFetchingSettings;

export const getDarkMode = (state: RootState): boolean =>
  state.settings.darkMode;

export const getSettingsLoading = (state: RootState): Loading =>
  state.settings.loading;

export const getSaveUserSettingsLoading = (state: RootState) =>
  state.settings.saveUserSettingsLoading;

export const getSettingModalVisibility = (state: RootState) =>
  state.settings.settingsModalVisibility;

export const getSettingsTab = (state: RootState) => state.settings.settingsTab;

export const getSettingsModalShown = (state: RootState) =>
  state.settings.settingsModalShown;
