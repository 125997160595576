import { FunctionComponent, useCallback, useEffect, useRef } from "react";

import { Modal } from "bootstrap";
import { getExampleProjectModalVisibility } from "./nests";
import { setExampleProjectModalVisibility } from "./nestsSlice";
import { useAppDispatch } from "../../app/hooks/reduxTypedHooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ExampleProjectDynamicModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const exampleProjectModalVisibility = useSelector(
    getExampleProjectModalVisibility
  );

  const showModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {
      backdrop: "static",
    });

    bsModal.show();
  }, []);

  const hiddenModal = useCallback(() => {
    dispatch(setExampleProjectModalVisibility(false));
  }, [dispatch]);

  const hideModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);

    bsModal?.hide();
  }, []);

  useEffect(() => {
    const modalEle = modalRef.current;
    if (modalEle) {
      modalEle.addEventListener("hidden.bs.modal", hiddenModal);

      window.addEventListener("popstate", hideModal);
    }

    if (exampleProjectModalVisibility) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      modalEle && modalEle.removeEventListener("hidden.bs.modal", hiddenModal);

      window.removeEventListener("popstate", hideModal);
    };
  }, [
    dispatch,
    exampleProjectModalVisibility,
    hiddenModal,
    hideModal,
    showModal,
  ]);

  return exampleProjectModalVisibility ? (
    <div
      ref={modalRef}
      className="modal fade"
      id="exampleProjectModal"
      tabIndex={-1}
      aria-labelledby="exampleProjectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content theme-welcome-modal border-none shadow">
          <div className="modal-body text-white">
            <div className="container">
              <div className="row">
                <h4
                  className="mb-4"
                  id="exampleProjectModalLabel"
                >
                  {t("exampleProjectModal.h1")}
                </h4>
                <div className="col-12">
                  <div className="mb-4">
                    <p>{t("exampleProjectModal.p1")}</p>
                    <p>{t("exampleProjectModal.p2")}</p>
                    <p>{t("exampleProjectModal.p3")}</p>
                    <p>{t("exampleProjectModal.p4")}</p>
                    <p>{t("exampleProjectModal.p5")}</p>
                    <p className="py-2">{t("exampleProjectModal.p6")}</p>
                    <p
                      className="h2"
                      style={{ fontFamily: "Brush Script MT" }}
                    >
                      {t("exampleProjectModal.p7")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer p-2 border-0">
            <button
              type="button"
              className="btn fw-bold btn-light text-efficient btn-sm"
              onClick={hideModal}
            >
              {t("exampleProjectModal.getStarted")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ExampleProjectDynamicModal;
