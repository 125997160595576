import {
  CSVGetHeaders,
  CSVHeaderResponse,
  CSVPartResponse,
  PartsFromCSV,
} from "../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";

type CSVType = "sheets" | "parts" | "";

type CSVModal = {
  type: CSVType;
  visible: boolean;
};

type ImportCSVState = {
  csvHeaders: CSVHeaderResponse;
  csvHeadersLoading: Loading;
  csvPartsAndAlerts: CSVPartResponse;
  csvPartsAndAlertsLoading: Loading;
  importCSVModalVisibility: CSVModal;
  csvFile: string;
};

const initialState: ImportCSVState = {
  csvHeaders: new CSVHeaderResponse(),
  csvHeadersLoading: "idle",
  csvPartsAndAlerts: new CSVPartResponse({ parts: [], alerts: [] }),
  csvPartsAndAlertsLoading: "idle",
  importCSVModalVisibility: { type: "", visible: false },
  csvFile: "",
};

export const fetchCSVHeaders = createAsyncThunk(
  "importCSV/fetchCSVHeaders",
  async (file: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new CSVGetHeaders({ file }))
        .then((csvHeaders: CSVHeaderResponse) => {
          return csvHeaders;
        });
    });
  }
);

export const fetchPartsFromCSV = createAsyncThunk(
  "importCSV/fetchPartsFromCSV",
  async ({ file, assignments }: Partial<PartsFromCSV>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new PartsFromCSV({ file, assignments }))
        .then((csvPart: CSVPartResponse) => {
          return csvPart;
        });
    });
  }
);

const importCSVSlice = createSlice({
  name: "importCSV",
  initialState,
  reducers: {
    setImportCSVModalVisibility: (state, action: PayloadAction<CSVModal>) => {
      state.importCSVModalVisibility = action.payload;
    },
    setCsvPartsAndAlerts: (state, action: PayloadAction<CSVPartResponse>) => {
      state.csvPartsAndAlerts = action.payload;
    },
  },
  extraReducers: (builder) => {
    // POST: /csv/headers
    builder.addCase(fetchCSVHeaders.pending, (state, args) => {
      state.csvFile = args.meta.arg;
      state.csvHeadersLoading = "pending";
    });
    builder.addCase(fetchCSVHeaders.fulfilled, (state, action) => {
      state.csvHeadersLoading = "succeeded";
      state.csvHeaders = action.payload;
    });
    builder.addCase(fetchCSVHeaders.rejected, (state) => {
      state.csvHeadersLoading = "failed";
    });
    // POST: /csv/parts
    builder.addCase(fetchPartsFromCSV.pending, (state) => {
      state.csvPartsAndAlertsLoading = "pending";
    });
    builder.addCase(fetchPartsFromCSV.fulfilled, (state, action) => {
      state.csvPartsAndAlertsLoading = "succeeded";
      state.csvPartsAndAlerts = action.payload;
    });
    builder.addCase(fetchPartsFromCSV.rejected, (state) => {
      state.csvPartsAndAlertsLoading = "failed";
    });
  },
});

export const { setImportCSVModalVisibility, setCsvPartsAndAlerts } =
  importCSVSlice.actions;

export default importCSVSlice.reducer;
