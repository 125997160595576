import {
  CompleteSessionRequest,
  CreateFileSettings,
  CreateImportSession,
  DeleteFileIdentity,
  ModelRetrievedResult,
  UpdateFileSettings,
  UpdateModelSettings,
} from "../../serviceClient/api.dtos";
import {
  ImporterFile,
  ImporterFileLayer,
  ImporterFileResult,
  ImporterFileSettingsArgs,
  ImporterFileStatus,
} from "./importerTypes.dtos";
import {
  ImporterState,
  checkIfPartNamesExist,
  completeSessionRequest,
  createFileSettings,
  createImportSession,
  deleteFileIdentity,
  disposeImporterState,
  fetchLayerAssignments,
  removeFiles,
  removeLayers,
  removeModels,
  removeParts,
  setBusyFinalisingSession,
  setDuplicatePartNamesModalOpen,
  setFileSettings,
  setFiles,
  setImportTimerModalVisibility,
  setImporterPartModalOpen,
  setLayers,
  setResults,
  setSession,
  setSessionRequestActive,
  updateFileSettings,
  updateModelSettings,
} from "./importerSlice";
import {
  NestableSheet,
  addOnTheFlyPartsToNest,
  addOnTheFlySheetsToNest,
} from "../nesting/nestingSlice";

import { AppThunk } from "../../app/store";
import { NavigateFunction } from "react-router-dom";
import ServerEventsImport from "../../serviceClient/ESServiceClient/ServerEventsImporter";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { guid } from "../../Util";
import i18n from "../../language/i18next";
import { t } from "i18next";
import { updateCurrentPage } from "../libraryPartList/libraryPartListSlice";

export const deleteFiles =
  (files: Partial<ImporterFile>[]): AppThunk =>
  async (dispatch, getState) => {
    const importerState = getState().importer;

    const fileIdsToRemove = files.map((fileToRemove) => fileToRemove.fileId);

    fileIdsToRemove.map((id) => {
      return dispatch(
        deleteFileIdentity({
          fileId: id,
          sessionId: getState().importer.session.sessionId,
        } as DeleteFileIdentity)
      );
    });

    dispatch(
      removeFiles(
        importerState.files.filter((stateFile) =>
          fileIdsToRemove.includes(stateFile.fileId)
        )
      )
    );

    const resultIdsToRemove = importerState.results
      .filter((result) => fileIdsToRemove.includes(result.fileId))
      .map((result) => result.uiIdentifier);

    dispatch(
      setResults(
        importerState.results.filter(
          (result) => !resultIdsToRemove.includes(result.uiIdentifier)
        )
      )
    );

    dispatch(
      removeParts(
        importerState.parts.filter((part) =>
          resultIdsToRemove.includes(part.resultId)
        )
      )
    );

    const modelsToRemove = importerState.models.filter((model) =>
      fileIdsToRemove.includes(model.fileId)
    );
    const modelIdsToRemove = modelsToRemove.map((model) => model.uiIdentifier);

    dispatch(removeModels(modelsToRemove));

    dispatch(
      removeLayers(
        importerState.layers.filter((layer) =>
          modelIdsToRemove.includes(layer.modelId)
        )
      )
    );
  };

// Units and Scale Change
export const updateFileSetting =
  (fileSettings: ImporterFileSettingsArgs[]): AppThunk =>
  async (dispatch, getState) => {
    const lastState = getState();
    const files = lastState.importer.files;
    const oldFileSettings = lastState.importer.fileSettings;

    fileSettings.forEach(async (settings) => {
      const previousSetting = oldFileSettings.find(
        (fileSetting) => fileSetting.fileId === settings.fileId
      );

      if (
        typeof previousSetting !== "undefined" &&
        JSON.stringify(previousSetting) === JSON.stringify(settings)
      ) {
        return;
      }

      dispatch(updateFileStatus(settings.fileId!, true));

      const uGuid = guid();

      const toUpdate = files.map((file) => ({
        ...file,
        status: file.selected
          ? ImporterFileStatus.FILE_STATUS_PROCESSING
          : file.status,
        uniqueRequestId:
          file.fileId === settings.fileId ? uGuid : file.uniqueRequestId,
      }));

      dispatch(setFiles(toUpdate));

      await dispatch(
        updateFileSettings({
          fileId: settings.fileId,
          uniqueRequestId: uGuid,
          fileSettings: {
            drawingScale: settings.drawingScale!,
            measurementUnit: settings.measurementUnits,
          },
          sessionId: getState().importer.session.sessionId,
        } as UpdateFileSettings)
      )
        .unwrap()
        .then(() => {
          // updating the files state can clash with the retrieveResultIdentity calls coming back from the server. That calls updateFileStatus which updates the files state but does not set the new  uniqueReferenceRequest guid

          dispatch(setFiles(toUpdate));
        })
        .catch(() => {
          //before setting file in state we need to pass the uniqueReferenceRequest when using updateFileStatus

          dispatch(
            addToastMessage({
              id: guid(),
              text: t("toastMessages.updateFileSettingsError"),
              severity: "danger",
            })
          );
        });
    });

    dispatch(setFileSettings(fileSettings));
  };

// Show timer if on importer page or modal
export const showImporterTimerModal =
  (show: boolean): AppThunk =>
  (dispatch, getState) => {
    const {
      importer: { importerPartModalOpen, importTimerModalVisibility },
    } = getState();

    const location = window.location;

    if (
      !importTimerModalVisibility &&
      (location.pathname ===
        i18n.language + "/" + t("paths.library") + "/" + t("paths.importer") ||
        importerPartModalOpen)
    ) {
      dispatch(setImportTimerModalVisibility(show));
    }
  };

export const updateFileStatus =
  (fileId: string, newRequest = false, clearWaiting = false): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().importer;

    const targetFile = state.files.find((file) => file.fileId === fileId);
    const targetFileModel = state.models.find(
      (fileModel) => fileModel.fileId === fileId
    );
    const targetFileResult = state.results.find(
      (fileResult) => fileResult.fileId === fileId
    );

    if (typeof targetFile !== "undefined") {
      let activeRequestCount = 0;

      if (newRequest) {
        activeRequestCount =
          typeof targetFile.activeRequests !== "undefined"
            ? newRequest
              ? targetFile.activeRequests + 1
              : targetFile.activeRequests - 1
            : newRequest
            ? 1
            : 0;

        activeRequestCount = activeRequestCount < 0 ? 0 : activeRequestCount; // Negative to zero.
      }

      const awaitingUpdate = newRequest || !clearWaiting;

      if (
        typeof targetFileModel !== "undefined" &&
        typeof targetFileResult !== "undefined"
      ) {
        const newState =
          activeRequestCount > 0 || targetFile.awaitingUpdate
            ? ImporterFileStatus.FILE_STATUS_PROCESSING
            : ImporterFileStatus.FILE_STATUS_COMPLETE;

        dispatch(
          setFiles([
            {
              // ...targetFile,
              fileId,
              activeRequests: activeRequestCount,
              awaitingUpdate,
              status: newState,
            },
          ])
        );
      } else {
        dispatch(
          setFiles([
            {
              //...targetFile,
              fileId,
              activeRequests: activeRequestCount,
              awaitingUpdate,
              status: ImporterFileStatus.FILE_STATUS_DOWNLOADING,
            },
          ])
        );
      }
    }
  };

export const updateGroupPartAsDrawn =
  (groupPartAsDrawn: boolean, fileId: string): AppThunk =>
  (dispatch, getState) => {
    const layers = getState().importer.layers;
    const fileModels = getState().importer.models;
    const sessionId = getState().importer.session.sessionId;
    const files = getState().importer.files;
    const updateModelSettingsRequest = new UpdateModelSettings();

    const modelUiIdentifier = fileModels.find(
      (fileModel) => fileModel.fileId === fileId
    )?.uiIdentifier;

    const selectedLayers = layers.filter(
      (layer) => layer.modelId === modelUiIdentifier
    );

    updateModelSettingsRequest.fileId = fileId;
    updateModelSettingsRequest.sessionId = sessionId;
    updateModelSettingsRequest.groupPartAsDrawn = groupPartAsDrawn;
    updateModelSettingsRequest.layerChanges = selectedLayers;
    updateModelSettingsRequest.uniqueRequestId = guid();
    dispatch(updateFileStatus(updateModelSettingsRequest.fileId, true));
    dispatch(updateModelSettings(updateModelSettingsRequest))
      .unwrap()
      .then(() => {
        const newFiles = files.map((file) =>
          file.fileId === fileId
            ? { ...file, groupPartAsDrawn: groupPartAsDrawn }
            : file
        );
        dispatch(setFiles(newFiles));
        dispatch(updateFileStatus(updateModelSettingsRequest.fileId, true));
      })
      .catch(() => {
        //Reset if fail
        const newFilesFailed = files.map((file) =>
          file.fileId === fileId
            ? { ...file, groupPartAsDrawn: !groupPartAsDrawn }
            : file
        );

        dispatch(setFiles(newFilesFailed));
        dispatch(updateFileStatus(updateModelSettingsRequest.fileId, false));
        dispatch(
          addToastMessage({
            id: guid(),
            severity: "danger",
            text: t("toastMessages.updateModelSettingsError"),
          })
        );
      });
  };

export const updateLayers =
  (layers: Partial<ImporterFileLayer>[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().importer;

    const files = getState().importer.files;

    const stateLayers = getState().importer.layers;

    const extantLayerIds = stateLayers.map(
      (extantLayer) => extantLayer.uiIdentifier
    );

    const updatedLayers = stateLayers
      .map((extantLayer) => ({
        ...extantLayer,
        ...layers.find(
          (newLayer) => newLayer.uiIdentifier === extantLayer.uiIdentifier
        ),
      }))
      .map(
        (newLayer) =>
          ({
            ...newLayer,
          } as ImporterFileLayer)
      );

    dispatch(
      setLayers([
        ...updatedLayers,
        ...layers
          .filter(
            (newLayer) =>
              typeof newLayer.uiIdentifier !== "undefined" &&
              !extantLayerIds.includes(newLayer.uiIdentifier)
          )
          .map(
            (newLayer) =>
              ({
                ...newLayer,
              } as ImporterFileLayer)
          ),
      ])
    );

    const stateModels = state.models;

    const possiblyModifiedFiles = updatedLayers.map((newLayer) => ({
      fileId: (
        stateModels.find(
          (fileModel) => fileModel.uiIdentifier === newLayer.modelId
        ) as ModelRetrievedResult
      ).fileId,
      layer: {
        ...newLayer,
        ...layers.find((layer) => layer.uiIdentifier === newLayer.uiIdentifier),
      },
    }));

    if (possiblyModifiedFiles.length > 0) {
      const modifiedFiles: {
        fileId: string;
        mappedLayers: any;
      }[] = Object.entries(
        possiblyModifiedFiles.reduce((acc, cur) => {
          acc[cur.fileId] = (acc[cur.fileId] || []).concat(cur.layer);
          return acc;
        }, {})
      ).map(([fileId, mappedLayers]) => ({
        fileId,
        mappedLayers,
      }));

      modifiedFiles.forEach(async (modifiedFile) => {
        const layerUpdateRequest = new UpdateModelSettings();

        const originalLayersForFileJSON = JSON.stringify(
          state.layers
            .filter(
              (oldLayer) =>
                oldLayer.modelId === modifiedFile.mappedLayers[0].modelId
            )
            .sort((layerA, layerB) =>
              ("" + layerA.uiIdentifier).localeCompare(layerB.uiIdentifier)
            )
        );

        const newLayersFileFileJSON = JSON.stringify(
          modifiedFile.mappedLayers.sort((layerA, layerB) =>
            ("" + layerA.uiIdentifier).localeCompare(layerB.uiIdentifier)
          )
        );

        if (originalLayersForFileJSON === newLayersFileFileJSON) {
          return;
        }

        layerUpdateRequest.sessionId = state.session.sessionId;
        layerUpdateRequest.fileId = modifiedFile.fileId;
        layerUpdateRequest.uniqueRequestId = guid();
        layerUpdateRequest.layerChanges = modifiedFile.mappedLayers.map(
          (mappedLayer: ImporterFileLayer) => {
            const oldLayer = stateLayers.find(
              (fileLayer) => fileLayer.uiIdentifier === mappedLayer.uiIdentifier
            );
            return { ...oldLayer, ...mappedLayer };
          }
        );

        dispatch(
          setFiles(
            files.map((file) => ({
              ...file,
              uniqueRequestId:
                file.fileId === layerUpdateRequest.fileId
                  ? layerUpdateRequest.uniqueRequestId
                  : file.uniqueRequestId,
              status:
                file.fileId === layerUpdateRequest.fileId
                  ? ImporterFileStatus.FILE_STATUS_PROCESSING
                  : file.status,
            }))
          )
        );

        await dispatch(updateModelSettings(layerUpdateRequest))
          .unwrap()
          .then(() => {})
          .catch(() => {
            addToastMessage({
              id: guid(),
              severity: "danger",
              text:
                files.find((file) => file.fileId === layerUpdateRequest.fileId)
                  ?.fileName ?? "",
            });
          });
      });
    }
  };

export const updateModelLayersMatch =
  (file: any): AppThunk =>
  (_, getState) => {
    const stateLayers = getState().importer.layers;

    //get file id from file
    const newLayers = file.generatedUsingLayers.map((layer: any) => ({
      ...layer,
      fileId: file.fileId,
    }));

    // If a file has been uploaded, but the data has not been imported yet, the fileId will be in the list of files that have been uploaded, but not imported.
    // We get the uniqueRequestId from the list of files that have been uploaded, but not imported.
    // If the uniqueRequestId is undefined, the data has not been uploaded yet.
    const uniqueRequestId = getState().importer.files.find(
      (f) => f.fileId === file.fileId
    )?.uniqueRequestId;

    // Note: do compare against returned uniqueRequestId, it has now become the fileId.
    // Use the uniqueRequestIdReceived to compare against the uniqueRequestId

    // Checks whether the uniqueRequestId is equal to the file's uniqueReferenceRequest. If they are equal, it sets the importerLayerMatch to true.
    // Then we retrieveResultIdentity

    if (uniqueRequestId === file.uniqueReferenceRequest) {
      return true;
    }

    /*
      This code checks to see if the layer names, colours, line types, and instructions in the state match the layer names, colours, line types, and instructions in the new layers. If they do, then it returns true. If they don't, then it returns false.
    */

    // Check each layer in the state against the corresponding layer in the file

    const test = stateLayers.every((layer) => {
      // Find the corresponding layer in the file
      const layerMatch = newLayers.find(
        (fileLayer: any) =>
          fileLayer.fileId === layer.fileId &&
          fileLayer.layerName === layer.layerName &&
          fileLayer.colourHex === layer.colourHex &&
          fileLayer.lineType === layer.lineType
      );

      // If no layer is found, then the state has a layer that is not in the file
      if (layerMatch == null) {
        return true;
      }

      // Check if the layer has the same instruction name
      return layerMatch?.instruction?.name === layer.instruction.name;
    });

    return test;
  };

export const uploadFiles =
  (files: File[]): AppThunk =>
  async (dispatch, getState) => {
    let newFiles: Partial<ImporterFile>[] = [];
    let updateErrorFile: Partial<ImporterFile>[] = [];
    for (const file of files) {
      try {
        const uploadFileRequest: CreateFileSettings = new CreateFileSettings({
          file: (await readAsDataURL(file)) as string,
          fileName: file.name,
          fileReference: guid(),
          sessionId: getState().importer.session.sessionId,
          uniqueRequestId: guid(),
        });

        const sizeInMb = getState().settings.userSettings.maxFilesizeMb;

        const fileExt =
          file.name.split(".").pop()?.toString().toLowerCase() ?? "";

        if (!["dxf", "dwg"].includes(fileExt)) {
          dispatch(
            addToastMessage({
              id: uploadFileRequest.fileReference,
              severity: "danger",
              text: t("toastMessages.fileNotSupportedError", {
                context: file.name,
              }),
            })
          );

          //Add oversized file to list of files
          newFiles.push({
            fileId: uploadFileRequest.fileReference,
            fileName: file.name,
            reference: uploadFileRequest.fileReference,
            status: ImporterFileStatus.FILE_STATUS_TOO_LARGE,
          });

          continue;
        }

        const fileSize = file.size / 1024 / 1024;

        if (fileSize >= sizeInMb) {
          dispatch(
            addToastMessage({
              id: uploadFileRequest.fileReference,
              severity: "danger",
              text: t("toastMessages.fileTooLargeError", {
                name: file.name,
                count: sizeInMb,
              }),
              timeout: 12000,
            })
          );

          //Add oversized file to list of files
          newFiles.push({
            fileId: uploadFileRequest.fileReference,
            fileName: file.name,
            reference: uploadFileRequest.fileReference,
            status: ImporterFileStatus.FILE_STATUS_TOO_LARGE,
          });

          continue;
        }

        //Upload files
        if (!uploadFileRequest.sessionId) {
          // TODO: Requeue request.
        } else {
          newFiles.push({
            fileName: file.name,
            reference: uploadFileRequest.fileReference,
            status: ImporterFileStatus.FILE_STATUS_UPLOADING,
          });

          dispatch(createFileSettings(uploadFileRequest))
            .unwrap()
            .then((uploadResponse) => {
              dispatch(
                setFiles([
                  {
                    fileId: uploadResponse.fileId,
                    fileName: file.name,
                    reference: uploadFileRequest.fileReference,
                    uniqueRequestId: uploadFileRequest.uniqueRequestId,
                  },
                ])
              );
            })
            .catch((e) => {
              dispatch(
                setFiles([
                  {
                    fileId: uploadFileRequest.fileReference,
                    fileName: file.name,
                    reference: uploadFileRequest.fileReference,
                    uniqueRequestId: uploadFileRequest.uniqueRequestId,
                    status: ImporterFileStatus.FILE_STATUS_ERROR,
                  },
                ])
              );

              dispatch(
                addToastMessage({
                  id: uploadFileRequest.fileReference,
                  severity: "danger",
                  text: t("toastMessages.createFileSettingsError", {
                    name: file.name,
                    error: e?.responseStatus?.message ?? "",
                  }),
                })
              );

              //TODO: on 401 error files seem to be left on processing

              updateErrorFile.push(
                Object.assign(
                  newFiles.find(
                    (file) => file.reference === uploadFileRequest.fileReference
                  ) ?? {},
                  {
                    status: ImporterFileStatus.FILE_STATUS_ERROR,
                  }
                )
              );
            });
        }
      } catch {
        dispatch(
          addToastMessage({
            id: file.name,
            severity: "warning",
            text: t("toastMessages.uploadError"),
          })
        );
      }
    }

    const concatFiles = [
      ...newFiles.filter((file) =>
        updateErrorFile.every((errored) => errored.reference !== file.reference)
      ),
      ...updateErrorFile,
    ];

    dispatch(setFiles(concatFiles));
  };

export const newImporterSession =
  (): AppThunk => async (dispatch, getState) => {
    if (getState().importer.sessionRequestActive) {
      return;
    }

    dispatch(setSessionRequestActive(true));

    const newImporterSessionRequest: CreateImportSession =
      new CreateImportSession();

    //

    dispatch(createImportSession(newImporterSessionRequest))
      .unwrap()
      .then((importSessionResult) => {
        dispatch(setSession(importSessionResult));
        dispatch(fetchLayerAssignments());

        const serverEventsImport = new ServerEventsImport();
        serverEventsImport.setSessionId(importSessionResult.sessionId);
        serverEventsImport.setDispatch(dispatch);

        //Signalr connect

        //Attach instance to window so we can abort
        window.ServerEventImporterInstance = serverEventsImport;
      })
      .catch(() => {
        dispatch(
          addToastMessage({
            id: guid(),
            severity: "warning",
            text: t("toastMessages.creatingImportSessionError"),
          })
        );
      });

    dispatch(setSessionRequestActive(false));
  };

export const updateResults =
  (results: Partial<ImporterFileResult>[]): any =>
  async (dispatch, getState) => {
    const importerState = getState().importer;

    const extantResultIds = importerState.results.map(
      (extantModel) => extantModel.uiIdentifier
    );

    const updatedResults = importerState.results.map((extantResult) => ({
      ...extantResult,
      ...results.find(
        (newResult) => newResult.uiIdentifier === extantResult.uiIdentifier
      ),
    }));

    const existingResults = [
      ...results
        .filter(
          (newResult) =>
            typeof newResult.uiIdentifier === "undefined" ||
            !extantResultIds.includes(newResult.uiIdentifier)
        )
        .map(
          (newResult) =>
            ({
              ...newResult,
            } as ImporterFileResult)
        ),
    ];

    dispatch(setResults([...updatedResults, ...existingResults]));
  };

export const disposeSession =
  (isOnFlyModeSession = false, navigate: NavigateFunction): AppThunk =>
  (dispatch) => {
    dispatch(disposeImporterState());
    navigate(
      isOnFlyModeSession
        ? "/" + i18n.language + "/" + t("paths.nesting")!
        : "/" + i18n.language + "/" + t("paths.library")!
    );
  };

export const finaliseSession =
  (
    isOnFlyModeSession = false,
    navigate: NavigateFunction,
    finish = false
  ): AppThunk =>
  async (dispatch, getState) => {
    const importerState = getState().importer as ImporterState;
    const libraryState = getState().libraryPartList;

    const filteredImportedFiles = importerState.files.filter(
      (file) => file.status !== ImporterFileStatus.FILE_STATUS_TOO_LARGE
    );

    if (
      !filteredImportedFiles.every(
        (file) => file.status === ImporterFileStatus.FILE_STATUS_COMPLETE
      )
    ) {
      const toastId = "ALLFILESNOTREADY";

      dispatch(
        addToastMessage({
          id: toastId,
          severity: "danger",
          text: t("toastMessages.someFilesNotReadyError"),
        })
      );

      return;
    }

    if (!finish) {
      if (!isOnFlyModeSession && importerState.parts.length > 0) {
        //Checks for duplicate names
        const names = Array.from(
          new Set(
            importerState.parts.flatMap((a) => (a.isSelected ? a.name : []))
          )
        );

        dispatch(checkIfPartNamesExist({ names, navigate }))
          .unwrap()
          .then((parts) => {
            if (parts.length > 0) {
              dispatch(setDuplicatePartNamesModalOpen(true));

              return;
            } else {
              dispatch(setBusyFinalisingSession(true));

              const fileObjects = filteredImportedFiles.map((file) => {
                // Get the result ID of the file
                const resultId = importerState.results.find(
                  (result) => result.fileId === file.fileId
                )!.uiIdentifier;

                // Get the parts for the file
                const parts = importerState.parts
                  .filter((part) => part.resultId === resultId)
                  .map((part) => ({
                    id: part.partId,
                    isSelected: part.isSelected,
                    name: part.name,
                    quantity: part.quantity,
                    priority: part.priority,
                  }));

                return {
                  fileId: file.fileId,
                  uniqueId: file.uniqueRequestId!,
                  parts: parts,
                };
              });

              // Remove duplicates from fileObjects
              const set = new Set();
              const uniqueFileObjects = fileObjects.filter((item) => {
                const duplicate: boolean = set.has(item.fileId);
                set.add(item.fileId);
                return !duplicate;
              });

              dispatch(
                completeSessionRequest(
                  new CompleteSessionRequest({
                    folderId: libraryState.selectedPartFolderId,
                    fileOptions: uniqueFileObjects,
                    isOnFlyModeSession,
                    sessionId: importerState.session.sessionId,
                  })
                )
              )
                .unwrap()
                .then((finaliseResult) => {
                  dispatch(disposeSession(isOnFlyModeSession, navigate));

                  if (isOnFlyModeSession) {
                    dispatch(
                      addOnTheFlyPartsToNest(finaliseResult.nestingParts)
                    );
                  }

                  // Force library to update.
                  dispatch(updateCurrentPage(1));

                  if (isOnFlyModeSession) {
                    return dispatch(setImporterPartModalOpen(false));
                  } else {
                    return navigate(
                      "/" + i18n.language + "/" + t("paths.library")!
                    );
                  }
                })
                .catch(() => {
                  dispatch(setBusyFinalisingSession(false));
                  dispatch(
                    addToastMessage({
                      id: guid(),
                      severity: "warning",
                      text: t("toastMessages.completingSessionRequestError"),
                    })
                  );
                });
            }
          })
          .catch(() => {
            dispatch(
              addToastMessage({
                id: guid(),
                severity: "warning",
                text: t("toastMessages.checkingNameError"),
              })
            );
          });
      } else {
        //is On the Fly
        dispatch(setBusyFinalisingSession(true));

        dispatch(
          completeSessionRequest(
            new CompleteSessionRequest({
              fileOptions: filteredImportedFiles.map((file) => {
                const resultId = importerState.results.find(
                  (result) => result.fileId === file.fileId
                )!.uiIdentifier;

                return {
                  uniqueId: file.uniqueRequestId!,
                  fileId: file.fileId!,
                  parts: importerState.parts
                    .filter((part) => part.resultId === resultId)
                    .map((part) => {
                      return {
                        id: part.partId,
                        isSelected: part.isSelected,
                        name: part.name,
                        quantity: part.quantity,
                        priority: part.priority,
                      };
                    }),
                };
              }),
              isOnFlyModeSession,
              sessionId: importerState.session.sessionId,
            })
          )
        )
          .unwrap()
          .then((finaliseResult) => {
            dispatch(disposeSession(isOnFlyModeSession, navigate));

            if (importerState.importerType === "parts") {
              if (isOnFlyModeSession) {
                dispatch(addOnTheFlyPartsToNest(finaliseResult.nestingParts));
              }

              // Force library to update.
              dispatch(updateCurrentPage(1));
              if (isOnFlyModeSession) {
                return dispatch(setImporterPartModalOpen(false));
              } else {
                return navigate(
                  "/" + i18n.language + "/" + t("paths.library")!
                );
              }

              // Sheets =======================================
            } else if (importerState.importerType === "sheets") {
              //Add to sheets

              dispatch(
                addOnTheFlySheetsToNest(
                  finaliseResult.nestingParts as NestableSheet[]
                )
              );

              dispatch(setImporterPartModalOpen(false));
            }
          })
          .catch(() => {
            dispatch(
              addToastMessage({
                id: guid(),
                severity: "warning",
                text: t("toastMessages.completingSessionRequestError"),
              })
            );

            dispatch(setImporterPartModalOpen(false));
          });
      }
    }
  };

const readAsDataURL = (inputFile: File) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => resolve(temporaryFileReader.result);

    temporaryFileReader.readAsDataURL(inputFile);
  });
};
