import apiErrorSlice from "../features/apiError/apiErrorSlice";
import { combineReducers } from "redux";
import commonSlice from "../features/common/commonSlice";
import efficientRoutingSlice from "../features/efficientRouting/efficientRoutingSlice";
import feedbackSlice from "../features/feedback/feedbackSlice";
import importCSVSlice from "../features/importCSV/importCSVSlice";
import importerSlice from "../features/importer/importerSlice";
import landingSlice from "../features/landing/landingSlice";
import libraryPartListSlice from "../features/libraryPartList/libraryPartListSlice";
import libraryPartSearchSlice from "../features/libraryPartSearch/libraryPartSearchSlice";
import nestingSlice from "../features/nesting/nestingSlice";
import nestsSlice from "../features/nests/nestsSlice";
import notesSlice from "../features/notes/notesSlice";
import parametricSlice from "../features/libraryPartList/parametric/ParametricSlice";
import paymentSlice from "../features/payment/paymentSlice";
import settingsSlice from "../features/settings/settingsSlice";
import teamsSlice from "../features/teams/teamsSlice";
import toastMessagesSlice from "../features/toastMessages/toastMessagesSlice";

export const createRootReducer = () => {
  return combineReducers({
    apiError: apiErrorSlice,
    common: commonSlice,
    efficientRouting: efficientRoutingSlice,
    feedback: feedbackSlice,
    importCSV: importCSVSlice,
    importer: importerSlice,
    landing: landingSlice,
    libraryPartList: libraryPartListSlice,
    libraryPartSearch: libraryPartSearchSlice,
    nesting: nestingSlice,
    nests: nestsSlice,
    notes: notesSlice,
    payment: paymentSlice,
    settings: settingsSlice,
    teams: teamsSlice,
    toastMessages: toastMessagesSlice,
    parametric: parametricSlice,
  });
};
