import {
  setProductAction,
  setSelectedProduct,
  setSelectedProductId,
} from "../../features/payment/paymentSlice";
import { useAppDispatch, useAppSelector } from "./reduxTypedHooks";

import { PaymentPlanTypes } from "../../serviceClient/api.dtos";
import { getAvailablePlans } from "../../features/payment/payment";
import { setProductActionModalOpen } from "../../features/common/commonSlice";

function useUpgrade() {
  const dispatch = useAppDispatch();
  const plans = useAppSelector(getAvailablePlans);

  function upgrade() {
    const upgradeToBusiness = plans.find(
      (plan) =>
        plan.planType.toString() ===
        PaymentPlanTypes[PaymentPlanTypes.Business].toString()
    );

    if (upgradeToBusiness) {
      window.Paddle.Product.Prices(
        upgradeToBusiness.overrideId ?? upgradeToBusiness.id,
        1,
        (data) => {
          dispatch(setSelectedProduct(data.price));
        }
      );
      dispatch(setSelectedProductId(upgradeToBusiness.id));
      dispatch(setProductAction("Upgrade Plan"));
      dispatch(setProductActionModalOpen(true));
    }
  }

  return {
    upgradeAccount: upgrade,
  };
}

export default useUpgrade;
