import { FunctionComponent, ReactNode } from "react";

import DropdownNav from "./DropdownNav";
import { Link } from "react-router-dom";
import MobileNav from "./MobileNav";
import WarningBanner from "../common/WarningBanner";
import { getDarkMode } from "../settings/settings";
import { getEfficientRouting } from "../efficientRouting/efficientRouting";
import logoDark from "../../image/my-nesting-logo-dark.svg";
import logoLight from "../../image/my-nesting-logo.svg";
import { useAppSelector } from "../../app/hooks/reduxTypedHooks";

const AppFrame: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const darkMode = useAppSelector(getDarkMode);

  const efficientRoutingState = useAppSelector(getEfficientRouting);

  // To allow users to go from shop back to site if they are cancelled trial
  function handleReload(e) {
    e.stopPropagation();
    if (efficientRoutingState.productScreen) {
      window.location.reload();
    }
  }

  return (
    <>
      <header className="row gx-3">
        {/* Logo */}
        <div className="d-flex col flex-xl-grow-0">
          <Link
            to={"/"}
            className="position-relative d-inline-block"
            onClick={handleReload}
          >
            <img
              src={darkMode ? logoDark : logoLight}
              alt={"MyNesting Online"}
              width={237}
              height={72}
            />
          </Link>
        </div>

        {/* Mobile NAV ====================== */}
        <MobileNav />
        {/* Main Nav ======================== */}
        <DropdownNav />
      </header>
      <WarningBanner />
      {/* Pages */}
      <div className="row flex-grow-1 flex-column flex-xl-row">{children}</div>
    </>
  );
};

export default AppFrame;
