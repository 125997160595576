import { RootState } from "../../app/store";

export const getTeamsStatus = (state: RootState) => state.teams.teamStatus;

export const getTeamsStatusLoading = (state: RootState) =>
  state.teams.teamStatusLoading;

export const getRemoveMemberModalVisibility = (state: RootState) =>
  state.teams.removeMemberModalVisibility;

export const getRemoveMemberLoading = (state: RootState) =>
  state.teams.removeMemberLoading;

export const getRescindInviteLoading = (state: RootState) =>
  state.teams.rescindInviteLoading;

export const getSelectedMember = (state: RootState) =>
  state.teams.selectedMember;

export const getTeamInviteLoading = (state: RootState) =>
  state.teams.teamInviteLoading;

export const getInviteModalVisibility = (state: RootState) =>
  state.teams.inviteModalVisibility;

export const getPendingInvite = (state: RootState) => state.teams.pendingInvite;

export const getPendingInvitesLoading = (state: RootState) =>
  state.teams.pendingInvitesLoading;

export const getApproveInviteLoading = (state: RootState) =>
  state.teams.approveInviteLoading;

export const getRejectInviteLoading = (state: RootState) =>
  state.teams.rejectInviteLoading;
