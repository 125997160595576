import {
  ImporterFile,
  ImporterFileLayer,
  ImporterFileModel,
  ImporterFilePart,
  ImporterFileResult,
  ImporterFileSettings,
  ImporterSettings,
  ImporterType,
} from "./importerTypes.dtos";

import { Assignment } from "../../serviceClient/api.dtos";
import { Loading } from "./../common/commonTypes";
import { RootState } from "../../app/store";

export const getFiles = (state: RootState): ImporterFile[] =>
  state.importer.files;

export const getFileSettings = (state: RootState): ImporterFileSettings[] =>
  state.importer.fileSettings;

export const getLayers = (state: RootState): ImporterFileLayer[] =>
  state.importer.layers;

export const getModels = (state: RootState): ImporterFileModel[] =>
  state.importer.models;

export const getParts = (state: RootState): ImporterFilePart[] =>
  state.importer.parts;

export const getResults = (state: RootState): ImporterFileResult[] =>
  state.importer.results;

export const getSession = (state: RootState) => state.importer.session;

export const getImporterSettings = (state: RootState): ImporterSettings =>
  state.importer.settings;

export const getBusyFinalisingSession = (state: RootState): boolean =>
  state.importer.busyFinalisingSession;

export const getLayerAssignments = (state: RootState): Assignment[] =>
  state.importer.layerAssignments;

export const getImportSessionDirty = (state: RootState): boolean =>
  state.importer.importSessionDirty;

export const getImporterLobbyOpen = (state: RootState): boolean =>
  state.importer.duplicatePartNamesModalOpen;

export const getExistingPartNames = (state: RootState): ImporterFilePart[] =>
  state.importer.existingPartNames;

export const getExistingPartNamesLoading = (state: RootState): Loading =>
  state.importer.checkIfPartNamesExistLoading;

export const getImporterPartModalOpen = (state: RootState): boolean =>
  state.importer.importerPartModalOpen;

export const getUpdateModelSettingsLoading = (state: RootState): Loading =>
  state.importer.updateModelSettingsLoading;

export const getModelRequestLoading = (state: RootState): Loading =>
  state.importer.modelRequestLoading;

export const getUpdateFileSettingsLoading = (state: RootState): Loading =>
  state.importer.updateFileSettingsLoading;

export const getResultIdentityLoading = (state: RootState): Loading =>
  state.importer.retrieveResultIdentityLoading;

export const getImporterType = (state: RootState): ImporterType =>
  state.importer.importerType;

export const getImporterSignalRConnectionState = (state: RootState): string =>
  state.importer.importerSignalRConnectionState;

export const getImportTimerModalVisibility = (state: RootState): boolean =>
  state.importer.importTimerModalVisibility;
